import user from './user';
import buyer from './buyer';
import order_management from './orderManagment';
import product_details from './productDetails';
import order_listing from './orderListing';
import labels from './labels';
import catalogs from './catalog';
import manage_data from './manageData';
import notifications from './notifications';
import { user_management } from './userManagement';
import reports from './reports';
import storefront from './storefront';
import wizshop from './wizshop';
import discount_campaign from './discount';

const api_requests = {
	user,
	buyer,
	order_management,
	product_details,
	order_listing,
	labels,
	catalogs,
	manage_data,
	notifications,
	user_management,
	reports,
	storefront,
	wizshop,
	discount_campaign,
};

export default api_requests;
