// cartv2.ts

import { CartItem, ADD_TO_CART, REMOVE_FROM_CART, GET_CART, UPDATE_MULTI_CART_NAME } from 'src/@types/cart_types';

// Action Creators
export const addToCart = (item: CartItem) => ({
	type: ADD_TO_CART,
	payload: item,
});

export const removeFromCart = (id: string) => ({
	type: REMOVE_FROM_CART,
	payload: id,
});

export const getCart = (items: CartItem[]) => ({
	type: GET_CART,
	payload: items,
});

export const updateMultiCart = (updated_cart: any) => {
	return {
		type: UPDATE_MULTI_CART_NAME,
		data: updated_cart,
	};
};
