/* eslint-disable */
import {
	CREATE_DOCUMENT_SELECTED_BUYER,
	CREATE_DOCUMENT_TYPE,
	UPDATE_BUYER,
	// [Suyash] commented out as it is not being used
	// UPDATE_BUYER_CART,
	UPDATE_BUYER_PRICE_LIST,
	SHOW_BUYER_TOAST,
	TOTAL_BUYERS,
	UPDATE_CATALOG,
} from './reduxConstants';
import { Dispatch } from 'redux';
import cartManagement from 'src/utils/api_requests/cartManagement';
import api_requests from 'src/utils/api_requests';
import { getCart } from './cartv2';
import utils from 'src/utils/utils';
import { initializeCart } from './cart';
import cart_management from 'src/utils/api_requests/cartManagement';

export const update_buyer = ({ buyer_cart, buyer_info }: any) => ({
	type: UPDATE_BUYER,
	payload: { buyer_cart, buyer_info },
});

export const get_total_buyers = (data: any) => ({
	type: TOTAL_BUYERS,
	payload: data,
});

export const set_buyer_toast = (state: boolean, title: string, sub_title: string, type: string) => ({
	type: SHOW_BUYER_TOAST,
	payload: { state, title, sub_title, type },
});

// [Suyash] commented out as it is not being used
// const update_buyer_cart = ({ buyer_cart }: any) => ({
// 	type: UPDATE_BUYER_CART,
// 	payload: { buyer_cart },
// });

export const update_buyer_price_list = ({ price_list }: any) => ({
	type: UPDATE_BUYER_PRICE_LIST,
	payload: { price_list },
});

export const update_catalog = ({ catalog }: any) => ({
	type: UPDATE_CATALOG,
	payload: { catalog },
});

export const create_document_selected_buyer = (flag: boolean) => ({
	type: CREATE_DOCUMENT_SELECTED_BUYER,
	payload: flag,
});

export const create_document_type = (type: any) => ({
	type: CREATE_DOCUMENT_TYPE,
	payload: type,
});

export const set_buyer =
	({ buyer_id, is_guest_buyer, callback, fail_callback }: any) =>
	async (dispatch: Dispatch) => {
		try {
			const [cart_response, buyer_details_response]: any = await Promise.all([
				cartManagement.get_cart({ buyer_id, is_guest_buyer }),
				is_guest_buyer ? Promise.resolve(null) : api_requests.buyer.get_buyer_dashboard(buyer_id),
			]);
			const active_cart = utils.get_active_cart(cart_response?.data);
			dispatch(update_buyer({ buyer_cart: active_cart, buyer_info: buyer_details_response?.data }));
			dispatch(getCart(cart_response?.data)); // update redux state for multi carts
			if (callback) callback();
		} catch (error) {
			console.log(error);
			if (fail_callback) fail_callback();
		}
	};

export const update_store_buyer =
	({ active_cart_data, cart_data }: any) =>
	async (dispatch: Dispatch) => {
		try {
			const buyer_info: any = active_cart_data?.is_guest_buyer
				? Promise.resolve(null)
				: await api_requests.buyer.get_buyer_dashboard(active_cart_data?.buyer_id);

			const { items, products: _product } = active_cart_data;

			if (items && Object.keys(items)?.length > 0) {
				for (let _item in items) {
					const { id, parent_id } = _product[_item];

					items[_item].parent_id = parent_id;
					items[_item].id = id;
				}
			}

			dispatch(update_buyer({ buyer_cart: active_cart_data, buyer_info: buyer_info?.data }));
			dispatch(update_catalog({ catalog: { value: active_cart_data?.catalog_ids?.[0], label: '' } }));
			dispatch(getCart(cart_data));
			dispatch(
				initializeCart({
					id: active_cart_data.id,
					name: active_cart_data?.name,
					products: items,
					products_details: _product,
					document_items: active_cart_data?.document_items || {},
					container_data: active_cart_data?.container_data || {},
					meta: active_cart_data?.meta || {},
				}),
			);
		} catch (error) {
			console.error(error);
		}
	};

export const set_cart =
	({ buyer_id, is_guest_buyer, callback }: any) =>
	async (dispatch: Dispatch) => {
		try {
			const response: any = await cartManagement.get_cart({ buyer_id, is_guest_buyer });
			const buyer_details_response: any = is_guest_buyer ? Promise.resolve(null) : await api_requests.buyer.get_buyer_dashboard(buyer_id);
			const active_cart = utils.get_active_cart(response?.data);

			const buyer_cart = {
				...(active_cart || {}),
				buyer_id: buyer_id || active_cart?.buyer_id,
				is_guest_buyer,
			};

			const cart_response: any = await cart_management.get_cart_details({ cart_id: active_cart?.id });
			const { cart: active_cart_data } = cart_response;

			const { items, products: _product } = active_cart_data;

			if (items && Object.keys(items)?.length > 0) {
				for (let _item in items) {
					const { id, parent_id } = _product[_item];

					items[_item].parent_id = parent_id;
					items[_item].id = id;
				}
			}

			dispatch(update_buyer({ buyer_cart, buyer_info: buyer_details_response?.data }));
			dispatch(update_catalog({ catalog: { value: active_cart_data?.catalog_ids?.[0], label: '' } }));
			dispatch(getCart(response?.data)); // update redux state for multi carts
			dispatch(
				initializeCart({
					id: active_cart_data.id,
					name: active_cart_data?.name,
					products: items,
					products_details: _product,
					document_items: active_cart_data?.document_items || {},
					container_data: active_cart_data?.container_data || {},
					meta: active_cart_data?.meta || {},
				}),
			);
			if (callback) callback();
		} catch (error) {
			console.log(error);
		}
	};

export const edit_cart = async ({ buyer_id, cart_id }: any) => {
	try {
		const [cart_response, buyer_details_response]: any = await Promise.all([
			cartManagement.get_cart_details({ cart_id }),
			api_requests.buyer.get_buyer_dashboard(buyer_id),
		]);

		const {
			id,
			tenant_id,
			created_by,
			updated_by,
			type,
			created_at,
			updated_at,
			status,
			source,
			cart_hash,
			meta,
			cart_linked_to,
			document_id,
			is_guest_cart,
		} = cart_response?.cart;

		const buyer_cart = {
			id,
			tenant_id,
			buyer_id,
			created_by,
			updated_by,
			type,
			created_at,
			updated_at,
			status,
			source,
			cart_hash,
			meta,
			cart_linked_to,
			document_id,
			is_guest_cart,
		};
		return { buyer_cart, buyer_info: buyer_details_response?.data };
	} catch (error) {
		console.log(error);
	}
};
