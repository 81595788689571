import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

dayjs.extend(quarterOfYear);

const format = 'YYYY-MM-DD HH:mm:ss';

export const time_range_list: any = {
	all_time: {
		id: 'all_time',
		label: 'All time',
		value: () => {
			return { from: null, to: null };
		},
	},
	today: {
		id: 'today',
		label: 'Today',
		value: (timezone: string = 'UTC') => {
			const from = dayjs().tz(timezone).startOf('day').format(format);
			return { from, to: from };
		},
	},
	yesterday: {
		id: 'yesterday',
		label: 'Yesterday',
		value: (timezone: string = 'UTC') => {
			const from = dayjs().tz(timezone).subtract(1, 'day').startOf('day').format(format);
			return { from, to: from };
		},
	},
	this_week: {
		id: 'this_week',
		label: 'This week',
		value: (timezone: string = 'UTC') => {
			const from = dayjs().tz(timezone).subtract(1, 'day').startOf('week').add(1, 'day').startOf('day').format(format);
			const to = dayjs().tz(timezone).subtract(1, 'day').endOf('week').add(1, 'day').startOf('day').format(format);
			return { from, to };
		},
	},
	last_week: {
		id: 'last_week',
		label: 'Last week',
		value: (timezone: string = 'UTC') => {
			const from = dayjs().tz(timezone).subtract(1, 'day').subtract(1, 'week').startOf('week').add(1, 'day').startOf('day').format(format);
			const to = dayjs().tz(timezone).subtract(1, 'day').subtract(1, 'week').endOf('week').add(1, 'day').startOf('day').format(format);
			return { from, to };
		},
	},
	this_month: {
		id: 'this_month',
		label: 'This month',
		value: (timezone: string = 'UTC') => {
			const from = dayjs().tz(timezone).startOf('month').startOf('day').format(format);
			const to = dayjs().tz(timezone).endOf('month').startOf('day').format(format);
			return { from, to };
		},
	},
	last_month: {
		id: 'last_month',
		label: 'Last month',
		value: (timezone: string = 'UTC') => {
			const from = dayjs().tz(timezone).subtract(1, 'month').startOf('month').startOf('day').format(format);
			const to = dayjs().tz(timezone).subtract(1, 'month').endOf('month').startOf('day').format(format);
			return { from, to };
		},
	},
	this_quarter: {
		id: 'this_quarter',
		label: 'This quarter',
		value: (timezone: string = 'UTC') => {
			const from = dayjs().tz(timezone).startOf('quarter').startOf('day').format(format);
			const to = dayjs().tz(timezone).endOf('quarter').startOf('day').format(format);
			return { from, to };
		},
	},
	last_quarter: {
		id: 'last_quarter',
		label: 'Last quarter',
		value: (timezone: string = 'UTC') => {
			const from = dayjs().tz(timezone).startOf('quarter').subtract(1, 'quarter').startOf('day').format(format);
			const to = dayjs().tz(timezone).endOf('quarter').subtract(1, 'quarter').startOf('day').format(format);
			return { from, to };
		},
	},
	this_year: {
		id: 'this_year',
		label: 'This year',
		value: (timezone: string = 'UTC') => {
			const from = dayjs().tz(timezone).startOf('year').startOf('day').format(format);
			const to = dayjs().tz(timezone).endOf('year').startOf('day').format(format);
			return { from, to };
		},
	},
	last_year: {
		id: 'last_year',
		label: 'Last year',
		value: (timezone: string = 'UTC') => {
			const from = dayjs().tz(timezone).startOf('year').subtract(1, 'year').startOf('day').format(format);
			const to = dayjs().tz(timezone).endOf('year').subtract(1, 'year').startOf('day').format(format);
			return { from, to };
		},
	},
};

export const default_time_range_id = 'all_time';

export const ALL_TIME_ID = 'all_time';
