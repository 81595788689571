import { Divider } from '@mui/material';
import { t } from 'i18next';
import _ from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import CustomText from 'src/common/@the-source/CustomText';
import { Box, Button, Drawer, Grid, Icon } from 'src/common/@the-source/atoms';
import FormBuilder from 'src/common/@the-source/molecules/FormBuilder/FormBuilder';
import { BARCODE, BARCODE_TYPE_OPTION, DEFAULT_BARCODE, DEFAULT_VALUES } from '../../General/mock';
import { useContext, useEffect, useState } from 'react';
import SettingsContext from 'src/screens/Settings/context';
import RadioGroup from 'src/common/@the-source/molecules/RadioGroup';
import CheckboxEditField from 'src/common/@the-source/atoms/FieldsNew/CheckboxEditField';

interface Props {
	is_visible: boolean;
	close: () => void;
	data: any;
	options: any;
	barcodes_list: any;
	index: number;
}

const dividerStyle: React.CSSProperties = {
	height: '1px',
	background: 'repeating-linear-gradient(90deg,#D1D6DD 0 4px,#0000 0 8px)',
	margin: '2rem 0',
};

const AddEditBarcodeDrawer = ({ is_visible, close, data, options }: Props) => {
	const [loading, set_loading] = useState<boolean>(false);
	const [selected_types, set_selected_types] = useState<any>([]);
	const [default_type, set_default_type] = useState<string>('');
	const { update_configuration } = useContext(SettingsContext);
	const custom_field = _.find(data, (item: any) => item?.type === 'upc_a') || {};
	const methods = useForm({
		defaultValues: !_.isEmpty(custom_field) ? { ...custom_field, ...custom_field?.filter_logic, ...DEFAULT_VALUES } : { ...DEFAULT_VALUES },
	});

	const { control, getValues, setValue, handleSubmit }: any = methods;

	const show_custom_fields = _.includes(selected_types, 'upc_a');
	const show_default_field = _.includes(selected_types, 'upc_a') && _.includes(selected_types, 'barcode');

	const handle_save = async (params: any) => {
		set_loading(true);
		const is_multiple_selected = _.size(selected_types) > 1;
		const updated_payload = _.map(selected_types, (type: any) => {
			if (type === 'barcode') {
				return {
					..._.head(DEFAULT_BARCODE),
					is_default: is_multiple_selected ? type === default_type : true,
				};
			}

			let updated_data: any = _.cloneDeep(params);
			updated_data.filter_logic = {
				key: params?.key,
				key_type: params?.key_type,
			};
			delete updated_data?.key;
			delete updated_data?.key_type;
			if (updated_data?.includes_in_settings) {
				delete updated_data?.includes_in_settings;
			}
			_.forEach(BARCODE_TYPE_OPTION, (item: any) => delete updated_data?.[item?.value]);
			return {
				...updated_data,
				name: type === 'barcode' ? 'SKU' : 'UPC',
				is_default: is_multiple_selected ? type === default_type : true,
			};
		});
		await update_configuration('barcode_scanner_settings', updated_payload, close);
		set_loading(false);
	};

	useEffect(() => {
		const included_types = _.map(data, (item: any) => item?.type);
		set_selected_types(included_types);
		const default_type_item = _.find(data, (item: any) => item?.is_default === true)?.type;
		set_default_type(default_type_item);
	}, [data]);

	const handle_render_header = () => {
		return (
			<Grid className='drawer-header'>
				<CustomText type='H3'>Barcode Setting</CustomText>
				<Icon iconName='IconX' sx={{ cursor: 'pointer' }} onClick={close} />
			</Grid>
		);
	};

	const handle_barcode_type = (val: any) => {
		set_selected_types((prev: any) => {
			if (_.includes(prev, val)) {
				return _.filter(prev, (item: any) => item !== val);
			} else {
				return [...prev, val];
			}
		});
	};

	const handle_change_default = (val: string) => {
		set_default_type(val);
	};

	const handle_render_footer = () => {
		return (
			<Grid className='drawer-footer' justifyContent={'flex-end'}>
				<Button variant='outlined' onClick={close} disabled={loading}>
					{t('Settings.CTA.cancel')}
				</Button>
				<Button onClick={handleSubmit(handle_save)} loading={loading} disabled={_.isEmpty(selected_types)}>
					{t('Settings.CTA.save')}
				</Button>
			</Grid>
		);
	};

	const handle_render_drawer_content = () => {
		return (
			<Grid className='drawer-body' pt={1} gap={1}>
				<FormProvider {...methods}>
					<CustomText type='H3'> Select barcode type :</CustomText>
					<Box display={'flex'}>
						{_.map(BARCODE_TYPE_OPTION, (item: any) => (
							<CheckboxEditField
								name={item?.value}
								defaultValue={_.includes(selected_types, item?.value)}
								checkbox_value={true}
								label={item?.label}
								is_disabled={false}
								onClick={() => handle_barcode_type(item?.value)}
							/>
						))}
					</Box>
					{show_custom_fields && (
						<Grid>
							<div style={dividerStyle}></div>
							{_.map(BARCODE?.FORM_FIELDS, (attribute: any) => (
								<FormBuilder
									key={attribute?.id}
									placeholder={attribute?.name}
									label={attribute?.name}
									name={attribute?.id}
									defaultValue={data?.[attribute?.id] || attribute?.value}
									validations={{ required: true }}
									type={attribute?.type}
									options={options}
									control={control}
									register={methods.register}
									getValues={getValues}
									setValue={setValue}
								/>
							))}
						</Grid>
					)}
					{show_default_field && (
						<>
							<CustomText type='H3'> Select default</CustomText>
							<RadioGroup
								selectedOption={default_type}
								options={BARCODE_TYPE_OPTION}
								onChange={(val: string) => handle_change_default(val)}
							/>
						</>
					)}
				</FormProvider>
			</Grid>
		);
	};

	const handle_render_drawer = () => {
		return (
			<Grid className='drawer-container'>
				{handle_render_header()}
				<Divider className='drawer-divider' />
				{handle_render_drawer_content()}
				<Divider className='drawer-divider' />
				{handle_render_footer()}
			</Grid>
		);
	};

	return <Drawer width={480} open={is_visible} onClose={close} content={handle_render_drawer()} />;
};

export default AddEditBarcodeDrawer;
