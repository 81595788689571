export interface CartItem {
	id: string;
	name: string;
	quantity: number;
	price: number;
	[key: string]: any;
}

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const GET_CART = 'GET_CART';
export const UPDATE_CART_NAME = 'UPDATE_CART_NAME';
export const UPDATE_MULTI_CART_NAME = 'UPDATE_MULTI_CART_NAME';

export interface AddToCartAction {
	type: typeof ADD_TO_CART;
	payload: CartItem;
}

export interface RemoveFromCartAction {
	type: typeof REMOVE_FROM_CART;
	payload: string;
}

export interface InitializeCartAction {
	type: typeof GET_CART;
	payload: CartItem[];
}

export interface UpdateCartNameAction {
	type: typeof UPDATE_CART_NAME;
	data: CartItem;
}

export interface UpdateMultiCartNameAction {
	type: typeof UPDATE_MULTI_CART_NAME;
	data: CartItem;
}

// Combine action types
export type CartActions = AddToCartAction | RemoveFromCartAction | InitializeCartAction | UpdateCartNameAction | UpdateMultiCartNameAction;
