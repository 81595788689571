// cart_v2.ts

import { cloneDeep } from 'lodash';
import { CartActions, CartItem, GET_CART, UPDATE_MULTI_CART_NAME } from 'src/@types/cart_types';

const initialState: CartItem[] = [];

const cartv2_reducer = (state = initialState, action: CartActions): CartItem[] => {
	switch (action.type) {
		case GET_CART:
			return action.payload;
		case UPDATE_MULTI_CART_NAME:
			const new_state = cloneDeep(state);
			return new_state?.map((item: any) => {
				if (item?.id === action?.data?.id) {
					return {
						...item,
						name: action?.data?.name,
					};
				} else {
					return item;
				}
			});

		default:
			return state;
	}
};

export default cartv2_reducer;
