import React, { useState } from 'react';
import SimilarDrawer from './@the-source/molecules/SimilarDrawer';
import HasSimilar from './@the-source/molecules/RecommendCard/HasSimilar';
import constants from 'src/utils/constants';
import useTenantSettings from 'src/hooks/useTenantSettings';
import { isEmpty } from 'lodash';
import { Grid, Icon } from './@the-source/atoms';
import { colors } from 'src/utils/theme';
import { makeStyles } from '@mui/styles';

interface Props {
	drawer_props?: any;
	on_click?: (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
	disable_drawer?: boolean;
	custom_similar_comp?: React.ReactNode;
	discount_campaigns?: any[];
	id: string;
	show_icon?: boolean;
}

const useStyles = makeStyles(() => ({
	view_similar_icon: {
		display: 'flex',
		backgroundColor: colors.white,
		borderRadius: '200px',
		cursor: 'pointer',
		border: `1px solid ${colors.black_14}`,
		width: '32px',
		height: '32px',
	},
}));

const ViewSimilar = ({
	drawer_props = {},
	on_click,
	disable_drawer = false,
	custom_similar_comp,
	discount_campaigns,
	id,
	show_icon = false,
}: Props) => {
	const [drawer, set_drawer] = useState(false);
	const icon_classes = useStyles();

	const { view_similar_enabled } = useTenantSettings({
		[constants.TENANT_SETTINGS_KEYS.VIEW_SIMILAR_ENABLED]: true,
	});

	const handle_view_similar_click = (e: any) => {
		on_click && on_click(e);
		set_drawer(true);
	};

	const view_similar_icon = (
		<Grid
			className={`${icon_classes.view_similar_icon}`}
			justifyContent={'center'}
			alignItems={'center'}
			onClick={handle_view_similar_click}>
			<Icon iconName='IconCards' color={colors.grey_800} />
		</Grid>
	);

	const component_to_render = show_icon ? view_similar_icon : <HasSimilar onClick={handle_view_similar_click} id={id} />;

	return (
		view_similar_enabled && (
			<>
				{!isEmpty(custom_similar_comp) ? custom_similar_comp : component_to_render}
				{drawer && !disable_drawer && (
					<SimilarDrawer drawer={drawer} setDrawer={set_drawer} {...drawer_props} discount_campaigns={discount_campaigns} />
				)}
			</>
		)
	);
};

export default ViewSimilar;
