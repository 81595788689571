import classes from '../../Settings.module.css';
import CustomText from 'src/common/@the-source/CustomText';
import { Box, Button, Grid, Switch } from 'src/common/@the-source/atoms';
import AgGridTableContainer from 'src/common/@the-source/molecules/Table';
import utils from 'src/utils/utils';
import { BARCODE } from './mock';
import AddEditBarcodeDrawer from '../Common/Drawer/AddEditBarcodeDrawer';
import { useContext, useEffect, useState } from 'react';
import SettingsContext from '../../context';
import _ from 'lodash';
import { Divider } from '@mui/material';

const actions = [
	{
		name: 'Edit',
		action: 'edit',
		icon: 'IconEdit',
		key: 'edit',
	},
	{
		name: 'Delete',
		action: 'delete',
		icon: 'IconTrash',
		key: 'delete',
	},
];

const BarcodeSettings = () => {
	const { configure, get_keys_configuration, get_attributes, update_configuration } = useContext(SettingsContext);
	const [attributes, set_attributes] = useState<any>([]);
	const [row_data, set_row_data] = useState<any>([]);
	const [drawer, set_drawer] = useState<any>({ state: false, data: null, index: 0 });
	const show_add_button = _.isEmpty(configure?.barcode_scanner_settings);
	const { tenant_settings } = configure;

	const get_row_data = () =>
		_.map(configure?.barcode_scanner_settings, (barcode: any) => ({
			...barcode,
			includes_in_settings: true,
			is_default: barcode?.is_default ?? false,
		}));

	const handle_edit = (params: any, key: string) => {
		if (key === 'edit') {
			set_drawer({ state: true, data: row_data, index: params?.rowIndex });
		} else {
			let updated_barcodes = _.cloneDeep(configure?.barcode_scanner_settings);
			const selected_row_name = params?.node?.data?.name;
			updated_barcodes = _.filter(updated_barcodes, (item: any) => item?.name !== selected_row_name);
			update_configuration('barcode_scanner_settings', updated_barcodes);
		}
	};

	const get_products = async () => {
		const res: any = await get_attributes('product');
		let temp: any = [];
		if (res) {
			temp = _.map(res, (att: any) => ({
				value: `transformed_attributes.${att?.id}`,
				label: att?.name,
			}));
		}
		set_attributes(temp);
	};

	const height = row_data?.length * 50;
	const should_disable_button = (data: any, key: string) => key === 'delete' && data?.is_default;

	const handle_get_column_config = () => {
		const table_config = _.map(BARCODE?.TABLE_CONFIG, (item) => {
			if (item?.headerName === 'Logic Type') {
				return {
					...item,
					cellRenderer: (params: any) => {
						const value = params?.value === 'sku_id' ? '--' : _.find(attributes, ['value', params?.node?.data?.filter_logic?.key])?.label;

						return value;
					},
				};
			} else {
				return item;
			}
		});
		return [...table_config, { ...utils.create_action_config(actions, handle_edit), cellRendererParams: { should_disable_button } }];
	};
	const columnDef = handle_get_column_config();

	const on_toggle_switch = async () => {
		const updated_data = {
			...tenant_settings,
			scanning_should_open_pdp: !tenant_settings?.scanning_should_open_pdp,
		};
		update_configuration('tenant_settings', updated_data);
	};

	useEffect(() => {
		get_keys_configuration('tenant_settings');
		get_keys_configuration('barcode_scanner_settings');
		get_products();
	}, []);

	useEffect(() => {
		if (_.isEmpty(configure?.barcode_scanner_settings)) {
			set_row_data([]);
			return;
		}
		const data = get_row_data();
		set_row_data(data);
	}, [configure?.barcode_scanner_settings]);

	return (
		<Grid className={classes.content}>
			<Grid className={classes.content_header}>
				<CustomText type='H2'>Barcode Settings</CustomText>
			</Grid>
			<Grid my={2}>
				<AgGridTableContainer
					columnDefs={columnDef}
					has_serials={false}
					hideManageColumn
					rowData={row_data}
					containerStyle={{ height: `${height + 50}px`, maxHeight: '700px', minHeight: '200px' }}
					showStatusBar={false}
					suppressFieldDotNotation
				/>
			</Grid>
			{show_add_button && (
				<Button variant='text' onClick={() => set_drawer({ state: true, data: null, index: configure?.barcode_scanner_settings?.length })}>
					+ Add new barcode type
				</Button>
			)}
			<Box mt={5}>
				<Divider />
			</Box>
			<Box mt={2} p={1} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
				<CustomText type='Body2'>Scanning should open PDP</CustomText>
				<Switch onChange={on_toggle_switch} checked={tenant_settings?.scanning_should_open_pdp} />
			</Box>
			{drawer?.state && (
				<AddEditBarcodeDrawer
					is_visible={true}
					data={drawer?.data}
					close={() => set_drawer({ state: false, data: null, index: 0 })}
					options={attributes}
					barcodes_list={configure?.barcode_scanner_settings || []}
					index={drawer?.index}
				/>
			)}
		</Grid>
	);
};

export default BarcodeSettings;
