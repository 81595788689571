import green_tick from './green_tick.json';
import report_loading from './report_loading.json';
import connecting from './account_connect.json';
import loader_animation from './loading_animations.json';
import preview_pdf from './preview_pdf.json';
import insights from './insights.json';
import smart_search_loading from './smart_search_loading.json';
import LoaderGreen from './LoaderGreen.json';
import wizai from './wizai.json';
const Animations = {
	green_tick,
	report_loading,
	connecting,
	loader_animation,
	LoaderGreen,
	preview_pdf,
	insights,
	smart_search_loading,
	wizai,
};

export default Animations;
