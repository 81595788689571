import { head } from 'lodash';
import { Grid, Radio } from '../../../atoms';

export interface AccordionSelectProps {
	options: Array<string>;
	selectedOptions: Array<string>;
	setSelectedOptions: any;
	onApply: any;
}

export default function AccordionSelect({ options, selectedOptions, setSelectedOptions, onApply }: AccordionSelectProps) {
	const selected_option = head(selectedOptions);
	return (
		<Grid sx={{ marginTop: '-1em' }}>
			{options.map((option: any) => (
				<Grid key={option} container>
					<Grid item>
						<Radio
							checked={selected_option === (option?.value || option)}
							onChange={(e: any) => {
								if (e.target.checked) {
									setSelectedOptions(option?.value || option);
									onApply(option?.value || option);
									return;
								}
								setSelectedOptions([]);
							}}
						/>
					</Grid>
					<Grid display='flex' alignItems='center' justifyContent='center' item>
						{option?.label || option}
					</Grid>
				</Grid>
			))}
		</Grid>
	);
}
