import constants from 'src/utils/constants';

type ImageProps = {
	src: string;
	alt?: string;
	width?: any;
	height?: any;
	style?: object;
	imageFit?: string;
	imgClass?: any;
	loadingType?: any;
	fallbackSrc?: string;
	onClick?: any;
	id?: string;
	// timeout?: number;
};

const Image = ({
	src,
	alt,
	style,
	imageFit,
	width,
	height,
	imgClass,
	loadingType,
	onClick,
	fallbackSrc,
	id,
	// timeout,
	...rest
}: ImageProps) => {
	// const [imageSrc, setImageSrc] = useState(src || fallbackSrc || constants.FALLBACK_IMAGE);
	// const [isLoaded, setIsLoaded] = useState(false);

	// useEffect(() => {
	// 	const timer = setTimeout(() => {
	// 		if (!isLoaded) {
	// 			setImageSrc(fallbackSrc || constants.FALLBACK_IMAGE);
	// 		}
	// 	}, timeout); // Use the provided timeout or default to 15 seconds

	// 	return () => clearTimeout(timer); // Cleanup on unmount
	// }, [isLoaded, fallbackSrc, timeout]);

	// useEffect(() => {
	// 	setImageSrc(src || constants.FALLBACK_IMAGE);
	// }, [src]);

	// const handleLoad = () => {
	// 	setIsLoaded(true);
	// };

	const handleFallback = (e: React.SyntheticEvent<HTMLImageElement>) => {
		if (fallbackSrc) {
			e.currentTarget.src = fallbackSrc;
		}
	};

	return (
		<img
			// src={imageSrc}
			src={src}
			alt={alt}
			onClick={onClick}
			id={id}
			style={{ objectFit: 'contain', ...style }}
			className={imgClass}
			loading={loadingType}
			width={width}
			onError={handleFallback}
			height={height}
			{...rest}
		/>
	);
};

Image.defaultProps = {
	src: '',
	alt: '',
	width: 20,
	height: 20,
	style: {},
	imageFit: 'contain',
	imgClass: {},
	loadingType: 'lazy',
	fallbackSrc: constants.FALLBACK_IMAGE,
	// timeout: constants.DEFAULT_IMAGE_TIMEOUT,
};

export default Image;
