import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import apply_validations, { ValidationProps } from 'src/utils/apply_validations';
import dayjs from 'dayjs';
import { colors } from 'src/utils/theme';
import constants from 'src/utils/constants';

interface Props {
	name: string;
	label?: string;
	validations?: ValidationProps;
	refInput?: any;
	value?: any;
	defaultValue?: any;
	onChangeCapture?: any;
	disablePast?: boolean;
	component_type?: string;
	selected_timezone?: any;
	handle_error?: any;
}

const DateTimeEditField = ({
	name,
	label,
	validations,
	defaultValue,
	onChangeCapture,
	disablePast,
	selected_timezone,
	handle_error,
	...props
}: Props) => {
	const [new_value, set_new_value] = useState(defaultValue);
	const { control, watch, setValue } = useFormContext();

	const watched_value = watch(name);

	useEffect(() => {
		if (defaultValue) {
			setValue(name, defaultValue);
		}
	}, [defaultValue]);

	useEffect(() => {
		if (watched_value === new_value) return;
		set_new_value(watched_value);
	}, [watched_value]);

	const handle_change = (onChange: any, val: any) => {
		setValue(name, val);
		let date = dayjs.tz(val, selected_timezone)?.utc()?.format(constants.CUSTOM_DATE_FORMATS.DATE_TIME_ZULU);
		if (date === 'Invalid Date') {
			onChange(null);
		} else {
			onChange(date);
		}
		let parsed_date: string | null = dayjs(val).format(constants.CUSTOM_DATE_FORMATS.MM_DD_YYYY);
		if (parsed_date === 'Invalid Date') {
			parsed_date = null;
		}
		if (onChangeCapture) onChangeCapture({ target: { name, value: parsed_date } });
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Controller
				name={name}
				control={control}
				defaultValue={defaultValue}
				rules={apply_validations({ label, name, ...validations })}
				render={({ field, fieldState: { error } }) => {
					return (
						<DateTimePicker
							timezone={selected_timezone}
							sx={{ width: '100%' }}
							{...field}
							{...props}
							disablePast={disablePast}
							format='MM/DD/YYYY hh:mm A'
							inputFormat='MM/DD/YYYY hh:mm A'
							onError={(newError) => handle_error(name, newError)}
							closeOnSelect={false}
							label={`${label}${validations?.required ? '*' : ''}`}
							value={field?.value && selected_timezone ? dayjs.utc(field?.value)?.tz(selected_timezone) : null}
							onChange={(val) => handle_change(field.onChange, val)}
							renderInput={(params: any) => <TextField {...params} label={label} />}
							slotProps={{
								actionBar: {
									actions: ['clear', 'accept'],
								},
								day: { sx: { fontSize: '14px' } },
								calendarHeader: { sx: { fontSize: '14px' } },
								textField: {
									helperText: error ? <p style={{ color: colors.red }}>{error?.message}</p> : '',
								},
								digitalClockSectionItem: {
									sx: {
										borderRadius: '50%',
										padding: '10px',
									},
								},
							}}
						/>
					);
				}}
			/>
		</LocalizationProvider>
	);
};

export default DateTimeEditField;
