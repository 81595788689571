import CustomText from 'src/common/@the-source/CustomText';
import _ from 'lodash';
import { Chip } from '@mui/material';
import { useEffect, useState } from 'react';
import { handle_field_validations } from '../helper';
import { Grid } from 'src/common/@the-source/atoms';
import { get_formatted_price_with_currency } from 'src/utils/common';

interface ChipProps {
	values: { display_name: string; price: number }[];
	prevent_overflow: {};
	onChange: any;
	default_value: any;
	id: string;
	is_mandatory: boolean;
	handleError: any;
	search_string_style?: any;
	currency: string;
}

const chip_style = {
	borderRadius: '40px',
	background: '#FFF',
	boxShadow: '0px 1px 12px 0px rgba(0, 0, 0, 0.08)',
	width: 'fit-content',
	maxWidth: '40rem',
	cursor: 'pointer',
};

const container_style = {
	display: 'flex',
	gap: '10px',
	flexWrap: 'wrap',
	marginTop: '1rem',
};

const ChipComponent = ({
	values,
	default_value,
	prevent_overflow,
	onChange,
	id,
	is_mandatory,
	handleError,
	search_string_style,
	currency,
}: ChipProps) => {
	const [selectedChip, setSelectedChip] = useState(default_value ?? '');

	const handleChipClick = (chipValue: string) => {
		if (selectedChip?.includes(chipValue)) {
			setSelectedChip('');
		} else {
			setSelectedChip(chipValue);
		}
	};

	useEffect(() => {
		onChange({ [id]: selectedChip });
		handleError({ [id]: handle_field_validations(_.isEmpty(selectedChip) ? 0 : 1, is_mandatory, is_mandatory ? 1 : 0, 1) });
	}, [selectedChip]);

	return (
		<Grid sx={{ ...container_style, ...search_string_style }}>
			{_.isArray(values) &&
				values?.map((curr: any) => {
					const selected = selectedChip === curr?.display_name;
					const price = get_formatted_price_with_currency(currency, curr?.price);
					const is_negative = Number(curr?.price) < 0;
					return (
						<Chip
							key={curr.id}
							id={curr.id}
							sx={{
								...chip_style,
								border: `1px solid ${selected ? '#4F555E' : '#D1D6DD'}`,
								...(selected ? { border: '1px solid black' } : {}),
							}}
							label={
								<Grid sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
									<CustomText type='Body' style={{ color: 'black', ...prevent_overflow }}>
										{`${curr?.display_name} :`}
									</CustomText>
									<CustomText type='Subtitle' color={is_negative ? 'rgb(215, 76, 16)' : '#000'}>
										{price}
									</CustomText>
								</Grid>
							}
							onClick={() => handleChipClick(curr?.display_name)}
						/>
					);
				})}
		</Grid>
	);
};

export default ChipComponent;
