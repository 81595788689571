import { Button as MuiButton, ButtonProps as MuiButtonProps, styled } from '@mui/material';
import CircularProgressBar from '../ProgressBar/CircularProgressBar';
import Box from '../Box';

type ButtonBaseProps = Pick<
	MuiButtonProps,
	| 'variant'
	| 'size'
	| 'color'
	| 'fullWidth'
	| 'href'
	| 'disabled'
	| 'startIcon'
	| 'endIcon'
	| 'onClick'
	| 'disableRipple'
	| 'disableTouchRipple'
	| 'className'
	| 'sx'
>;

export interface ButtonProps extends ButtonBaseProps {
	children?: any;
	tonal?: boolean;
	width?: string;
	disableRipple?: boolean;
	sx?: object;
	loading?: boolean;
	loaderSize?: string;
	loaderStyle?: any;
	id?: string;
	loaderColor?: string;
}

const TonalButton = styled(MuiButton)({
	color: '#16885F',
	backgroundColor: '#E8F3EF',
	border: 0,
	textTransform: 'none',
	fontWeight: 700,
	':hover': {
		backgroundColor: '#D0E7DF',
		color: '#16885F',
		boxShadow: 'none',
	},
});

const Button = ({
	color,
	tonal,
	children,
	loaderStyle = {},
	width,
	disableRipple,
	disableTouchRipple,
	sx,
	loading,
	disabled,
	loaderSize,
	className,
	id = '',
	loaderColor = '#4f4b4b',
	...rest
}: ButtonProps) => {
	return tonal ? (
		<TonalButton
			id={id}
			disableRipple={disableRipple}
			disableTouchRipple={disableTouchRipple}
			sx={sx}
			color={color}
			className={className}
			{...rest}>
			{children}
		</TonalButton>
	) : (
		<MuiButton
			id={id}
			disabled={disabled || loading}
			style={{ boxShadow: 'none', textTransform: 'none', fontWeight: 700, width }}
			sx={sx}
			color={color}
			className={className}
			disableRipple={disableRipple}
			disableTouchRipple={disableTouchRipple}
			{...rest}>
			{loading && (
				<Box display='flex' alignItems='center'>
					<CircularProgressBar
						style={{ width: loaderSize, height: loaderSize, marginRight: 15, color: loaderColor, ...loaderStyle }}
						variant='indeterminate'
					/>
				</Box>
			)}
			{children}
		</MuiButton>
	);
};

Button.defaultProps = {
	tonal: false,
	variant: 'contained',
	size: 'medium',
	color: 'primary',
	disabled: false,
	children: 'Click me',
	type: 'button',
	width: '',
	disableRipple: false,
	disableTouchRipple: false,
	loaderSize: '25px',
	sx: {},
	className: {},
};
export default Button;
