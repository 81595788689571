import utils from '../utils';

const discount_campaign = {
	//discount
	get_create_discount_form: (discount_type: string) => {
		return utils.request({
			url: `setting/v1/configuration/tenant_or_default/${discount_type}`,
			method: 'GET',
		});
	},
	create_discount_campaign: (payload: any) => {
		return utils.request({
			url: 'discount_campaign/v1/create',
			method: 'POST',
			data: payload,
		});
	},
	edit_discount_campaign: (discount_campaign_id: any) => {
		return utils.request({
			url: `discount_campaign/v1/edit/${discount_campaign_id}`,
			method: 'GET',
		});
	},
	update_discount_campaign: (discount_campaign_id: any, payload: any) => {
		return utils.request({
			url: `discount_campaign/v1/update/${discount_campaign_id}`,
			method: 'PUT',
			data: payload,
		});
	},
	delete_discount_campaign: (discount_campaign_id: any) => {
		return utils.request({
			url: `discount_campaign/v1/delete/${discount_campaign_id}`,
			method: 'delete',
		});
	},
	duplicate_name_discount_campaign: (name: any, id: any) => {
		let url: string;
		if (id) {
			url = `discount_campaign/v1/duplicate?name=${name}&id=${id}`;
		} else {
			url = `discount_campaign/v1/duplicate?name=${name}`;
		}
		return utils.request({
			url,
			method: 'GET',
		});
	},
	discount_listing: () => {
		return utils.request({
			url: 'discount_campaign/v1/list',
			method: 'GET',
		});
	},

	//segments
	get_create_segment_form: (segment_type: string) => {
		return utils.request({
			url: `setting/v1/configuration/tenant_or_default/${segment_type}`,
			method: 'GET',
		});
	},
	get_view_segment_detail: (segment_id: any) => {
		return utils.request({
			url: `segments/v1/deserialize/${segment_id}`,
			method: 'GET',
		});
	},

	get_segment_attributes: (segment_type: string, entity: string) => {
		return utils.request({
			url: `segments/v1/${segment_type}/${entity}`,
			method: 'GET',
		});
	},

	create_segment: (payload: any) => {
		return utils.request({
			url: 'segments/v1',
			method: 'POST',
			data: payload,
		});
	},

	duplicate_segment_name: (name: any, id: any) => {
		let url: string;
		if (id) {
			url = `segments/v1/duplicate/name?name=${name}&id=${id}`;
		} else {
			url = `segments/v1/duplicate/name?name=${name}`;
		}
		return utils.request({
			url,
			method: 'GET',
		});
	},

	delete_segment: (segment_id: any) => {
		return utils.request({
			url: `segments/v1/${segment_id}`,
			method: 'delete',
		});
	},

	segment_listing: () => {
		return utils.request({
			url: 'segments/v1/list',
			method: 'GET',
		});
	},

	get_all_segment: (type: string) => {
		return utils.request({
			url: `discount_campaign/v1/segments/${type}`,
			method: 'GET',
		});
	},

	export_all_data: (payload: any) => {
		return utils.request({
			url: 'segments/v1/export',
			method: 'POST',
			data: payload,
		});
	},
};

export default discount_campaign;
