import { createTheme } from '@mui/material';

import { FirstIcon, LastIcon, NextIcon, PrevIcon } from '../common/@the-source/atoms/Pagination/PaginationIcons';
import SatoshiBold from 'src/assets/fonts/Satoshi-Bold.woff2';
import SatoshiMedium from 'src/assets/fonts/Satoshi-Medium.woff2';
import SatoshiRegular from 'src/assets/fonts/Satoshi-Regular.woff2';

export const primary = {
	main: '#16885F',
	contrastText: 'white',
	50: '#E8F3EF',
	100: '#D0E7DF',
	200: '#A2CFBF',
	300: '#73B89F',
	400: '#6AB399',
	500: '#16885F',
	600: '#096645',
	700: '#01442C',
	800: '#01442C',
	900: '#002D1D',
};
export const error = {
	main: '#D74C10',
	contrastText: 'white',
	50: '#FBEDE7',
	100: '#F7DBCF',
	200: '#EFB79F',
	300: '#E79470',
	400: '#DF7040',
	500: '#D74C10',
	600: '#AE3500',
	700: '#852800',
	800: '#5D1C00',
	900: '#481702',
};

export const warning = {
	main: '#F0AF30',
	contrastText: 'white',
	50: '#FEF7EA',
	100: '#FCEFD6',
	200: '#F9DFAC',
	300: '#F6CF83',
	400: '#F3BF59',
	500: '#F0AF30',
	600: '#CE921E',
	700: '#AC7710',
	800: '#8A5D05',
	900: '#684500',
};

export const info = {
	main: '#6BA6FE',
	contrastText: 'white',
	50: '#F0F6FF',
	100: '#E1EDFF',
	200: '#C4DBFF',
	300: '#A6C9FE',
	400: '#88B8FE',
	500: '#6BA6FE',
	600: '#4578C4',
	700: '#3563A6',
	800: '#284F89',
	900: '#1C3C6C',
};

export const success = {
	main: '#7DA50E',
	contrastText: 'white',
	50: '#F2F6E7',
	100: '#E5EDCF',
	200: '#CBDB9F',
	300: '#B1C96E',
	400: '#97B73E',
	500: '#7DA50E',
	600: '#5B7C00',
	700: '#3D5300',
	800: '#2C3C01',
	900: '#212D01',
};

export const secondary = {
	main: '#25282D',
	contrastText: 'white',
	50: '#FAFAFA',
	100: '#F2F4F7',
	200: '#F2F4F7',
	300: '#EEF1F6',
	400: '#D1D6DD',
	500: '#B5BBC3',
	600: '#9AA0AA',
	700: '#676D77',
	800: '#4F555D',
	900: '#25282D',
};

export const text_colors = {
	primary: 'rgba(0, 0, 0, 0.6)',
	secondary: '#EEF1F7',
	tertiary: 'rgba(0, 0, 0, 0.12)',
	green: '#16885F',
	grey: '#a9acb2',
	light_grey: 'rgba(0, 0, 0, 0.08)',
	dark_grey: 'rgba(103, 109, 119, 1)',
	disabled: 'rgba(0, 0, 0, 0.3)',
	black: 'rgba(0, 0, 0, 0.87)',
};

export const background_colors = {
	primary: '#FFFFFF',
	secondary: '#F7F8FA',
	alice_blue: 'rgba(240, 246, 255, 0.70)',
	accordion: '#EFF3E1',
	radiant_color: '#eff7e0',
};

export const border_colors = {
	primary: '#16885F',
};

export const custom_stepper_text_color = {
	primary: '#525252',
	secondary: 'black',
	grey: '#4F555E',
	anchor_link: '#4578C4',
};

export const custom_stepper_bg_color = {
	primary: '#EEF1F7',
};

export const accordion_colors = {
	background: '#000000',
	text: '#808080',
};

export const select_colors = {
	primary: '#FFF',
	secondary: '#D74C10',
	text: 'rgba(0, 0, 0, 0.60)',
};

export const manage_data_export_select_color = 'rgba(22, 136, 95, 0.08)';

const lightTheme = createTheme({
	palette: {
		primary,
		secondary,
		error,
		warning,
		info,
		success,
		background: {
			default: '#FFFFFF',
			paper: '#F2F4F7',
		},
		divider: 'rgba(0,0,0,0.12)',
	},
	shape: {
		borderRadius: 8,
	},
	typography: {
		fontFamily: 'Satoshi',
		h1: {
			fontWeight: 700,
			fontSize: '4.8rem',
			lineHeight: '6.4rem',
		},
		h2: {
			fontWeight: 700,
			fontSize: '3.6rem',
			lineHeight: '4.8rem',
		},
		h3: {
			fontWeight: 700,
			fontSize: '2.8rem',
			lineHeight: '3.6rem',
		},
		h4: {
			fontWeight: 700,
			fontSize: '2.4rem',
			lineHeight: '3.2rem',
		},
		h5: {
			fontWeight: 700,
			fontSize: '2rem',
			lineHeight: '2.8rem',
		},
		h6: {
			fontWeight: 700,
			fontSize: '1.6rem',
			lineHeight: '2.4rem',
		},
		body1: {
			fontSize: '1.6rem',
		},
		body2: {
			fontSize: '1.4rem',
		},
		subtitle1: {
			fontSize: '1.6rem',
		},
		subtitle2: {
			fontSize: '1.4rem',
		},
	},
	cart: {
		cart_icon: {
			color: '#4F555E',
		},
		cart_custom_item_error: {
			background: '#FBEDE7',
			color: '#D74C10',
		},
	},
	discount_campaign: {
		color: '#FF5733',
	},
	filters: {
		date: {
			selected_color: 'rgba(22, 136, 95, 0.08)',
			border: primary?.main,
			border_color: ' #B5BBC3',
			background_color: '#FFFFFF',
		},
	},
	insights: {
		dashboard: {
			background: '#FFF',
			text_color: '#676D77',
			chip_bg_color: '#EEF1F7',
		},
		action_color: '#ddd4d1',
		alert: {
			icon_color: '#3563A6',
			bg_color: '#F0F6FF',
		},
		header: {
			color: '#4F555E',
			bg_color: '#f5f5f5',
		},
		sorting: '#F7F8F8',
		chip_color: 'rgba(242, 244, 247, 1)',
		chip_border: '1px solid rgba(0, 0, 0, 0.12)',
		background: '#F2F4F7',
		container: '#E5E5E5',
		more_color: '#5B7C00',
		less_color: '#D74C10',
		black_color: '#000000',
		add_color: '#16885F',
	},
	payments: {
		white: '#FFFFFF',
		grey_300: '#C4C4C4',
		grey_600: '#4F555E',
		grey_400: '#F7F8FA',
		grey_500: '#676D77',
		grey_700: '#737373',
		green: '#16885F',
		light_green: '#FCEFD6',
		light_blue: '#F0F6FF',
		light_yellow: '#F9DFAC',
		accent_yellow: '#CE921E',
		background: '#F7F8FA',
		paper: '#FCEFD6',
		transparent: 'transparent',
		green_100: '#E8F3EF',
		grey: 'grey',
		grey_light: 'rgba(247, 248, 250, 1)',
		grey_dark: 'rgba(0, 0, 0, 0.6)',
		primary_text: 'rgba(103, 109, 119, 1)',
		red: 'red',
		black: '#000000',
		warning: '#F0AF30',
	},
	settings: {
		import_export: {
			color: '#CE921E',
			text: '000000DE',
			span: {
				fontWeight: 500,
				cursor: 'pointer',
				color: primary?.main,
				'&:hover': {
					color: secondary[500],
				},
			},
		},
	},
	light_box: {
		modal: {
			modifiers: {
				icon_color: '#ffffff',
				image_border: ' #D1D6DD',
			},
			overlay_config: {
				background: 'rgba(0, 0, 0, 0.8)',
			},
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				'@font-face': {
					/* open-sans-300 - latin_cyrillic */
					fontFamily: '"Satoshi"',
					fontDisplay: 'swap',
					fontStyle: 'normal',
					fontWeight: 300,
					src: `local('Satoshi Regular'), local('Satoshi-Regular'),
                url(${SatoshiRegular}) format('woff2')`,
				},
				fallbacks: [
					{
						/* open-sans-300italic - latin_cyrillic */
						'@font-face': {
							fontFamily: '"Satoshi"',
							fontDisplay: 'swap',
							fontStyle: 'normal',
							fontWeight: 500,
							src: `local('Satoshi Medium'), local('Satoshi-Medium'),
                        url(${SatoshiMedium}) format('woff2')`,
						},
					},
					{
						'@font-face': {
							/* open-sans-regular - latin_cyrillic */
							fontFamily: '"Satoshi"',
							fontDisplay: 'swap',
							fontStyle: 'normal',
							fontWeight: 700,
							src: `local('Satoshi Bold'), local('Satoshi-Bold'),
                        url(${SatoshiBold}) format('woff2')`,
						},
					},
				],
			},
		},
		MuiTypography: {
			defaultProps: {
				variantMapping: {
					h1: 'h1',
					h2: 'h2',
					h3: 'h3',
					h4: 'h4',
					h5: 'h5',
					h6: 'h6',
					subtitle1: 'subtitle',
					body1: 'text',
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: () => ({
					// ...(ownerState.variant === 'contained' &&
					// 	ownerState.color === 'primary' && {
					// 		backgroundColor: '#202020',
					// 		color: '#fff',
					// 	}),
					backgroundColor: '#fff',
					'&:hover': {
						backgroundColor: 'rgba(0, 0, 0, 0.04)',
					},
					// '&:selected': {
					// 	backgroundColor: 'green',
					// },
				}),
			},
		},
		MuiList: {
			styleOverrides: {
				root: () => ({
					borderRadius: '8px',
					// filter: 'drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.1))',
					background: 'white',
					boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
					border: '1px solid #f5f5f5',
				}),
			},
		},
		MuiAccordion: {
			styleOverrides: {
				root: () => ({
					boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
				}),
			},
		},
		MuiToggleButton: {
			styleOverrides: {
				root: () => ({
					'&:hover': {
						backgroundColor: '#D0E7DF',
						color: '#25282D',
					},
					'&.Mui-selected, &.Mui-selected:hover': {
						backgroundColor: '#6AB399',
						color: '#FFFFFF',
					},
				}),
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: () => ({
					'&:hover': {
						backgroundColor: success[50],
						color: secondary[700],
					},
				}),
			},
		},
		MuiCard: {
			styleOverrides: {
				root: () => ({
					background: '#FFFFFF',
				}),
			},
		},
		MuiSelect: {
			styleOverrides: {
				root: () => ({
					border: '',
					'&:hover': {
						border: 'none',
					},
				}),
			},
		},
		MuiPaginationItem: {
			defaultProps: {
				components: {
					previous: PrevIcon,
					next: NextIcon,
					last: LastIcon,
					first: FirstIcon,
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: () => ({
					fontSize: '1.6rem',
					'&:hover': {
						border: 'none',
					},
				}),
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: () => ({
					fontSize: '1.6rem',
				}),
			},
		},
		MuiButton: {
			styleOverrides: {
				root: () => ({
					fontSize: '1.4rem',
					boxShadow: 'none',
				}),
			},
		},
		MuiSvgIcon: {
			styleOverrides: {
				root: () => ({
					fontSize: '2rem',
					width: '2rem',
					height: '2rem',
				}),
			},
		},
		MuiTab: {
			styleOverrides: {
				root: () => ({
					fontSize: '1.4rem',
				}),
			},
		},
	},
});

export default lightTheme;
