import FallbackImage from 'src/assets/images/fallback.png';

const ALL_ENV = {
	STAGING: 'staging',
	PRE_PROD: 'pre-prod',
	PRODUCTION: 'production',
};

const { VITE_APP_API_URL, VITE_APP_FILE_SERVICE_API_URL } = import.meta.env;
const new_url = VITE_APP_API_URL;
const FALLBACK_IMAGE = FallbackImage;
const DEFAULT_IMAGE_TIMEOUT = 15000;

const ENDPOINT = new_url;
const POSTMAN_MOCK = 'https://e06f6a5f-4c44-4f2b-b203-94d9469288b5.mock.pstmn.io';
const POSTMAN_ENDPOINT = 'https://528cdc15-33b6-407f-a746-332085f87a93.mock.pstmn.io';

const LOGIN: `${typeof ENDPOINT}/users/v1/login` = `${ENDPOINT}/users/v1/login`;
const START_TRIAL: `${typeof ENDPOINT}/internal_dashboard/v1/tenant/trial-mode-tenant` = `${ENDPOINT}/internal_dashboard/v1/tenant/trial-mode-tenant`;
const SWITCH_TENANT: `${typeof ENDPOINT}/users/v1/switch` = `${ENDPOINT}/users/v1/switch`;
const REFRESH_TOKEN: `${typeof ENDPOINT}/auth/refresh` = `${ENDPOINT}/auth/refresh`;
const VERIFY_TOKEN: `${typeof ENDPOINT}/auth/token/verify/` = `${ENDPOINT}/auth/token/verify/`;
const ENABLE_2FA: `${typeof ENDPOINT}/auth/email/activate/` = `${ENDPOINT}/auth/email/activate/`;
const VALIDATE_2FA: `${typeof ENDPOINT}/auth/email/activate/confirm/` = `${ENDPOINT}/auth/email/activate/confirm/`;
const SET_PASSWORD: `${typeof ENDPOINT}/users/v1/password_reset` = `${ENDPOINT}/users/v1/password_reset`;
const LOGOUT_API: `${typeof ENDPOINT}/auth/logout` = `${ENDPOINT}/auth/logout`;
const RESET_PASSWORD: `${typeof ENDPOINT}/users/v1/password_reset_confirm/` = `${ENDPOINT}/users/v1/password_reset_confirm/`;
const DEACTIVATE_2FA: `${typeof ENDPOINT}/auth/email/deactivate/` = `${ENDPOINT}/auth/email/deactivate/`;
const LOGIN_2FA: `${typeof ENDPOINT}/auth/email/deactivate/` = `${ENDPOINT}/auth/email/deactivate/`;
const BLACKLIST_TOKEN: `${typeof ENDPOINT}/auth/token/blacklist/` = `${ENDPOINT}/auth/token/blacklist/`;
const GET_USER: `${typeof ENDPOINT}/users/v1/me` = `${ENDPOINT}/users/v1/me`;

const GET_CART: `${typeof ENDPOINT}/cart/` = `${ENDPOINT}/cart/`;
const GET_ENTITIES: `${typeof ENDPOINT}/cart/entities` = `${ENDPOINT}/cart/entities`;
const CART: `${typeof ENDPOINT}/cart` = `${ENDPOINT}/cart`;
const BULK_GET: `${typeof ENDPOINT}/cart/bulk` = `${ENDPOINT}/cart/bulk`;
const CREATE_QUTATION: `${typeof ENDPOINT}/quotation/create/` = `${ENDPOINT}/quotation/create/`;
const CONFIRM_QUTATION: `${typeof ENDPOINT}/quotation/confirm/` = `${ENDPOINT}/quotation/confirm/`;
const GET_ACCOUNT_LIST: `${typeof ENDPOINT}/settings/account/list` = `${ENDPOINT}/settings/account/list`;
const GET_ACCOUNT_USERS: `${typeof ENDPOINT}/settings/account/details/create/cart` = `${ENDPOINT}/settings/account/details/create/cart`;
const CREATE_ACCOUNT: `${typeof ENDPOINT}/settings/account/add` = `${ENDPOINT}/settings/account/add`;

const UPDATE_QUICK_SETTINGS: `${typeof ENDPOINT}/settings/` = `${ENDPOINT}/settings/`;
const GET_QUICK_SETTINGS: `${typeof ENDPOINT}/settings` = `${ENDPOINT}/settings`;
const ATTRIBUTE_DATE_FORMAT = "DD MMM' YY";
const ATTRIBUTE_DATE_TIME_FORMAT = "DD MMM' YY, hh:mm A";
const GET_PRODUCT_DETAILS: (id: string) => string = (id) => `${ENDPOINT}/pim/entity/${id}`;
const GET_PRODUCTS: (params: string | undefined) => string = (params) => `${ENDPOINT}/pim/entity/search${params ? `?${params}` : ''}`;
const GET_ALL_FILTERS: `${typeof ENDPOINT}/pim/entity/filters` = `${ENDPOINT}/pim/entity/filters`;
const GET_PRODUCT_VARIANTS: (productId: string) => string = (productId) => `${ENDPOINT}/pim/entity/variants/${productId}`;
const GET_PRICE_LEVEL_LIST: `${typeof ENDPOINT}/entity/v1/product/price_list` = `${ENDPOINT}/entity/v1/product/price_list`;
const GET_CREATE_ACCOUNTS_FORM: `${typeof ENDPOINT}/settings/account/details/create/main` = `${ENDPOINT}/settings/account/details/create/main`;
const POST_CREATE_ACCOUNT: `${typeof ENDPOINT}/settings/account/add` = `${ENDPOINT}/settings/account/add`;
const GET_PERMISSIONS: `${typeof ENDPOINT}/users/v2/permission` = `${ENDPOINT}/users/v2/permission`;

const SMART_SEARCH_UPLOAD_IMAGE: `${typeof ENDPOINT}/files/upload` = `${VITE_APP_FILE_SERVICE_API_URL}/files/upload`;

const BUYER_ADDRESS_FIELDS = {
	id: 'id',
	first_name: 'first_name',
	last_name: 'last_name',
	country_code: 'country_code',
	phone: 'phone',
	email: 'email',
	country: 'country',
	state: 'state',
	pincode: 'pincode',
	type: 'type',
	street_address: 'street_address',
	city: 'city',
	default_shipping_address: 'default_shipping_address',
	default_billing_address: 'default_billing_address',
	address_line_2: 'address_line_2',
};

const EXCLUDED_FACETS_LABELS = ['CATEGORY', 'COLLECTIONS', 'INVENTORY STATUS'];

export const STATUS = {
	SUCCESS: 'success',
};

const HYPERLINK_FIELDS = ['company_name', 'action'];
const BUYER_LIST_KEY = 'buyer_list';

const ATTRIBUTE_DISPLAY_CONFIG = {
	HINGE: 'show_hinges',
	CUSTOM: 'show_custom',
	HIDDEN: 'hidden',
};

const CART_GROUPING_KEYS = {
	ENABLED: 'enabled',
	GROUP_BY: 'group_by',
	CUSTOM_GROUPING: 'custom_grouping',
	CUSTOM_GROUP_PRODUCT_KEYS: ['name', 'sku_id'],
	CART_SUMMARY_PAGE: 'CART_SUMMARY_PAGE',
	UNGROUPED_KEY: 'Ungrouped',
	ACTION_MODAL_MODES: {
		ADD: 'add',
		EDIT: 'edit',
		DELETE: 'delete',
	},
};

const DATA_TYPES = {
	STRING: 'string',
};

const DATE_FORMAT = 'MM/DD/YY';
const BUYER_CUSTOMER_MAP = {
	BUYERS: 'buyers',
	CUSTOMERS: 'customers',
};
// [TODO]: To be removed after driven from config
const CUSTOM_DATE_FORMATS = {
	MM_DD_YYYY: 'MM/DD/YYYY',
	DD_MMM_YYYY: 'DD MMM YYYY',
	DATE_TIME_ZULU: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
	DATE_TIME: 'YYYY-MM-DDTHH:mm:ss',
};
const TIME_FORMATS = {
	HOUR_12: 'h:mm A', // 12-hour format with AM/PM (e.g., 3:15 AM)
	HOUR_24: 'HH:mm', // 24-hour format (e.g., 15:15)
};
const TENANT_SETTINGS_KEYS = {
	DOCUMENT_TAGS_ENABLED: 'document_tags_enabled',
	INTEGRATION_ACCOUNT_SETTING_KEY: 'is_integration_account',
	PAYMENTS_ENABLED: 'is_payments_enabled',
	SHOW_CUSTOMER_UUID: 'show_customer_system_uuid',
	MANUAL_PAYMENT_STATUS_CHANGE: 'manual_payment_status_change',
	PRESENTATION_ENABLED: 'is_presentation_enabled',
	WISHLIST_ENABLED: 'enable_wishlist',
	IS_FTP_ACCOUNT: 'is_ftp_account',
	QUICKBOOK_INTEGRATION_ENABLED: 'quickbook_online_oauth_enabled',
	VIEW_SIMILAR_ENABLED: 'view_similar_enabled',
};

const ASSIGN_TAG_OPTIONS = [
	{ label: 'Yes', value: 'yes' },
	{ label: 'No', value: 'no' },
];

// [for TEMP fix]
const SAGEBROOK_TEMP_CONSTANTS = {
	TENANT_ID: '4bd1a53b-e30f-4abc-a917-6669da370d71',
	PHONE_CUSTOM_ATTR_ID: 'da57f34c-e7d7-459e-80e6-c3b2c7d0f69d',
};

const SAGEBROOK_TEMP_KEYS = {
	'4bd1a53b-e30f-4abc-a917-6669da370d71': 'da57f34c-e7d7-459e-80e6-c3b2c7d0f69d',
	'5c66253d-ff2d-496d-a49d-0cdc4df6233d': 'da57f34c-e7d7-459e-80e6-c3b2c7d0f69d',
};
const JSON_EDITORS = {
	STAGING: 'https://ultron-git-json-editor-staging-sourcewiz.vercel.app/',
	PREPROD: 'https://ultron-git-json-editor-preprod-sourcewiz.vercel.app/',
	PROD: 'https://ultron-git-json-editor-sourcewiz.vercel.app/',
};

const KALALOUE_ID = '9f044c2a-510e-4e68-8e80-dc36bc464137';

const PAYMENTS_KEY = 'Payments';

const DELETE_SKU_DRAWER_ITEM_PER_PAGE = 50;
const PRODUCT_CARD_TYPE = {
	REVIEW: 'REVIEW' as 'REVIEW',
	ACTION: 'ACTION' as 'ACTION',
	SELECTED: 'SELECTED' as 'SELECTED',
};
const VIEW_SKU_DRAWER_ITEM_PER_PAGE = 50;
const VARIANT_DRAWER_TYPES = {
	VIEW: 'VIEW' as 'VIEW',
	DELETE: 'DELETE' as 'DELETE',
};
const CATALOG_STATUS_KEYS = {
	GENERATED: 'generated',
	NOT_GENERATED: 'not generated',
	FAILED: 'failed',
	IN_PROGRESS: 'in progress',
};
const PRODUCT_DETAILS_CARD_TYPE = {
	REVIEW: 'REVIEW' as 'REVIEW',
	ACTION: 'ACTION' as 'ACTION',
};
const VARIANT_DETAILS_CARD_TYPE = {
	REVIEW: 'REVIEW' as 'REVIEW',
	ACTION: 'ACTION' as 'ACTION',
};
const RECOMMEND_CARD_TYPE = {
	REVIEW: 'REVIEW' as 'REVIEW',
	ACTION: 'ACTION' as 'ACTION',
};
const MAX_CATALOG_STORING_LIMIT_DEFAULT = 300;

const RAILS = {
	ALL_PRODUCTS_SECTION: 'all_products_section',
};
const PAYMENT_DEPENDENT_TABLES = ['payment', 'credit', 'transactions'];

const DATE_NUMBER_COMP_OPTIONS = [
	{ label: 'Equals', value: 'equals' },
	{ label: 'Less than or equals', value: 'lessThanOrEqual' },
	{ label: 'Greater than or equals', value: 'greaterThanOrEqual' },
	{ label: 'Less than', value: 'lessThan' },
	{ label: 'Greater than', value: 'greaterThan' },
	{ label: 'Range', value: 'inRange' },
];

const TEXT_FILTER_DTYPE = ['text', 'email', 'phone', 'longText', 'imageText', 'long_text', 'phone_e164'];

const DATE_FILTER_DTYPE = ['date', 'dateTime'];

const NUMBER_FILTER_DTYPE = ['number', 'currency', 'percentage'];

const SELECT_FILTER_DTYPE = ['select', 'multiselect'];
const DEFAULT_CART_NAME = 'New Cart';
const CART_NOT_FOUND_TOAST_MSGS = {
	title: 'Cart no longer available.',
	subtitle: 'Refreshing latest cart information',
};
const DEFAULT_REFRESH_CART_MSGS = {
	title: 'Refreshing cart',
	subtitle: 'Please wait.',
};

const constants = {
	STATUS,
	ALL_ENV,
	FALLBACK_IMAGE,
	DEFAULT_IMAGE_TIMEOUT,
	ATTRIBUTE_DATE_FORMAT,
	ENDPOINT,
	LOGIN,
	START_TRIAL,
	SWITCH_TENANT,
	REFRESH_TOKEN,
	VERIFY_TOKEN,
	ENABLE_2FA,
	VALIDATE_2FA,
	SET_PASSWORD,
	LOGOUT_API,
	RESET_PASSWORD,
	DEACTIVATE_2FA,
	LOGIN_2FA,
	BLACKLIST_TOKEN,
	GET_USER,
	GET_CART,
	GET_ENTITIES,
	CART,
	BULK_GET,
	CREATE_QUTATION,
	CONFIRM_QUTATION,
	GET_ACCOUNT_LIST,
	GET_ACCOUNT_USERS,
	CREATE_ACCOUNT,
	POSTMAN_MOCK,
	POSTMAN_ENDPOINT,
	UPDATE_QUICK_SETTINGS,
	GET_QUICK_SETTINGS,
	GET_PRODUCT_DETAILS,
	GET_PRODUCTS,
	GET_ALL_FILTERS,
	GET_PRODUCT_VARIANTS,
	GET_PRICE_LEVEL_LIST,
	GET_CREATE_ACCOUNTS_FORM,
	POST_CREATE_ACCOUNT,
	GET_PERMISSIONS,
	BUYER_ADDRESS_FIELDS,
	EXCLUDED_FACETS_LABELS,
	HYPERLINK_FIELDS,
	BUYER_LIST_KEY,
	ATTRIBUTE_DISPLAY_CONFIG,
	CART_GROUPING_KEYS,
	DATA_TYPES,
	DATE_FORMAT,
	BUYER_CUSTOMER_MAP,
	CUSTOM_DATE_FORMATS,
	TIME_FORMATS,
	TENANT_SETTINGS_KEYS,
	ASSIGN_TAG_OPTIONS,
	SAGEBROOK_TEMP_CONSTANTS,
	SAGEBROOK_TEMP_KEYS,
	JSON_EDITORS,
	PAYMENTS_KEY,
	DELETE_SKU_DRAWER_ITEM_PER_PAGE,
	PRODUCT_CARD_TYPE,
	KALALOUE_ID,
	VIEW_SKU_DRAWER_ITEM_PER_PAGE,
	VARIANT_DRAWER_TYPES,
	CATALOG_STATUS_KEYS,
	PRODUCT_DETAILS_CARD_TYPE,
	VARIANT_DETAILS_CARD_TYPE,
	RECOMMEND_CARD_TYPE,
	MAX_CATALOG_STORING_LIMIT_DEFAULT,
	RAILS,
	SMART_SEARCH_UPLOAD_IMAGE,
	PAYMENT_DEPENDENT_TABLES,
	ATTRIBUTE_DATE_TIME_FORMAT,
	DATE_NUMBER_COMP_OPTIONS,
	TEXT_FILTER_DTYPE,
	DATE_FILTER_DTYPE,
	NUMBER_FILTER_DTYPE,
	SELECT_FILTER_DTYPE,
	DEFAULT_CART_NAME,
	CART_NOT_FOUND_TOAST_MSGS,
	DEFAULT_REFRESH_CART_MSGS,
};

export default constants;
