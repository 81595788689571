/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker, TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ValidationProps } from 'src/utils/apply_validations';
import dayjs from 'dayjs';
import { colors } from 'src/utils/theme';
import Grid from '../Grid';
import constants from 'src/utils/constants';

interface Props {
	name: string;
	label?: string;
	validations?: ValidationProps;
	refInput?: any;
	value?: any;
	defaultValue?: any;
	onChangeCapture?: any;
	disablePast?: boolean;
	component_type?: string;
	selected_timezone?: any;
	handle_error?: any;
	disablePastTime?: any;
}

const time_format = constants.TIME_FORMATS.HOUR_12;

const SeperateDateTimeEditField = ({
	name,
	label,
	validations,
	defaultValue,
	onChangeCapture,
	disablePast,
	selected_timezone,
	handle_error,
	disablePastTime,
	...props
}: Props) => {
	const [new_value, set_new_value] = useState(defaultValue);
	const { control, watch, setValue } = useFormContext();
	const watched_value = watch(name);
	const [date, set_date] = useState<any>(null);
	const [time, set_time] = useState<any>(null);

	useEffect(() => {
		if (defaultValue && !date && !time) {
			setValue(name, defaultValue);
			const local_date_time = dayjs(defaultValue).tz(selected_timezone);
			set_date(local_date_time);
			set_time(local_date_time);
		}
	}, [defaultValue]);

	useEffect(() => {
		if (watched_value) {
			const local_date_time = dayjs.utc(watched_value).tz(selected_timezone);
			set_date(local_date_time.startOf('day'));
			set_time(local_date_time);
		}
	}, [selected_timezone]);

	useEffect(() => {
		if (watched_value === new_value) return;
		set_new_value(watched_value);
	}, [watched_value]);

	useEffect(() => {
		if (date && time && selected_timezone) {
			const mergedDateTime = dayjs(date)?.hour(time?.hour()).minute(time?.minute());
			setValue(name, mergedDateTime);
			// const formattedDateTime = mergedDateTime.tz(selected_timezone).format('YYYY-MM-DD HH:mm A');
			// console.log(formattedDateTime, 'formatted');
			// console.log(mergedDateTime, 'without formatting');
		} else {
			setValue(name, null);
		}
	}, [date, time, selected_timezone]);

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Controller
				name={name}
				control={control}
				defaultValue={defaultValue}
				render={({ field, fieldState: { error } }) => {
					return (
						<Grid sx={{ display: 'flex', width: '100%', gap: '1rem' }}>
							<DatePicker
								sx={{
									width: '100%',
									'& .MuiInputBase-root': {
										height: '36px',
									},
								}}
								{...field}
								{...props}
								timezone={selected_timezone}
								disablePast={disablePast}
								format={constants.CUSTOM_DATE_FORMATS.MM_DD_YYYY}
								value={date}
								onError={(newError) => handle_error(name, newError)}
								onChange={(val) => set_date(val)}
								renderInput={(params: any) => <TextField {...params} label={label} />}
								slotProps={{
									textField: {
										helperText: error ? <p style={{ color: colors.red }}>{error?.message}</p> : '',
									},
									actionBar: {
										actions: ['accept'],
									},
								}}
							/>

							<TimePicker
								sx={{
									width: '100%',
									'& .MuiInputBase-root': {
										height: '36px',
									},
								}}
								{...field}
								{...props}
								disablePast={disablePastTime}
								closeOnSelect={false}
								timezone={selected_timezone}
								disabled={!date}
								format={time_format}
								onError={(newError) => handle_error(name, newError)}
								value={time}
								onChange={(val) => set_time(val)}
								renderInput={(params: any) => <TextField {...params} label={label} />}
								slotProps={{
									textField: {
										helperText: error ? <p style={{ color: colors.red }}>{error?.message}</p> : '',
									},
									actionBar: {
										actions: ['accept'],
									},
								}}
							/>
						</Grid>
					);
				}}
			/>
		</LocalizationProvider>
	);
};

export default SeperateDateTimeEditField;
