/* eslint-disable */
import CustomText from 'src/common/@the-source/CustomText';
import { PLACE_HOLDER_IMAGE } from 'src/screens/ProductDetailsPage/constants';
import { transform_image_url } from 'src/utils/ImageConstants';
import { Box, Checkbox, Image, Button, Icon, Radio, Grid } from 'src/common/@the-source/atoms';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { handle_field_validations } from '../helper';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import ImageLightbox from 'src/common/@the-source/atoms/LightBox/LightBox';
import { useTheme } from '@mui/material';
import { get_formatted_price_with_currency } from 'src/utils/common';
import style from '../customproduct.module.css';

interface ImageProps {
	values: any[];
	show_checkbox?: boolean;
	options: any;
	onChange: any;
	id: string;
	is_mandatory: boolean;
	max_selection_quantity: number;
	min_selection_quantity: number;
	handleError: any;
	search_string_style?: {};
	currency: string;
}

const selected_style = {
	borderRadius: '8px',
	border: '1px solid #4F555E',
	background: '#F7F8FA',
	boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.08)',
	padding: '6px',
};

const useStyles = makeStyles((theme: any) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'column',
		width: '170px',
		textAlign: 'center',
		padding: '6px 8px',
		cursor: 'pointer',
		height: '100%',
	},
	overlay: {
		position: 'absolute',
		inset: 0,
		backgroundColor: theme?.light_box?.modal?.overlay_config?.background,
		opacity: 0,
		transition: 'opacity 0.3s ease',
		pointerEvents: 'none',
	},
	image_style: {
		width: '100%',
		height: '100%',
		maxWidth: '12rem',
		maxHeight: '12rem',
		borderRadius: 8,
		border: `1px solid ${theme?.light_box?.modal?.modifiers?.image_border}`,
		marginBottom: 5,
		cursor: 'pointer',
	},
	wrapper_container: {
		display: 'grid',
		gap: '20px',
		gridTemplateColumns: 'repeat(3, 1fr)',
		gridTemplateRows: 'auto',
		alignItems: 'center',
		justifyItems: 'center',
	},
	image_container: {
		position: 'relative',
		width: '12rem',
		height: '12rem',
		marginBottom: 6,
	},
	icon: {
		position: 'absolute',
		top: '2px',
		right: '2px',
		opacity: 0,
		cursor: 'pointer',
		transition: 'opacity 0.3s ease',
	},
	text_component: {
		textOverflow: 'ellipsis',
		maxWidth: '100px',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		width: '85px',
	},
	image_container_active: {
		'&:hover $overlay': {
			opacity: 1,
		},
		'&:hover $icon': {
			opacity: 1,
		},
	},
}));

const ImageComponent = ({
	values,
	show_checkbox = false,
	onChange,
	options,
	id,
	is_mandatory,
	max_selection_quantity,
	min_selection_quantity,
	handleError,
	search_string_style,
	currency,
}: ImageProps) => {
	const styles = useStyles();
	const [selectedOptions, setSelectedOptions] = useState<any[]>(options ? options.split(',').map((item: any) => item.trim()) : []);
	const [lightboxOpen, setLightboxOpen] = useState<boolean>(false);
	const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
	const [open, set_open] = useState(false);
	const image_preview = useSelector((state: any) => state?.settings?.image_preview);
	const theme: any = useTheme();

	const handleContextMenu = (e: any) => {
		e.preventDefault();
	};

	const handleCheckboxChange = (name: string, e: React.ChangeEvent<HTMLInputElement>) => {
		e.stopPropagation();

		// eslint-disable-next-line no-confusing-arrow
		setSelectedOptions((prevSelected: any) => {
			let newSelected;
			if (prevSelected?.includes(name)) {
				newSelected = prevSelected?.filter((item: string) => item !== name);
			} else {
				newSelected = [...prevSelected, name];
				if (max_selection_quantity && newSelected?.length > max_selection_quantity) {
					return prevSelected;
				}
			}
			return newSelected;
		});
	};

	const handleClickImage = (e: any, index: number, name: string) => {
		if (image_preview) {
			setSelectedImageIndex(index);
			setLightboxOpen(true);
		} else {
			handleSelect(name, e);
		}
	};

	const handleSelect = (name: string, e: any) => {
		e.stopPropagation();
		if (!show_checkbox) {
			setSelectedOptions(selectedOptions?.includes(name) ? [] : [name]);
		} else {
			handleCheckboxChange(name, e);
		}
	};

	const images = (values || [])?.map((curr) => ({
		src: curr?.image || '',
		download: {
			url: curr?.image || '',
			filename: curr?.display_name || 'download',
		},
	}));

	const handle_click = (e: any, index: number, name: string) => {
		e?.stopPropagation();
		handleClickImage(e, index, name);
	};

	useEffect(() => {
		if (options) {
			setSelectedOptions(options?.split(',')?.map((item: any) => item?.trim()));
		}
	}, [options]);

	useEffect(() => {
		onChange({ [id]: selectedOptions });
		handleError({ [id]: handle_field_validations(selectedOptions?.length, is_mandatory, min_selection_quantity, max_selection_quantity) });
	}, [selectedOptions]);

	return (
		<div style={search_string_style} onContextMenu={handleContextMenu}>
			<div className={styles.wrapper_container}>
				{_.isArray(values) &&
					values?.slice(0, open ? values?.length : 10).map((curr: any, index: number) => {
						const price = get_formatted_price_with_currency(currency, curr?.price);
						const is_negative = Number(curr?.price) < 0;
						return (
							<>
								<Box
									className={styles.container}
									key={curr?.id}
									style={{
										...(selectedOptions.includes(curr?.name) ? selected_style : {}),
									}}>
									<Grid display={'flex'} flexDirection={'column'} height={'100%'} alignItems={'center'}>
										<Box className={`${styles.image_container} ${image_preview ? styles.image_container_active : ''}`}>
											<Image
												id={curr?.id}
												fallbackSrc={PLACE_HOLDER_IMAGE}
												src={transform_image_url(curr?.image, 'VARIANT_HINGE')}
												alt='image'
												imgClass={styles.image_style}
												style={{ cursor: image_preview ? 'pointer' : 'default' }}
												onClick={(e: any) => handle_click(e, index, curr?.name)}
											/>
											{image_preview && (
												<React.Fragment>
													<Box className={styles.overlay} />
													<Icon
														iconName='IconArrowsDiagonal'
														color={theme?.light_box?.modal?.modifiers?.icon_color}
														className={styles.icon}
														onClick={(e: any) => handle_click(e, index, curr?.name)}
													/>
												</React.Fragment>
											)}
										</Box>
										<Grid flex={1} display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
											<CustomText
												style={{
													textOverflow: 'ellipsis',
													maxWidth: '100px',
													// whiteSpace: 'nowrap',
													overflow: 'hidden',
													width: '100%',
												}}
												className={style.overflow_two_line}
												type='Caption'>
												{curr?.display_name}
											</CustomText>
										</Grid>
									</Grid>
									<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
										{show_checkbox ? (
											<Checkbox checked={selectedOptions?.includes(curr?.name)} onClick={(e: any) => handleSelect(curr?.name, e)} />
										) : (
											<Radio checked={selectedOptions?.includes(curr?.name)} onClick={(e: any) => handleSelect(curr?.name, e)} />
										)}
										<CustomText type='Caption' style={{ fontWeight: '700' }} color={is_negative ? 'rgb(215, 76, 16)' : '#000'}>
											{price}
										</CustomText>
									</Box>
								</Box>
							</>
						);
					})}
			</div>
			{values?.length > 10 && (
				<Button
					sx={{ height: '40px' }}
					width='100px'
					variant='text'
					onClick={() => {
						set_open(!open);
					}}>
					{open ? ' See less ' : `+ ${values?.length - 10} more`}
					{open && <Icon sx={{ ml: 0.5 }} iconName='IconChevronUp' color='#16885F' />}
				</Button>
			)}
			{image_preview && (
				<ImageLightbox
					open={lightboxOpen}
					onClose={() => setLightboxOpen(false)}
					images={images}
					selectedIndex={selectedImageIndex}
					overrideWidth={900}
					overrideHeight={700}
				/>
			)}
		</div>
	);
};

export default ImageComponent;
