import { SvgIcon, SvgIconProps } from '@mui/material';
import {
	Icon2fa,
	Icon3dCubeSphere,
	Icon3dCubeSphereOff,
	Icon3dRotate,
	Icon24Hours,
	Icon123,
	IconUsersGroup,
	Icon360,
	Icon360View,
	IconAB,
	IconAB2,
	IconAbacus,
	IconAbacusOff,
	IconAbc,
	IconABOff,
	IconAccessible,
	IconAccessibleOff,
	IconAccessibleOffFilled,
	IconAccessPoint,
	IconAccessPointOff,
	IconActivity,
	IconActivityHeartbeat,
	IconAd,
	IconAd2,
	IconAdCircle,
	IconAdCircleOff,
	IconAddressBook,
	IconAddressBookOff,
	IconAdFilled,
	IconAdjustments,
	IconAdjustmentsAlt,
	IconAdjustmentsFilled,
	IconAdjustmentsHorizontal,
	IconAdjustmentsOff,
	IconAdOff,
	IconAerialLift,
	IconAffiliate,
	IconAffiliateFilled,
	IconAirBalloon,
	IconAirConditioning,
	IconAirConditioningDisabled,
	IconAlarm,
	IconAlarmFilled,
	IconAlarmMinus,
	IconAlarmMinusFilled,
	IconAlarmOff,
	IconAlarmPlus,
	IconAlarmPlusFilled,
	IconAlarmSnooze,
	IconAlarmSnoozeFilled,
	IconAlbum,
	IconAlbumOff,
	IconAlertCircle,
	IconAlertCircleFilled,
	IconAlertOctagon,
	IconAlertOctagonFilled,
	IconAlertTriangle,
	IconAlertTriangleFilled,
	IconAlien,
	IconAlienFilled,
	IconAlignBoxBottomCenter,
	IconAlignBoxBottomCenterFilled,
	IconAlignBoxBottomLeft,
	IconAlignBoxBottomLeftFilled,
	IconAlignBoxBottomRight,
	IconAlignBoxBottomRightFilled,
	IconAlignBoxCenterMiddle,
	IconAlignBoxLeftBottom,
	IconAlignBoxLeftBottomFilled,
	IconAlignBoxLeftMiddle,
	IconAlignBoxLeftMiddleFilled,
	IconAlignBoxLeftTop,
	IconAlignBoxLeftTopFilled,
	IconAlignBoxRightBottom,
	IconAlignBoxRightBottomFilled,
	IconAlignBoxRightMiddle,
	IconAlignBoxRightTop,
	IconAlignBoxRightTopFilled,
	IconAlignBoxTopCenter,
	IconAlignBoxTopCenterFilled,
	IconAlignBoxTopLeft,
	IconAlignBoxTopLeftFilled,
	IconAlignBoxTopRight,
	IconAlignBoxTopRightFilled,
	IconAlignCenter,
	IconAlignJustified,
	IconAlignLeft,
	IconAlignRight,
	IconAlpha,
	IconAlphabetCyrillic,
	IconAlphabetGreek,
	IconAlphabetLatin,
	IconAmbulance,
	IconAmpersand,
	IconAnalyze,
	IconAnalyzeFilled,
	IconAnalyzeOff,
	IconAnchor,
	IconAnchorOff,
	IconAngle,
	IconAnkh,
	IconAntenna,
	IconAntennaBars1,
	IconAntennaBars2,
	IconAntennaBars3,
	IconAntennaBars4,
	IconAntennaBars5,
	IconAntennaBarsOff,
	IconAntennaOff,
	IconAperture,
	IconApertureOff,
	IconApi,
	IconApiApp,
	IconApiAppOff,
	IconApiOff,
	IconApple,
	IconApps,
	IconAppsFilled,
	IconAppsOff,
	IconAppWindow,
	IconAppWindowFilled,
	IconArchive,
	IconArchiveOff,
	IconArmchair,
	IconArmchair2,
	IconArmchair2Off,
	IconArmchairOff,
	IconArrowAutofitContent,
	IconArrowAutofitContentFilled,
	IconArrowAutofitDown,
	IconArrowAutofitHeight,
	IconArrowAutofitLeft,
	IconArrowAutofitRight,
	IconArrowAutofitUp,
	IconArrowAutofitWidth,
	IconArrowBack,
	IconArrowBackUp,
	IconArrowBadgeDown,
	IconArrowBadgeLeft,
	IconArrowBadgeRight,
	IconArrowBadgeUp,
	IconArrowBarDown,
	IconArrowBarLeft,
	IconArrowBarRight,
	IconArrowBarToDown,
	IconArrowBarToLeft,
	IconArrowBarToRight,
	IconArrowBarToUp,
	IconArrowBarUp,
	IconArrowBearLeft,
	IconArrowBearLeft2,
	IconArrowBearRight,
	IconArrowBearRight2,
	IconArrowBigDown,
	IconArrowBigDownFilled,
	IconArrowBigDownLine,
	IconArrowBigDownLineFilled,
	IconArrowBigDownLines,
	IconArrowBigDownLinesFilled,
	IconArrowBigLeft,
	IconArrowBigLeftFilled,
	IconArrowBigLeftLine,
	IconArrowBigLeftLineFilled,
	IconArrowBigLeftLines,
	IconArrowBigLeftLinesFilled,
	IconArrowBigRight,
	IconArrowBigRightFilled,
	IconArrowBigRightLine,
	IconArrowBigRightLineFilled,
	IconArrowBigRightLines,
	IconArrowBigRightLinesFilled,
	IconArrowBigUp,
	IconArrowBigUpFilled,
	IconArrowBigUpLine,
	IconArrowBigUpLineFilled,
	IconArrowBigUpLines,
	IconArrowBigUpLinesFilled,
	IconArrowBounce,
	IconArrowCurveLeft,
	IconArrowCurveRight,
	IconArrowDown,
	IconArrowDownBar,
	IconArrowDownCircle,
	IconArrowDownLeft,
	IconArrowDownLeftCircle,
	IconArrowDownRhombus,
	IconArrowDownRight,
	IconArrowDownRightCircle,
	IconArrowDownSquare,
	IconArrowDownTail,
	IconArrowFork,
	IconArrowForward,
	IconArrowForwardUp,
	IconArrowGuide,
	IconArrowIteration,
	IconArrowLeft,
	IconArrowLeftBar,
	IconArrowLeftCircle,
	IconArrowLeftRhombus,
	IconArrowLeftRight,
	IconArrowLeftSquare,
	IconArrowLeftTail,
	IconArrowLoopLeft,
	IconArrowLoopLeft2,
	IconArrowLoopRight,
	IconArrowLoopRight2,
	IconArrowMerge,
	IconArrowMergeBoth,
	IconArrowMergeLeft,
	IconArrowMergeRight,
	IconArrowMoveDown,
	IconArrowMoveLeft,
	IconArrowMoveRight,
	IconArrowMoveUp,
	IconArrowNarrowDown,
	IconArrowNarrowLeft,
	IconArrowNarrowRight,
	IconArrowNarrowUp,
	IconArrowRampLeft,
	IconArrowRampLeft2,
	IconArrowRampLeft3,
	IconArrowRampRight,
	IconArrowRampRight2,
	IconArrowRampRight3,
	IconArrowRight,
	IconArrowRightBar,
	IconArrowRightCircle,
	IconArrowRightRhombus,
	IconArrowRightSquare,
	IconArrowRightTail,
	IconArrowRotaryFirstLeft,
	IconArrowRotaryFirstRight,
	IconArrowRotaryLastLeft,
	IconArrowRotaryLastRight,
	IconArrowRotaryLeft,
	IconArrowRotaryRight,
	IconArrowRotaryStraight,
	IconArrowRoundaboutLeft,
	IconArrowRoundaboutRight,
	IconArrowsCross,
	IconArrowsDiagonal,
	IconArrowsDiagonal2,
	IconArrowsDiagonalMinimize,
	IconArrowsDiagonalMinimize2,
	IconArrowsDiff,
	IconArrowsDoubleNeSw,
	IconArrowsDoubleNwSe,
	IconArrowsDoubleSeNw,
	IconArrowsDoubleSwNe,
	IconArrowsDown,
	IconArrowsDownUp,
	IconArrowsExchange,
	IconArrowsExchange2,
	IconArrowSharpTurnLeft,
	IconArrowSharpTurnRight,
	IconArrowsHorizontal,
	IconArrowsJoin,
	IconArrowsJoin2,
	IconArrowsLeft,
	IconArrowsLeftDown,
	IconArrowsLeftRight,
	IconArrowsMaximize,
	IconArrowsMinimize,
	IconArrowsMove,
	IconArrowsMoveHorizontal,
	IconArrowsMoveVertical,
	IconArrowsRandom,
	IconArrowsRight,
	IconArrowsRightDown,
	IconArrowsRightLeft,
	IconArrowsShuffle,
	IconArrowsShuffle2,
	IconArrowsSort,
	IconArrowsSplit,
	IconArrowsSplit2,
	IconArrowsTransferDown,
	IconArrowsTransferUp,
	IconArrowsUp,
	IconArrowsUpDown,
	IconArrowsUpLeft,
	IconArrowsUpRight,
	IconArrowsVertical,
	IconArrowUp,
	IconArrowUpBar,
	IconArrowUpCircle,
	IconArrowUpLeft,
	IconArrowUpLeftCircle,
	IconArrowUpRhombus,
	IconArrowUpRight,
	IconArrowUpRightCircle,
	IconArrowUpSquare,
	IconArrowUpTail,
	IconArrowWaveLeftDown,
	IconArrowWaveLeftUp,
	IconArrowWaveRightDown,
	IconArrowWaveRightUp,
	IconArrowZigZag,
	IconArtboard,
	IconArtboardOff,
	IconArticle,
	IconArticleOff,
	IconAspectRatio,
	IconAspectRatioOff,
	IconAssembly,
	IconAssemblyOff,
	IconAsset,
	IconAsterisk,
	IconAsteriskSimple,
	IconAt,
	IconAtOff,
	IconAtom,
	IconAtom2,
	IconAtom2Filled,
	IconAtomOff,
	IconAugmentedReality,
	IconAugmentedReality2,
	IconAugmentedRealityOff,
	IconAward,
	IconAwardFilled,
	IconAwardOff,
	IconAxe,
	IconAxisX,
	IconAxisY,
	IconBabyBottle,
	IconBabyCarriage,
	IconBackhoe,
	IconBackpack,
	IconBackpackOff,
	IconBackspace,
	IconBadge,
	IconBadge3d,
	IconBadge4k,
	IconBadge8k,
	IconBadgeAd,
	IconBadgeAr,
	IconBadgeCc,
	IconBadgeFilled,
	IconBadgeHd,
	IconBadgeOff,
	IconBadges,
	IconBadgeSd,
	IconBadgesOff,
	IconBadgeTm,
	IconBadgeVo,
	IconBadgeVr,
	IconBadgeWc,
	IconBaguette,
	IconBallAmericanFootball,
	IconBallAmericanFootballOff,
	IconBallBaseball,
	IconBallBasketball,
	IconBallBowling,
	IconBallFootball,
	IconBallFootballOff,
	IconBalloon,
	IconBalloonOff,
	IconBallpen,
	IconBallpenOff,
	IconBallTennis,
	IconBallVolleyball,
	IconBan,
	IconBandage,
	IconBandageOff,
	IconBarbell,
	IconBarbellOff,
	IconBarcode,
	IconBarcodeOff,
	IconBarrel,
	IconBarrelOff,
	IconBarrierBlock,
	IconBarrierBlockOff,
	IconBaseline,
	IconBasket,
	IconBasketOff,
	IconBat,
	IconBath,
	IconBathFilled,
	IconBathOff,
	IconBattery,
	IconBattery1,
	IconBattery1Filled,
	IconBattery2,
	IconBattery2Filled,
	IconBattery3,
	IconBattery3Filled,
	IconBattery4,
	IconBattery4Filled,
	IconBatteryAutomotive,
	IconBatteryCharging,
	IconBatteryCharging2,
	IconBatteryEco,
	IconBatteryFilled,
	IconBatteryOff,
	IconBeach,
	IconBeachOff,
	IconBed,
	IconBedOff,
	IconBeer,
	IconBeerOff,
	IconBell,
	IconBellFilled,
	IconBellMinus,
	IconBellMinusFilled,
	IconBellOff,
	IconBellPlus,
	IconBellPlusFilled,
	IconBellRinging,
	IconBellRinging2,
	IconBellRinging2Filled,
	IconBellRingingFilled,
	IconBellSchool,
	IconBellX,
	IconBellXFilled,
	IconBellZ,
	IconBellZFilled,
	IconBeta,
	IconBible,
	IconBike,
	IconBikeOff,
	IconBinary,
	IconBinaryOff,
	IconBinaryTree,
	IconBinaryTree2,
	IconBiohazard,
	IconBiohazardOff,
	IconBlade,
	IconBleach,
	IconBleachChlorine,
	IconBleachNoChlorine,
	IconBleachOff,
	IconBlockquote,
	IconBluetooth,
	IconBluetoothConnected,
	IconBluetoothOff,
	IconBluetoothX,
	IconBlur,
	IconBlurOff,
	IconBmp,
	IconBold,
	IconBoldOff,
	IconBolt,
	IconBoltOff,
	IconBomb,
	IconBone,
	IconBoneOff,
	IconBong,
	IconBongOff,
	IconBook,
	IconBook2,
	IconBookDownload,
	IconBookmark,
	IconBookmarkOff,
	IconBookmarks,
	IconBookmarksOff,
	IconBookOff,
	IconBooks,
	IconBooksOff,
	IconBookUpload,
	IconBorderAll,
	IconBorderBottom,
	IconBorderCorners,
	IconBorderHorizontal,
	IconBorderInner,
	IconBorderLeft,
	IconBorderNone,
	IconBorderOuter,
	IconBorderRadius,
	IconBorderRight,
	IconBorderSides,
	IconBorderStyle,
	IconBorderStyle2,
	IconBorderTop,
	IconBorderVertical,
	IconBottle,
	IconBottleOff,
	IconBounceLeft,
	IconBounceRight,
	IconBow,
	IconBowl,
	IconBox,
	IconBoxAlignBottom,
	IconBoxAlignBottomLeft,
	IconBoxAlignBottomRight,
	IconBoxAlignLeft,
	IconBoxAlignRight,
	IconBoxAlignTop,
	IconBoxAlignTopLeft,
	IconBoxAlignTopRight,
	IconBoxMargin,
	IconBoxModel,
	IconBoxModel2,
	IconBoxModel2Off,
	IconBoxModelOff,
	IconBoxMultiple,
	IconBoxMultiple0,
	IconBoxMultiple1,
	IconBoxMultiple2,
	IconBoxMultiple3,
	IconBoxMultiple4,
	IconBoxMultiple5,
	IconBoxMultiple6,
	IconBoxMultiple7,
	IconBoxMultiple8,
	IconBoxMultiple9,
	IconBoxOff,
	IconBoxPadding,
	IconBoxSeam,
	IconBraces,
	IconBracesOff,
	IconBrackets,
	IconBracketsContain,
	IconBracketsContainEnd,
	IconBracketsContainStart,
	IconBracketsOff,
	IconBraille,
	IconBrain,
	IconBrand4chan,
	IconBrandAbstract,
	IconBrandAdobe,
	IconBrandAdonisJs,
	IconBrandAirbnb,
	IconBrandAirtable,
	IconBrandAlgolia,
	IconBrandAlipay,
	IconBrandAlpineJs,
	IconBrandAmazon,
	IconBrandAmd,
	IconBrandAmigo,
	IconBrandAmongUs,
	IconBrandAndroid,
	IconBrandAngular,
	IconBrandAo3,
	IconBrandAppgallery,
	IconBrandApple,
	IconBrandAppleArcade,
	IconBrandApplePodcast,
	IconBrandAppstore,
	IconBrandAsana,
	IconBrandBackbone,
	IconBrandBadoo,
	IconBrandBaidu,
	IconBrandBandcamp,
	IconBrandBandlab,
	IconBrandBeats,
	IconBrandBehance,
	IconBrandBilibili,
	IconBrandBinance,
	IconBrandBing,
	IconBrandBitbucket,
	IconBrandBlackberry,
	IconBrandBlender,
	IconBrandBlogger,
	IconBrandBooking,
	IconBrandBootstrap,
	IconBrandBulma,
	IconBrandBumble,
	IconBrandBunpo,
	IconBrandCake,
	IconBrandCampaignmonitor,
	IconBrandCarbon,
	IconBrandCashapp,
	IconBrandChrome,
	IconBrandCitymapper,
	IconBrandCodecov,
	IconBrandCodepen,
	IconBrandCodesandbox,
	IconBrandCohost,
	IconBrandCoinbase,
	IconBrandComedyCentral,
	IconBrandCoreos,
	IconBrandCouchdb,
	IconBrandCouchsurfing,
	IconBrandCpp,
	IconBrandCSharp,
	IconBrandCss3,
	IconBrandCtemplar,
	IconBrandCucumber,
	IconBrandCupra,
	IconBrandCypress,
	IconBrandD3,
	IconBrandDaysCounter,
	IconBrandDcos,
	IconBrandDebian,
	IconBrandDeezer,
	IconBrandDeliveroo,
	IconBrandDeno,
	IconBrandDenodo,
	IconBrandDeviantart,
	IconBrandDingtalk,
	IconBrandDiscord,
	IconBrandDisney,
	IconBrandDisqus,
	IconBrandDjango,
	IconBrandDocker,
	IconBrandDoctrine,
	IconBrandDolbyDigital,
	IconBrandDouban,
	IconBrandDribbble,
	IconBrandDrops,
	IconBrandDrupal,
	IconBrandEdge,
	IconBrandElastic,
	IconBrandEmber,
	IconBrandEnvato,
	IconBrandEtsy,
	IconBrandEvernote,
	IconBrandFacebook,
	IconBrandFigma,
	IconBrandFinder,
	IconBrandFirebase,
	IconBrandFirefox,
	IconBrandFiverr,
	IconBrandFlickr,
	IconBrandFlightradar24,
	IconBrandFlipboard,
	IconBrandFlutter,
	IconBrandFortnite,
	IconBrandFoursquare,
	IconBrandFramer,
	IconBrandFramerMotion,
	IconBrandFunimation,
	IconBrandGatsby,
	IconBrandGit,
	IconBrandGithub,
	IconBrandGithubCopilot,
	IconBrandGitlab,
	IconBrandGmail,
	IconBrandGolang,
	IconBrandGoogle,
	IconBrandGoogleAnalytics,
	IconBrandGoogleBigQuery,
	IconBrandGoogleDrive,
	IconBrandGoogleFit,
	IconBrandGoogleHome,
	IconBrandGoogleOne,
	IconBrandGooglePhotos,
	IconBrandGooglePlay,
	IconBrandGooglePodcasts,
	IconBrandGrammarly,
	IconBrandGraphql,
	IconBrandGravatar,
	IconBrandGrindr,
	IconBrandGuardian,
	IconBrandGumroad,
	IconBrandHbo,
	IconBrandHeadlessui,
	IconBrandHipchat,
	IconBrandHtml5,
	IconBrandInertia,
	IconBrandInstagram,
	IconBrandIntercom,
	IconBrandJavascript,
	IconBrandKickstarter,
	IconBrandKotlin,
	IconBrandLaravel,
	IconBrandLastfm,
	IconBrandLinkedin,
	IconBrandLinktree,
	IconBrandLinqpad,
	IconBrandLoom,
	IconBrandMailgun,
	IconBrandMantine,
	IconBrandMastercard,
	IconBrandMastodon,
	IconBrandMatrix,
	IconBrandMcdonalds,
	IconBrandMedium,
	IconBrandMercedes,
	IconBrandMessenger,
	IconBrandMeta,
	IconBrandMiniprogram,
	IconBrandMixpanel,
	IconBrandMonday,
	IconBrandMongodb,
	IconBrandMyOppo,
	IconBrandMysql,
	IconBrandNationalGeographic,
	IconBrandNem,
	IconBrandNetbeans,
	IconBrandNeteaseMusic,
	IconBrandNetflix,
	IconBrandNexo,
	IconBrandNextcloud,
	IconBrandNextjs,
	IconBrandNordVpn,
	IconBrandNotion,
	IconBrandNpm,
	IconBrandNuxt,
	IconBrandNytimes,
	IconBrandOffice,
	IconBrandOkRu,
	IconBrandOnedrive,
	IconBrandOnlyfans,
	IconBrandOpenai,
	IconBrandOpenSource,
	IconBrandOpenvpn,
	IconBrandOpera,
	IconBrandPagekit,
	IconBrandPatreon,
	IconBrandPaypal,
	IconBrandPaypay,
	IconBrandPeanut,
	IconBrandPepsi,
	IconBrandPhp,
	IconBrandPicsart,
	IconBrandPinterest,
	IconBrandPlanetscale,
	IconBrandPocket,
	IconBrandPolymer,
	IconBrandPowershell,
	IconBrandPrisma,
	IconBrandProducthunt,
	IconBrandPushbullet,
	IconBrandPushover,
	IconBrandPython,
	IconBrandQq,
	IconBrandRadixUi,
	IconBrandReact,
	IconBrandReactNative,
	IconBrandReason,
	IconBrandReddit,
	IconBrandRedhat,
	IconBrandRedux,
	IconBrandRevolut,
	IconBrandSafari,
	IconBrandSamsungpass,
	IconBrandSass,
	IconBrandSentry,
	IconBrandSharik,
	IconBrandShazam,
	IconBrandShopee,
	IconBrandSketch,
	IconBrandSkype,
	IconBrandSlack,
	IconBrandSnapchat,
	IconBrandSnapseed,
	IconBrandSnowflake,
	IconBrandSocketIo,
	IconBrandSolidjs,
	IconBrandSoundcloud,
	IconBrandSpacehey,
	IconBrandSpotify,
	IconBrandStackoverflow,
	IconBrandStackshare,
	IconBrandSteam,
	IconBrandStorybook,
	IconBrandStorytel,
	IconBrandStrava,
	IconBrandStripe,
	IconBrandSublimeText,
	IconBrandSugarizer,
	IconBrandSupabase,
	IconBrandSuperhuman,
	IconBrandSupernova,
	IconBrandSurfshark,
	IconBrandSvelte,
	IconBrandSymfony,
	IconBrandTabler,
	IconBrandTailwind,
	IconBrandTaobao,
	IconBrandTed,
	IconBrandTelegram,
	IconBrandTether,
	IconBrandThreejs,
	IconBrandTidal,
	IconBrandTiktok,
	IconBrandTinder,
	IconBrandTopbuzz,
	IconBrandTorchain,
	IconBrandToyota,
	IconBrandTrello,
	IconBrandTripadvisor,
	IconBrandTumblr,
	IconBrandTwilio,
	IconBrandTwitch,
	IconBrandTwitter,
	IconBrandTypescript,
	IconBrandUber,
	IconBrandUbuntu,
	IconBrandUnity,
	IconBrandUnsplash,
	IconBrandUpwork,
	IconBrandValorant,
	IconBrandVercel,
	IconBrandVimeo,
	IconBrandVinted,
	IconBrandVisa,
	IconBrandVisualStudio,
	IconBrandVite,
	IconBrandVivaldi,
	IconBrandVk,
	IconBrandVolkswagen,
	IconBrandVsco,
	IconBrandVscode,
	IconBrandVue,
	IconBrandWalmart,
	IconBrandWaze,
	IconBrandWebflow,
	IconBrandWechat,
	IconBrandWeibo,
	IconBrandWhatsapp,
	IconBrandWindows,
	IconBrandWindy,
	IconBrandWish,
	IconBrandWix,
	IconBrandWordpress,
	IconBrandXbox,
	IconBrandXing,
	IconBrandYahoo,
	IconBrandYatse,
	IconBrandYcombinator,
	IconBrandYoutube,
	IconBrandYoutubeKids,
	IconBrandZalando,
	IconBrandZapier,
	IconBrandZeit,
	IconBrandZhihu,
	IconBrandZoom,
	IconBrandZulip,
	IconBrandZwift,
	IconBread,
	IconBreadOff,
	IconBriefcase,
	IconBriefcaseOff,
	IconBrightness,
	IconBrightness2,
	IconBrightnessDown,
	IconBrightnessHalf,
	IconBrightnessOff,
	IconBrightnessUp,
	IconBroadcast,
	IconBroadcastOff,
	IconBrowser,
	IconBrowserCheck,
	IconBrowserOff,
	IconBrowserPlus,
	IconBrowserX,
	IconBrush,
	IconBrushOff,
	IconBucket,
	IconBucketDroplet,
	IconBucketOff,
	IconBug,
	IconBugOff,
	IconBuilding,
	IconBuildingArch,
	IconBuildingBank,
	IconBuildingBridge,
	IconBuildingBridge2,
	IconBuildingBroadcastTower,
	IconBuildingCarousel,
	IconBuildingCastle,
	IconBuildingChurch,
	IconBuildingCircus,
	IconBuildingCommunity,
	IconBuildingCottage,
	IconBuildingEstate,
	IconBuildingFactory,
	IconBuildingFactory2,
	IconBuildingFortress,
	IconBuildingHospital,
	IconBuildingLighthouse,
	IconBuildingMonument,
	IconBuildingPavilion,
	IconBuildingSkyscraper,
	IconBuildingStadium,
	IconBuildingStore,
	IconBuildingTunnel,
	IconBuildingWarehouse,
	IconBuildingWindTurbine,
	IconBulb,
	IconBulbFilled,
	IconBulbOff,
	IconBulldozer,
	IconBus,
	IconBusinessplan,
	IconBusOff,
	IconBusStop,
	IconButterfly,
	IconCactus,
	IconCactusOff,
	IconCake,
	IconCakeOff,
	IconCalculator,
	IconCalculatorOff,
	IconCalendar,
	IconCalendarDue,
	IconCalendarEvent,
	IconCalendarMinus,
	IconCalendarOff,
	IconCalendarPlus,
	IconCalendarStats,
	IconCalendarTime,
	IconCamera,
	IconCameraMinus,
	IconCameraOff,
	IconCameraPlus,
	IconCameraRotate,
	IconCameraSelfie,
	IconCampfire,
	IconCandle,
	IconCandy,
	IconCandyOff,
	IconCane,
	IconCannabis,
	IconCapture,
	IconCaptureOff,
	IconCar,
	IconCaravan,
	IconCarCrane,
	IconCarCrash,
	IconCardboards,
	IconCardboardsOff,
	IconCards,
	IconCaretDown,
	IconCaretLeft,
	IconCaretRight,
	IconCaretUp,
	IconCarOff,
	IconCarouselHorizontal,
	IconCarouselVertical,
	IconCarrot,
	IconCarrotOff,
	IconCarTurbine,
	IconCash,
	IconCashBanknote,
	IconCashBanknoteOff,
	IconCashOff,
	IconCast,
	IconCastOff,
	IconCat,
	IconCategory,
	IconCategory2,
	IconCe,
	IconCell,
	IconCellSignal1,
	IconCellSignal2,
	IconCellSignal3,
	IconCellSignal4,
	IconCellSignal5,
	IconCellSignalOff,
	IconCeOff,
	IconCertificate,
	IconCertificate2,
	IconCertificate2Off,
	IconCertificateOff,
	IconChairDirector,
	IconChalkboard,
	IconChalkboardOff,
	IconChargingPile,
	IconChartArcs,
	IconChartArcs3,
	IconChartArea,
	IconChartAreaFilled,
	IconChartAreaLine,
	IconChartAreaLineFilled,
	IconChartArrows,
	IconChartArrowsVertical,
	IconChartBar,
	IconChartBarOff,
	IconChartBubble,
	IconChartBubbleFilled,
	IconChartCandle,
	IconChartCandleFilled,
	IconChartCircles,
	IconChartDonut,
	IconChartDonut2,
	IconChartDonut3,
	IconChartDonut4,
	IconChartDonutFilled,
	IconChartDots,
	IconChartDots2,
	IconChartDots3,
	IconChartGridDots,
	IconChartHistogram,
	IconChartInfographic,
	IconChartLine,
	IconChartPie,
	IconChartPie2,
	IconChartPie3,
	IconChartPie4,
	IconChartPieFilled,
	IconChartPieOff,
	IconChartPpf,
	IconChartRadar,
	IconChartSankey,
	IconChartTreemap,
	IconCheck,
	IconCheckbox,
	IconChecklist,
	IconChecks,
	IconCheckupList,
	IconCheese,
	IconChefHat,
	IconChefHatOff,
	IconCherry,
	IconCherryFilled,
	IconChess,
	IconChessBishop,
	IconChessBishopFilled,
	IconChessFilled,
	IconChessKing,
	IconChessKingFilled,
	IconChessKnight,
	IconChessKnightFilled,
	IconChessQueen,
	IconChessQueenFilled,
	IconChessRook,
	IconChessRookFilled,
	IconChevronDown,
	IconChevronDownLeft,
	IconChevronDownRight,
	IconChevronLeft,
	IconChevronRight,
	IconChevronsDown,
	IconChevronsDownLeft,
	IconChevronsDownRight,
	IconChevronsLeft,
	IconChevronsRight,
	IconChevronsUp,
	IconChevronsUpLeft,
	IconChevronsUpRight,
	IconChevronUp,
	IconChevronUpLeft,
	IconChevronUpRight,
	IconChisel,
	IconChristmasTree,
	IconChristmasTreeOff,
	IconCircle,
	IconCircle0Filled,
	IconCircle1Filled,
	IconCircle2Filled,
	IconCircle3Filled,
	IconCircle4Filled,
	IconCircle5Filled,
	IconCircle6Filled,
	IconCircle7Filled,
	IconCircle8Filled,
	IconCircle9Filled,
	IconCircleArrowDown,
	IconCircleArrowDownFilled,
	IconCircleArrowDownLeft,
	IconCircleArrowDownLeftFilled,
	IconCircleArrowDownRight,
	IconCircleArrowDownRightFilled,
	IconCircleArrowLeft,
	IconCircleArrowLeftFilled,
	IconCircleArrowRight,
	IconCircleArrowRightFilled,
	IconCircleArrowUp,
	IconCircleArrowUpFilled,
	IconCircleArrowUpLeft,
	IconCircleArrowUpLeftFilled,
	IconCircleArrowUpRight,
	IconCircleArrowUpRightFilled,
	IconCircleCaretDown,
	IconCircleCaretLeft,
	IconCircleCaretRight,
	IconCircleCaretUp,
	IconCircleCheck,
	IconCircleCheckFilled,
	IconCircleChevronDown,
	IconCircleChevronLeft,
	IconCircleChevronRight,
	IconCircleChevronsDown,
	IconCircleChevronsLeft,
	IconCircleChevronsRight,
	IconCircleChevronsUp,
	IconCircleChevronUp,
	IconCircleDashed,
	IconCircleDot,
	IconCircleDotFilled,
	IconCircleDotted,
	IconCircleFilled,
	IconCircleHalf,
	IconCircleHalf2,
	IconCircleHalfVertical,
	IconCircleKey,
	IconCircleKeyFilled,
	IconCircleLetterA,
	IconCircleLetterB,
	IconCircleLetterC,
	IconCircleLetterD,
	IconCircleLetterE,
	IconCircleLetterF,
	IconCircleLetterG,
	IconCircleLetterH,
	IconCircleLetterI,
	IconCircleLetterJ,
	IconCircleLetterK,
	IconCircleLetterL,
	IconCircleLetterM,
	IconCircleLetterN,
	IconCircleLetterO,
	IconCircleLetterP,
	IconCircleLetterQ,
	IconCircleLetterR,
	IconCircleLetterS,
	IconCircleLetterT,
	IconCircleLetterU,
	IconCircleLetterV,
	IconCircleLetterW,
	IconCircleLetterX,
	IconCircleLetterY,
	IconCircleLetterZ,
	IconCircleNumber0,
	IconCircleNumber1,
	IconCircleNumber2,
	IconCircleNumber3,
	IconCircleNumber4,
	IconCircleNumber5,
	IconCircleNumber6,
	IconCircleNumber7,
	IconCircleNumber8,
	IconCircleNumber9,
	IconCircleOff,
	IconCircleRectangle,
	IconCircleRectangleOff,
	IconCircles,
	IconCirclesFilled,
	IconCircleSquare,
	IconCirclesRelation,
	IconCircleTriangle,
	IconCircleX,
	IconCircleXFilled,
	IconCircuitAmmeter,
	IconCircuitBattery,
	IconCircuitBulb,
	IconCircuitCapacitor,
	IconCircuitCapacitorPolarized,
	IconCircuitCell,
	IconCircuitCellPlus,
	IconCircuitChangeover,
	IconCircuitDiode,
	IconCircuitDiodeZener,
	IconCircuitGround,
	IconCircuitGroundDigital,
	IconCircuitInductor,
	IconCircuitMotor,
	IconCircuitPushbutton,
	IconCircuitResistor,
	IconCircuitSwitchClosed,
	IconCircuitSwitchOpen,
	IconCircuitVoltmeter,
	IconClearAll,
	IconClearFormatting,
	IconClick,
	IconClipboard,
	IconClipboardCheck,
	IconClipboardCopy,
	IconClipboardData,
	IconClipboardHeart,
	IconClipboardList,
	IconClipboardOff,
	IconClipboardPlus,
	IconClipboardText,
	IconClipboardTypography,
	IconClipboardX,
	IconClock,
	IconClock2,
	IconClockCancel,
	IconClockEdit,
	IconClockFilled,
	IconClockHour1,
	IconClockHour2,
	IconClockHour3,
	IconClockHour4,
	IconClockHour5,
	IconClockHour6,
	IconClockHour7,
	IconClockHour8,
	IconClockHour9,
	IconClockHour10,
	IconClockHour11,
	IconClockHour12,
	IconClockOff,
	IconClockPause,
	IconClockPlay,
	IconClockRecord,
	IconClockStop,
	IconClothesRack,
	IconClothesRackOff,
	IconCloud,
	IconCloudComputing,
	IconCloudDataConnection,
	IconCloudDownload,
	IconCloudFilled,
	IconCloudFog,
	IconCloudLock,
	IconCloudLockOpen,
	IconCloudOff,
	IconCloudRain,
	IconCloudSnow,
	IconCloudStorm,
	IconCloudUpload,
	IconClover,
	IconClover2,
	IconClubs,
	IconClubsFilled,
	IconCode,
	IconCodeAsterix,
	IconCodeCircle,
	IconCodeCircle2,
	IconCodeDots,
	IconCodeMinus,
	IconCodeOff,
	IconCodePlus,
	IconCoffee,
	IconCoffeeOff,
	IconCoffin,
	IconCoin,
	IconCoinBitcoin,
	IconCoinEuro,
	IconCoinMonero,
	IconCoinOff,
	IconCoinPound,
	IconCoinRupee,
	IconCoins,
	IconCoinYen,
	IconCoinYuan,
	IconColorFilter,
	IconColorPicker,
	IconColorPickerOff,
	IconColorSwatch,
	IconColorSwatchOff,
	IconColumnInsertLeft,
	IconColumnInsertRight,
	IconColumns,
	IconColumns1,
	IconColumns2,
	IconColumns3,
	IconColumnsOff,
	IconComet,
	IconCommand,
	IconCommandOff,
	IconCompass,
	IconCompassOff,
	IconComponents,
	IconComponentsOff,
	IconCone,
	IconCone2,
	IconConeOff,
	IconConfetti,
	IconConfettiOff,
	IconConfucius,
	IconContainer,
	IconContainerOff,
	IconContrast,
	IconContrast2,
	IconContrast2Off,
	IconContrastOff,
	IconCooker,
	IconCookie,
	IconCookieMan,
	IconCookieOff,
	IconCopy,
	IconCopyleft,
	IconCopyleftFilled,
	IconCopyleftOff,
	IconCopyOff,
	IconCopyright,
	IconCopyrightFilled,
	IconCopyrightOff,
	IconCornerDownLeft,
	IconCornerDownLeftDouble,
	IconCornerDownRight,
	IconCornerDownRightDouble,
	IconCornerLeftDown,
	IconCornerLeftDownDouble,
	IconCornerLeftUp,
	IconCornerLeftUpDouble,
	IconCornerRightDown,
	IconCornerRightDownDouble,
	IconCornerRightUp,
	IconCornerRightUpDouble,
	IconCornerUpLeft,
	IconCornerUpLeftDouble,
	IconCornerUpRight,
	IconCornerUpRightDouble,
	IconCpu,
	IconCpu2,
	IconCpuOff,
	IconCrane,
	IconCraneOff,
	IconCreativeCommons,
	IconCreativeCommonsBy,
	IconCreativeCommonsNc,
	IconCreativeCommonsNd,
	IconCreativeCommonsOff,
	IconCreativeCommonsSa,
	IconCreativeCommonsZero,
	IconCreditCard,
	IconCreditCardOff,
	IconCricket,
	IconCrop,
	IconCross,
	IconCrossFilled,
	IconCrosshair,
	IconCrossOff,
	IconCrown,
	IconCrownOff,
	IconCrutches,
	IconCrutchesOff,
	IconCrystalBall,
	IconCsv,
	IconCubeSend,
	IconCubeUnfolded,
	IconCup,
	IconCupOff,
	IconCurling,
	IconCurlyLoop,
	IconCurrency,
	IconCurrencyAfghani,
	IconCurrencyBahraini,
	IconCurrencyBaht,
	IconCurrencyBitcoin,
	IconCurrencyCent,
	IconCurrencyDinar,
	IconCurrencyDirham,
	IconCurrencyDogecoin,
	IconCurrencyDollar,
	IconCurrencyDollarAustralian,
	IconCurrencyDollarBrunei,
	IconCurrencyDollarCanadian,
	IconCurrencyDollarGuyanese,
	IconCurrencyDollarOff,
	IconCurrencyDollarSingapore,
	IconCurrencyDollarZimbabwean,
	IconCurrencyDong,
	IconCurrencyDram,
	IconCurrencyEthereum,
	IconCurrencyEuro,
	IconCurrencyEuroOff,
	IconCurrencyForint,
	IconCurrencyFrank,
	IconCurrencyGuarani,
	IconCurrencyHryvnia,
	IconCurrencyKip,
	IconCurrencyKroneCzech,
	IconCurrencyKroneDanish,
	IconCurrencyKroneSwedish,
	IconCurrencyLari,
	IconCurrencyLeu,
	IconCurrencyLira,
	IconCurrencyLitecoin,
	IconCurrencyLyd,
	IconCurrencyManat,
	IconCurrencyMonero,
	IconCurrencyNaira,
	IconCurrencyNano,
	IconCurrencyOff,
	IconCurrencyPaanga,
	IconCurrencyPeso,
	IconCurrencyPound,
	IconCurrencyPoundOff,
	IconCurrencyQuetzal,
	IconCurrencyReal,
	IconCurrencyRenminbi,
	IconCurrencyRipple,
	IconCurrencyRiyal,
	IconCurrencyRubel,
	IconCurrencyRufiyaa,
	IconCurrencyRupee,
	IconCurrencyRupeeNepalese,
	IconCurrencyShekel,
	IconCurrencySolana,
	IconCurrencySom,
	IconCurrencyTaka,
	IconCurrencyTenge,
	IconCurrencyTugrik,
	IconCurrencyWon,
	IconCurrencyYen,
	IconCurrencyYenOff,
	IconCurrencyYuan,
	IconCurrencyZloty,
	IconCurrentLocation,
	IconCurrentLocationOff,
	IconCursorOff,
	IconCursorText,
	IconCut,
	IconCylinder,
	IconDashboard,
	IconDashboardOff,
	IconDatabase,
	IconDatabaseExport,
	IconDatabaseImport,
	IconDatabaseOff,
	IconDeer,
	IconDelta,
	IconDental,
	IconDentalBroken,
	IconDentalOff,
	IconDetails,
	IconDetailsOff,
	IconDeviceAirpods,
	IconDeviceAirpodsCase,
	IconDeviceAnalytics,
	IconDeviceAudioTape,
	IconDeviceCameraPhone,
	IconDeviceCctv,
	IconDeviceCctvOff,
	IconDeviceComputerCamera,
	IconDeviceComputerCameraOff,
	IconDeviceDesktop,
	IconDeviceDesktopAnalytics,
	IconDeviceDesktopOff,
	IconDeviceFloppy,
	IconDeviceGamepad,
	IconDeviceGamepad2,
	IconDeviceHeartMonitor,
	IconDeviceImac,
	IconDeviceIpad,
	IconDeviceIpadHorizontal,
	IconDeviceLandlinePhone,
	IconDeviceLaptop,
	IconDeviceLaptopOff,
	IconDeviceMobile,
	IconDeviceMobileCharging,
	IconDeviceMobileMessage,
	IconDeviceMobileOff,
	IconDeviceMobileRotated,
	IconDeviceMobileVibration,
	IconDeviceNintendo,
	IconDeviceNintendoOff,
	IconDeviceRemote,
	IconDevices,
	IconDevices2,
	IconDeviceSdCard,
	IconDeviceSim,
	IconDeviceSim1,
	IconDeviceSim2,
	IconDeviceSim3,
	IconDevicesOff,
	IconDevicesPc,
	IconDevicesPcOff,
	IconDeviceSpeaker,
	IconDeviceSpeakerOff,
	IconDeviceTablet,
	IconDeviceTabletOff,
	IconDeviceTv,
	IconDeviceTvOff,
	IconDeviceTvOld,
	IconDeviceWatch,
	IconDeviceWatchOff,
	IconDeviceWatchStats,
	IconDeviceWatchStats2,
	IconDialpad,
	IconDialpadOff,
	IconDiamond,
	IconDiamondFilled,
	IconDiamondOff,
	IconDiamonds,
	IconDiamondsFilled,
	IconDice,
	IconDice1,
	IconDice1Filled,
	IconDice2,
	IconDice2Filled,
	IconDice3,
	IconDice3Filled,
	IconDice4,
	IconDice4Filled,
	IconDice5,
	IconDice5Filled,
	IconDice6,
	IconDice6Filled,
	IconDiceFilled,
	IconDimensions,
	IconDirection,
	IconDirectionHorizontal,
	IconDirections,
	IconDirectionSign,
	IconDirectionSignFilled,
	IconDirectionSignOff,
	IconDirectionsOff,
	IconDisabled,
	IconDisabled2,
	IconDisabledOff,
	IconDisc,
	IconDiscGolf,
	IconDiscOff,
	IconDiscount,
	IconDiscount2,
	IconDiscount2Off,
	IconDiscountCheck,
	IconDiscountCheckFilled,
	IconDiscountOff,
	IconDivide,
	IconDna,
	IconDna2,
	IconDna2Off,
	IconDnaOff,
	IconDog,
	IconDogBowl,
	IconDoor,
	IconDoorEnter,
	IconDoorExit,
	IconDoorOff,
	IconDots,
	IconDotsCircleHorizontal,
	IconDotsDiagonal,
	IconDotsDiagonal2,
	IconDotsVertical,
	IconDownload,
	IconDownloadOff,
	IconDragDrop,
	IconDragDrop2,
	IconDrone,
	IconDroneOff,
	IconDropCircle,
	IconDroplet,
	IconDropletFilled,
	IconDropletHalf,
	IconDropletHalf2,
	IconDropletHalfFilled,
	IconDropletOff,
	IconEar,
	IconEarOff,
	IconEaseIn,
	IconEaseInControlPoint,
	IconEaseInOut,
	IconEaseInOutControlPoints,
	IconEaseOut,
	IconEaseOutControlPoint,
	IconEdit,
	IconEditCircle,
	IconEditCircleOff,
	IconEditOff,
	IconEgg,
	IconEggCracked,
	IconEggFilled,
	IconEggFried,
	IconEggOff,
	IconEggs,
	IconElevator,
	IconElevatorOff,
	IconEmergencyBed,
	IconEmpathize,
	IconEmpathizeOff,
	IconEmphasis,
	IconEngine,
	IconEngineOff,
	IconEPassport,
	IconEqual,
	IconEqualDouble,
	IconEqualNot,
	IconEraser,
	IconEraserOff,
	IconError404,
	IconError404Off,
	IconExchange,
	IconExchangeOff,
	IconExclamationCircle,
	IconExclamationMark,
	IconExclamationMarkOff,
	IconExplicit,
	IconExplicitOff,
	IconExposure,
	IconExposure0,
	IconExposureMinus1,
	IconExposureMinus2,
	IconExposureOff,
	IconExposurePlus1,
	IconExposurePlus2,
	IconExternalLink,
	IconExternalLinkOff,
	IconEye,
	IconEyeCheck,
	IconEyeFilled,
	IconEyeglass,
	IconEyeglass2,
	IconEyeglassOff,
	IconEyeOff,
	IconEyeTable,
	IconFaceId,
	IconFaceIdError,
	IconFaceMask,
	IconFaceMaskOff,
	IconFall,
	IconFeather,
	IconFeatherOff,
	IconFence,
	IconFenceOff,
	IconFidgetSpinner,
	IconFile,
	IconFile3d,
	IconFileAlert,
	IconFileAnalytics,
	IconFileArrowLeft,
	IconFileArrowRight,
	IconFileBarcode,
	IconFileBroken,
	IconFileCertificate,
	IconFileChart,
	IconFileCheck,
	IconFileCode,
	IconFileCode2,
	IconFileDatabase,
	IconFileDelta,
	IconFileDescription,
	IconFileDiff,
	IconFileDigit,
	IconFileDislike,
	IconFileDollar,
	IconFileDots,
	IconFileDownload,
	IconFileEuro,
	IconFileExport,
	IconFileFilled,
	IconFileFunction,
	IconFileHorizontal,
	IconFileImport,
	IconFileInfinity,
	IconFileInfo,
	IconFileInvoice,
	IconFileLambda,
	IconFileLike,
	IconFileMinus,
	IconFileMusic,
	IconFileOff,
	IconFileOrientation,
	IconFilePencil,
	IconFilePercent,
	IconFilePhone,
	IconFilePlus,
	IconFilePower,
	IconFileReport,
	IconFileRss,
	IconFiles,
	IconFileScissors,
	IconFileSearch,
	IconFileSettings,
	IconFileShredder,
	IconFileSignal,
	IconFilesOff,
	IconFileSpreadsheet,
	IconFileStack,
	IconFileStar,
	IconFileSymlink,
	IconFileText,
	IconFileTime,
	IconFileTypography,
	IconFileUnknown,
	IconFileUpload,
	IconFileVector,
	IconFileX,
	IconFileXFilled,
	IconFileZip,
	IconFilter,
	IconFilterOff,
	IconFilters,
	IconFingerprint,
	IconFingerprintOff,
	IconFireHydrant,
	IconFireHydrantOff,
	IconFiretruck,
	IconFirstAidKit,
	IconFirstAidKitOff,
	IconFish,
	IconFishBone,
	IconFishChristianity,
	IconFishHook,
	IconFishHookOff,
	IconFishOff,
	IconFlag,
	IconFlag2,
	IconFlag2Filled,
	IconFlag2Off,
	IconFlag3,
	IconFlag3Filled,
	IconFlagFilled,
	IconFlagOff,
	IconFlame,
	IconFlameOff,
	IconFlare,
	IconFlask,
	IconFlask2,
	IconFlask2Off,
	IconFlaskOff,
	IconFlipFlops,
	IconFlipHorizontal,
	IconFlipVertical,
	IconFloatCenter,
	IconFloatLeft,
	IconFloatNone,
	IconFloatRight,
	IconFlower,
	IconFlowerOff,
	IconFocus,
	IconFocus2,
	IconFocusCentered,
	IconFold,
	IconFoldDown,
	IconFolder,
	IconFolderFilled,
	IconFolderMinus,
	IconFolderOff,
	IconFolderPlus,
	IconFolders,
	IconFoldersOff,
	IconFolderX,
	IconFoldUp,
	IconForbid,
	IconForbid2,
	IconForklift,
	IconForms,
	IconFountain,
	IconFountainOff,
	IconFrame,
	IconFrameOff,
	IconFreeRights,
	IconFridge,
	IconFridgeOff,
	IconFriends,
	IconFriendsOff,
	IconFunction,
	IconFunctionOff,
	IconGardenCart,
	IconGardenCartOff,
	IconGasStation,
	IconGasStationOff,
	IconGauge,
	IconGaugeOff,
	IconGavel,
	IconGenderAgender,
	IconGenderAndrogyne,
	IconGenderBigender,
	IconGenderDemiboy,
	IconGenderDemigirl,
	IconGenderEpicene,
	IconGenderFemale,
	IconGenderFemme,
	IconGenderGenderfluid,
	IconGenderGenderless,
	IconGenderGenderqueer,
	IconGenderHermaphrodite,
	IconGenderIntergender,
	IconGenderMale,
	IconGenderNeutrois,
	IconGenderThird,
	IconGenderTransgender,
	IconGenderTrasvesti,
	IconGeometry,
	IconGhost,
	IconGhost2,
	IconGhost2Filled,
	IconGhostFilled,
	IconGhostOff,
	IconGif,
	IconGift,
	IconGiftCard,
	IconGiftOff,
	IconGitBranch,
	IconGitBranchDeleted,
	IconGitCherryPick,
	IconGitCommit,
	IconGitCompare,
	IconGitFork,
	IconGitMerge,
	IconGitPullRequest,
	IconGitPullRequestClosed,
	IconGitPullRequestDraft,
	IconGizmo,
	IconGlass,
	IconGlassFull,
	IconGlassOff,
	IconGlobe,
	IconGlobeOff,
	IconGoGame,
	IconGolf,
	IconGolfOff,
	IconGps,
	IconGradienter,
	IconGrain,
	IconGraph,
	IconGraphOff,
	IconGrave,
	IconGrave2,
	IconGridDots,
	IconGridPattern,
	IconGrill,
	IconGrillFork,
	IconGrillOff,
	IconGrillSpatula,
	IconGripHorizontal,
	IconGripVertical,
	IconGrowth,
	IconGuitarPick,
	IconGuitarPickFilled,
	IconH1,
	IconH2,
	IconH3,
	IconH4,
	IconH5,
	IconH6,
	IconHammer,
	IconHammerOff,
	IconHandClick,
	IconHandFinger,
	IconHandFingerOff,
	IconHandGrab,
	IconHandLittleFinger,
	IconHandMiddleFinger,
	IconHandMove,
	IconHandOff,
	IconHandRingFinger,
	IconHandRock,
	IconHandSanitizer,
	IconHandStop,
	IconHandThreeFingers,
	IconHandTwoFingers,
	IconHanger,
	IconHanger2,
	IconHangerOff,
	IconHash,
	IconHaze,
	IconHeading,
	IconHeadingOff,
	IconHeadphones,
	IconHeadphonesOff,
	IconHeadset,
	IconHeadsetOff,
	IconHealthRecognition,
	IconHeart,
	IconHeartbeat,
	IconHeartBroken,
	IconHeartFilled,
	IconHeartHandshake,
	IconHeartMinus,
	IconHeartOff,
	IconHeartPlus,
	IconHeartRateMonitor,
	IconHearts,
	IconHeartsOff,
	IconHelicopter,
	IconHelicopterLanding,
	IconHelmet,
	IconHelmetOff,
	IconHelp,
	IconHelpHexagon,
	IconHelpOctagon,
	IconHelpOff,
	IconHexagon,
	IconHexagon0Filled,
	IconHexagon1Filled,
	IconHexagon2Filled,
	IconHexagon3d,
	IconHexagon3Filled,
	IconHexagon4Filled,
	IconHexagon5Filled,
	IconHexagon6Filled,
	IconHexagon7Filled,
	IconHexagon8Filled,
	IconHexagon9Filled,
	IconHexagonFilled,
	IconHexagonLetterA,
	IconHexagonLetterB,
	IconHexagonLetterC,
	IconHexagonLetterD,
	IconHexagonLetterE,
	IconHexagonLetterF,
	IconHexagonLetterG,
	IconHexagonLetterH,
	IconHexagonLetterI,
	IconHexagonLetterJ,
	IconHexagonLetterK,
	IconHexagonLetterL,
	IconHexagonLetterM,
	IconHexagonLetterN,
	IconHexagonLetterO,
	IconHexagonLetterP,
	IconHexagonLetterQ,
	IconHexagonLetterR,
	IconHexagonLetterS,
	IconHexagonLetterT,
	IconHexagonLetterU,
	IconHexagonLetterV,
	IconHexagonLetterW,
	IconHexagonLetterX,
	IconHexagonLetterY,
	IconHexagonLetterZ,
	IconHexagonNumber0,
	IconHexagonNumber1,
	IconHexagonNumber2,
	IconHexagonNumber3,
	IconHexagonNumber4,
	IconHexagonNumber5,
	IconHexagonNumber6,
	IconHexagonNumber7,
	IconHexagonNumber8,
	IconHexagonNumber9,
	IconHexagonOff,
	IconHexagons,
	IconHexagonsOff,
	IconHierarchy,
	IconHierarchy2,
	IconHierarchy3,
	IconHierarchyOff,
	IconHighlight,
	IconHighlightOff,
	IconHistory,
	IconHistoryOff,
	IconHistoryToggle,
	IconHome,
	IconHome2,
	IconHomeBolt,
	IconHomeCancel,
	IconHomeCheck,
	IconHomeCog,
	IconHomeDollar,
	IconHomeDot,
	IconHomeDown,
	IconHomeEco,
	IconHomeEdit,
	IconHomeExclamation,
	IconHomeHand,
	IconHomeHeart,
	IconHomeInfinity,
	IconHomeLink,
	IconHomeMinus,
	IconHomeMove,
	IconHomeOff,
	IconHomePlus,
	IconHomeQuestion,
	IconHomeRibbon,
	IconHomeSearch,
	IconHomeShare,
	IconHomeShield,
	IconHomeSignal,
	IconHomeStar,
	IconHomeStats,
	IconHomeUp,
	IconHomeX,
	IconHorseToy,
	IconHotelService,
	IconHourglass,
	IconHourglassEmpty,
	IconHourglassFilled,
	IconHourglassHigh,
	IconHourglassLow,
	IconHourglassOff,
	IconIceCream,
	IconIceCream2,
	IconIceCreamOff,
	IconIceSkating,
	IconIcons,
	IconIconsOff,
	IconId,
	IconIdBadge,
	IconIdBadge2,
	IconIdBadgeOff,
	IconIdOff,
	IconInbox,
	IconInboxOff,
	IconIndentDecrease,
	IconIndentIncrease,
	IconInfinity,
	IconInfinityOff,
	IconInfoCircle,
	IconInfoCircleFilled,
	IconInfoHexagon,
	IconInfoOctagon,
	IconInfoSquare,
	IconInfoSquareRounded,
	IconInfoSquareRoundedFilled,
	IconInnerShadowBottom,
	IconInnerShadowBottomFilled,
	IconInnerShadowBottomLeft,
	IconInnerShadowBottomLeftFilled,
	IconInnerShadowBottomRight,
	IconInnerShadowBottomRightFilled,
	IconInnerShadowLeft,
	IconInnerShadowLeftFilled,
	IconInnerShadowRight,
	IconInnerShadowRightFilled,
	IconInnerShadowTop,
	IconInnerShadowTopFilled,
	IconInnerShadowTopLeft,
	IconInnerShadowTopLeftFilled,
	IconInnerShadowTopRight,
	IconInnerShadowTopRightFilled,
	IconInputSearch,
	IconIroning1,
	IconIroning2,
	IconIroning3,
	IconIroningOff,
	IconIroningSteam,
	IconIroningSteamOff,
	IconItalic,
	IconJacket,
	IconJetpack,
	IconJewishStar,
	IconJewishStarFilled,
	IconJpg,
	IconJumpRope,
	IconKarate,
	IconKayak,
	IconKering,
	IconKey,
	IconKeyboard,
	IconKeyboardHide,
	IconKeyboardOff,
	IconKeyboardShow,
	IconKeyframe,
	IconKeyframeAlignCenter,
	IconKeyframeAlignHorizontal,
	IconKeyframeAlignVertical,
	IconKeyframes,
	IconKeyOff,
	IconLadder,
	IconLadderOff,
	IconLambda,
	IconLamp,
	IconLamp2,
	IconLampOff,
	IconLanguage,
	IconLanguageHiragana,
	IconLanguageKatakana,
	IconLanguageOff,
	IconLasso,
	IconLassoOff,
	IconLassoPolygon,
	IconLayersDifference,
	IconLayersIntersect,
	IconLayersIntersect2,
	IconLayersLinked,
	IconLayersOff,
	IconLayersSubtract,
	IconLayersUnion,
	IconLayout,
	IconLayout2,
	IconLayoutAlignBottom,
	IconLayoutAlignCenter,
	IconLayoutAlignLeft,
	IconLayoutAlignMiddle,
	IconLayoutAlignRight,
	IconLayoutAlignTop,
	IconLayoutBoard,
	IconLayoutBoardSplit,
	IconLayoutBottombar,
	IconLayoutBottombarCollapse,
	IconLayoutBottombarExpand,
	IconLayoutCards,
	IconLayoutCollage,
	IconLayoutColumns,
	IconLayoutDashboard,
	IconLayoutDistributeHorizontal,
	IconLayoutDistributeVertical,
	IconLayoutGrid,
	IconLayoutGridAdd,
	IconLayoutKanban,
	IconLayoutList,
	IconLayoutNavbar,
	IconLayoutNavbarCollapse,
	IconLayoutNavbarExpand,
	IconLayoutOff,
	IconLayoutRows,
	IconLayoutSidebar,
	IconLayoutSidebarLeftCollapse,
	IconLayoutSidebarLeftExpand,
	IconLayoutSidebarRight,
	IconLayoutSidebarRightCollapse,
	IconLayoutSidebarRightExpand,
	IconLeaf,
	IconLeafOff,
	IconLego,
	IconLegoOff,
	IconLemon,
	IconLemon2,
	IconLetterA,
	IconLetterB,
	IconLetterC,
	IconLetterCase,
	IconLetterCaseLower,
	IconLetterCaseToggle,
	IconLetterCaseUpper,
	IconLetterD,
	IconLetterE,
	IconLetterF,
	IconLetterG,
	IconLetterH,
	IconLetterI,
	IconLetterJ,
	IconLetterK,
	IconLetterL,
	IconLetterM,
	IconLetterN,
	IconLetterO,
	IconLetterP,
	IconLetterQ,
	IconLetterR,
	IconLetterS,
	IconLetterSpacing,
	IconLetterT,
	IconLetterU,
	IconLetterV,
	IconLetterW,
	IconLetterX,
	IconLetterY,
	IconLetterZ,
	IconLicense,
	IconLicenseOff,
	IconLifebuoy,
	IconLifebuoyOff,
	IconLighter,
	IconLine,
	IconLineDashed,
	IconLineDotted,
	IconLineHeight,
	IconLink,
	IconLinkOff,
	IconList,
	IconListCheck,
	IconListDetails,
	IconListNumbers,
	IconListSearch,
	IconLivePhoto,
	IconLivePhotoOff,
	IconLiveView,
	IconLoader,
	IconLoader2,
	IconLoader3,
	IconLoaderQuarter,
	IconLocation,
	IconLocationBroken,
	IconLocationFilled,
	IconLocationOff,
	IconLock,
	IconLockAccess,
	IconLockAccessOff,
	IconLockOff,
	IconLockOpen,
	IconLockOpenOff,
	IconLockSquare,
	IconLockSquareRounded,
	IconLockSquareRoundedFilled,
	IconLogicAnd,
	IconLogicBuffer,
	IconLogicNand,
	IconLogicNor,
	IconLogicNot,
	IconLogicOr,
	IconLogicXnor,
	IconLogicXor,
	IconLogin,
	IconLogout,
	IconLollipop,
	IconLollipopOff,
	IconLuggage,
	IconLuggageOff,
	IconLungs,
	IconLungsOff,
	IconMacro,
	IconMacroOff,
	IconMagnet,
	IconMagnetOff,
	IconMail,
	IconMailbox,
	IconMailboxOff,
	IconMailFast,
	IconMailForward,
	IconMailOff,
	IconMailOpened,
	IconMan,
	IconManualGearbox,
	IconMap,
	IconMap2,
	IconMapOff,
	IconMapPin,
	IconMapPinFilled,
	IconMapPinOff,
	IconMapPins,
	IconMapPinShare,
	IconMapSearch,
	IconMarkdown,
	IconMarkdownOff,
	IconMarquee,
	IconMarquee2,
	IconMarqueeOff,
	IconMars,
	IconMask,
	IconMaskOff,
	IconMasksTheater,
	IconMasksTheaterOff,
	IconMassage,
	IconMatchstick,
	IconMath,
	IconMath1Divide2,
	IconMath1Divide3,
	IconMathAvg,
	IconMathEqualGreater,
	IconMathEqualLower,
	IconMathFunction,
	IconMathFunctionOff,
	IconMathFunctionY,
	IconMathGreater,
	IconMathIntegral,
	IconMathIntegrals,
	IconMathIntegralX,
	IconMathLower,
	IconMathMax,
	IconMathMin,
	IconMathNot,
	IconMathOff,
	IconMathPi,
	IconMathPiDivide2,
	IconMathSymbols,
	IconMathXDivide2,
	IconMathXDivideY,
	IconMathXDivideY2,
	IconMathXMinusX,
	IconMathXMinusY,
	IconMathXPlusX,
	IconMathXPlusY,
	IconMathXy,
	IconMathYMinusY,
	IconMathYPlusY,
	IconMaximize,
	IconMaximizeOff,
	IconMeat,
	IconMeatOff,
	IconMedal,
	IconMedal2,
	IconMedicalCross,
	IconMedicalCrossFilled,
	IconMedicalCrossOff,
	IconMedicineSyrup,
	IconMeeple,
	IconMenorah,
	IconMenu,
	IconMenu2,
	IconMenuOrder,
	IconMessage,
	IconMessage2,
	IconMessage2Code,
	IconMessage2Off,
	IconMessage2Share,
	IconMessageChatbot,
	IconMessageCircle,
	IconMessageCircle2,
	IconMessageCircle2Filled,
	IconMessageCircleOff,
	IconMessageCode,
	IconMessageDots,
	IconMessageForward,
	IconMessageLanguage,
	IconMessageOff,
	IconMessagePlus,
	IconMessageReport,
	IconMessages,
	IconMessageShare,
	IconMessagesOff,
	IconMeteor,
	IconMeteorOff,
	IconMickey,
	IconMickeyFilled,
	IconMicrophone,
	IconMicrophone2,
	IconMicrophone2Off,
	IconMicrophoneOff,
	IconMicroscope,
	IconMicroscopeOff,
	IconMicrowave,
	IconMicrowaveOff,
	IconMilitaryAward,
	IconMilitaryRank,
	IconMilk,
	IconMilkOff,
	IconMilkshake,
	IconMinimize,
	IconMinus,
	IconMinusVertical,
	IconMist,
	IconMistOff,
	IconMoneybag,
	IconMoodAngry,
	IconMoodAnnoyed,
	IconMoodAnnoyed2,
	IconMoodBoy,
	IconMoodConfuzed,
	IconMoodCrazyHappy,
	IconMoodCry,
	IconMoodEmpty,
	IconMoodHappy,
	IconMoodKid,
	IconMoodLookLeft,
	IconMoodLookRight,
	IconMoodNerd,
	IconMoodNervous,
	IconMoodNeutral,
	IconMoodOff,
	IconMoodSad,
	IconMoodSad2,
	IconMoodSadDizzy,
	IconMoodSadSquint,
	IconMoodSick,
	IconMoodSilence,
	IconMoodSing,
	IconMoodSmile,
	IconMoodSmileBeam,
	IconMoodSmileDizzy,
	IconMoodSuprised,
	IconMoodTongue,
	IconMoodTongueWink,
	IconMoodTongueWink2,
	IconMoodUnamused,
	IconMoodWink,
	IconMoodWink2,
	IconMoodWrrr,
	IconMoodXd,
	IconMoon,
	IconMoon2,
	IconMoonFilled,
	IconMoonOff,
	IconMoonStars,
	IconMoped,
	IconMotorbike,
	IconMountain,
	IconMountainOff,
	IconMouse,
	IconMouse2,
	IconMouseOff,
	IconMoustache,
	IconMovie,
	IconMovieOff,
	IconMug,
	IconMugOff,
	IconMultiplier1x,
	IconMultiplier2x,
	IconMultiplier05x,
	IconMultiplier15x,
	IconMushroom,
	IconMushroomOff,
	IconMusic,
	IconMusicOff,
	IconNavigation,
	IconNavigationFilled,
	IconNavigationOff,
	IconNeedle,
	IconNeedleThread,
	IconNetwork,
	IconNetworkOff,
	IconNews,
	IconNewSection,
	IconNewsOff,
	IconNfc,
	IconNfcOff,
	IconNoCopyright,
	IconNoCreativeCommons,
	IconNoDerivatives,
	IconNorthStar,
	IconNote,
	IconNotebook,
	IconNotebookOff,
	IconNoteOff,
	IconNotes,
	IconNotesOff,
	IconNotification,
	IconNotificationOff,
	IconNumber,
	IconNumber0,
	IconNumber1,
	IconNumber2,
	IconNumber3,
	IconNumber4,
	IconNumber5,
	IconNumber6,
	IconNumber7,
	IconNumber8,
	IconNumber9,
	IconNumbers,
	IconNurse,
	IconOctagon,
	IconOctagonFilled,
	IconOctagonOff,
	IconOld,
	IconOlympics,
	IconOlympicsOff,
	IconOm,
	IconOmega,
	IconOutbound,
	IconOutlet,
	IconOval,
	IconOvalFilled,
	IconOvalVertical,
	IconOvalVerticalFilled,
	IconOverline,
	IconPackage,
	IconPackageExport,
	IconPackageImport,
	IconPackageOff,
	IconPackages,
	IconPacman,
	IconPageBreak,
	IconPaint,
	IconPaintFilled,
	IconPaintOff,
	IconPalette,
	IconPaletteOff,
	IconPanoramaHorizontal,
	IconPanoramaHorizontalOff,
	IconPanoramaVertical,
	IconPanoramaVerticalOff,
	IconPaperBag,
	IconPaperBagOff,
	IconPaperclip,
	IconParachute,
	IconParachuteOff,
	IconParentheses,
	IconParenthesesOff,
	IconParking,
	IconParkingOff,
	IconPassword,
	IconPaw,
	IconPawFilled,
	IconPawOff,
	IconPdf,
	IconPeace,
	IconPencil,
	IconPencilMinus,
	IconPencilOff,
	IconPencilPlus,
	IconPennant,
	IconPennant2,
	IconPennant2Filled,
	IconPennantFilled,
	IconPennantOff,
	IconPentagon,
	IconPentagonFilled,
	IconPentagonOff,
	IconPentagram,
	IconPepper,
	IconPepperOff,
	IconPercentage,
	IconPerfume,
	IconPerspective,
	IconPerspectiveOff,
	IconPhone,
	IconPhoneCall,
	IconPhoneCalling,
	IconPhoneCheck,
	IconPhoneIncoming,
	IconPhoneOff,
	IconPhoneOutgoing,
	IconPhonePause,
	IconPhonePlus,
	IconPhoneX,
	IconPhoto,
	IconPhotoCancel,
	IconPhotoCheck,
	IconPhotoDown,
	IconPhotoEdit,
	IconPhotoHeart,
	IconPhotoMinus,
	IconPhotoOff,
	IconPhotoPlus,
	IconPhotoSearch,
	IconPhotoSensor,
	IconPhotoSensor2,
	IconPhotoSensor3,
	IconPhotoShield,
	IconPhotoStar,
	IconPhotoUp,
	IconPhotoX,
	IconPhysotherapist,
	IconPictureInPicture,
	IconPictureInPictureOff,
	IconPictureInPictureOn,
	IconPictureInPictureTop,
	IconPig,
	IconPigMoney,
	IconPigOff,
	IconPilcrow,
	IconPill,
	IconPillOff,
	IconPills,
	IconPin,
	IconPinFilled,
	IconPingPong,
	IconPinned,
	IconPinnedFilled,
	IconPinnedOff,
	IconPizza,
	IconPizzaOff,
	IconPlaceholder,
	IconPlane,
	IconPlaneArrival,
	IconPlaneDeparture,
	IconPlaneInflight,
	IconPlaneOff,
	IconPlanet,
	IconPlaneTilt,
	IconPlanetOff,
	IconPlant,
	IconPlant2,
	IconPlant2Off,
	IconPlantOff,
	IconPlayCard,
	IconPlayCardOff,
	IconPlayerEject,
	IconPlayerEjectFilled,
	IconPlayerPause,
	IconPlayerPauseFilled,
	IconPlayerPlay,
	IconPlayerPlayFilled,
	IconPlayerRecord,
	IconPlayerRecordFilled,
	IconPlayerSkipBack,
	IconPlayerSkipBackFilled,
	IconPlayerSkipForward,
	IconPlayerSkipForwardFilled,
	IconPlayerStop,
	IconPlayerStopFilled,
	IconPlayerTrackNext,
	IconPlayerTrackNextFilled,
	IconPlayerTrackPrev,
	IconPlayerTrackPrevFilled,
	IconPlaylist,
	IconPlaylistAdd,
	IconPlaylistOff,
	IconPlaylistX,
	IconPlaystationCircle,
	IconPlaystationSquare,
	IconPlaystationTriangle,
	IconPlaystationX,
	IconPlug,
	IconPlugConnected,
	IconPlugConnectedX,
	IconPlugOff,
	IconPlugX,
	IconPlus,
	IconPlusEqual,
	IconPlusMinus,
	IconPng,
	IconPodium,
	IconPodiumOff,
	IconPoint,
	IconPointer,
	IconPointFilled,
	IconPointOff,
	IconPokeball,
	IconPokeballOff,
	IconPokerChip,
	IconPolaroid,
	IconPolygon,
	IconPolygonOff,
	IconPoo,
	IconPool,
	IconPoolOff,
	IconPower,
	IconPray,
	IconPremiumRights,
	IconPrescription,
	IconPresentation,
	IconPresentationAnalytics,
	IconPresentationOff,
	IconPrinter,
	IconPrinterOff,
	IconPrison,
	IconPrompt,
	IconPropeller,
	IconPropellerOff,
	IconPumpkinScary,
	IconPuzzle,
	IconPuzzle2,
	IconPuzzleFilled,
	IconPuzzleOff,
	IconPyramid,
	IconPyramidOff,
	IconQrcode,
	IconQrcodeOff,
	IconQuestionMark,
	IconQuote,
	IconQuoteOff,
	IconRadar,
	IconRadar2,
	IconRadarOff,
	IconRadio,
	IconRadioactive,
	IconRadioactiveFilled,
	IconRadioactiveOff,
	IconRadioOff,
	IconRadiusBottomLeft,
	IconRadiusBottomRight,
	IconRadiusTopLeft,
	IconRadiusTopRight,
	IconRainbow,
	IconRainbowOff,
	IconRating12Plus,
	IconRating14Plus,
	IconRating16Plus,
	IconRating18Plus,
	IconRating21Plus,
	IconRazor,
	IconRazorElectric,
	IconReceipt,
	IconReceipt2,
	IconReceiptOff,
	IconReceiptRefund,
	IconReceiptTax,
	IconRecharging,
	IconRecordMail,
	IconRecordMailOff,
	IconRectangle,
	IconRectangleFilled,
	IconRectangleVertical,
	IconRectangleVerticalFilled,
	IconRecycle,
	IconRecycleOff,
	IconRefresh,
	IconRefreshAlert,
	IconRefreshDot,
	IconRefreshOff,
	IconRegex,
	IconRegexOff,
	IconRegistered,
	IconRelationManyToMany,
	IconRelationOneToMany,
	IconRelationOneToOne,
	IconReload,
	IconRepeat,
	IconRepeatOff,
	IconRepeatOnce,
	IconReplace,
	IconReplaceFilled,
	IconReplaceOff,
	IconReport,
	IconReportAnalytics,
	IconReportMedical,
	IconReportMoney,
	IconReportOff,
	IconReportSearch,
	IconResize,
	IconRibbonHealth,
	IconRipple,
	IconRippleOff,
	IconRoad,
	IconRoadOff,
	IconRoadSign,
	IconRobot,
	IconRobotOff,
	IconRocket,
	IconRocketOff,
	IconRollercoaster,
	IconRollercoasterOff,
	IconRollerSkating,
	IconRosette,
	IconRosetteFilled,
	IconRosetteNumber0,
	IconRosetteNumber1,
	IconRosetteNumber2,
	IconRosetteNumber3,
	IconRosetteNumber4,
	IconRosetteNumber5,
	IconRosetteNumber6,
	IconRosetteNumber7,
	IconRosetteNumber8,
	IconRosetteNumber9,
	IconRotate,
	IconRotate2,
	IconRotate360,
	IconRotateClockwise,
	IconRotateClockwise2,
	IconRotateDot,
	IconRotateRectangle,
	IconRoute,
	IconRoute2,
	IconRouteOff,
	IconRouter,
	IconRouterOff,
	IconRowInsertBottom,
	IconRowInsertTop,
	IconRss,
	IconRubberStamp,
	IconRubberStampOff,
	IconRuler,
	IconRuler2,
	IconRuler2Off,
	IconRuler3,
	IconRulerMeasure,
	IconRulerOff,
	IconRun,
	IconSailboat,
	IconSailboat2,
	IconSailboatOff,
	IconSalad,
	IconSalt,
	IconSatellite,
	IconSatelliteOff,
	IconSausage,
	IconScale,
	IconScaleOff,
	IconScaleOutline,
	IconScaleOutlineOff,
	IconScan,
	IconScanEye,
	IconSchema,
	IconSchemaOff,
	IconSchool,
	IconSchoolBell,
	IconSchoolOff,
	IconScissors,
	IconScissorsOff,
	IconScooter,
	IconScooterElectric,
	IconScreenShare,
	IconScreenShareOff,
	IconScreenshot,
	IconScribble,
	IconScribbleOff,
	IconScript,
	IconScriptMinus,
	IconScriptPlus,
	IconScriptX,
	IconScubaMask,
	IconScubaMaskOff,
	IconSdk,
	IconSearch,
	IconSearchOff,
	IconSection,
	IconSectionSign,
	IconSeeding,
	IconSeedingOff,
	IconSelect,
	IconSelector,
	IconSend,
	IconSendOff,
	IconSeo,
	IconSeparator,
	IconSeparatorHorizontal,
	IconSeparatorVertical,
	IconServer,
	IconServer2,
	IconServerBolt,
	IconServerCog,
	IconServerOff,
	IconServicemark,
	IconSettings,
	IconSettings2,
	IconSettingsAutomation,
	IconSettingsFilled,
	IconSettingsOff,
	IconShadow,
	IconShadowOff,
	IconShape,
	IconShape2,
	IconShape3,
	IconShapeOff,
	IconShare,
	IconShare2,
	IconShareOff,
	IconShield,
	IconShieldCheck,
	IconShieldCheckered,
	IconShieldCheckeredFilled,
	IconShieldCheckFilled,
	IconShieldChevron,
	IconShieldFilled,
	IconShieldHalf,
	IconShieldHalfFilled,
	IconShieldLock,
	IconShieldLockFilled,
	IconShieldOff,
	IconShieldX,
	IconShip,
	IconShipOff,
	IconShirt,
	IconShirtFilled,
	IconShirtOff,
	IconShirtSport,
	IconShoe,
	IconShoeOff,
	IconShoppingBag,
	IconShoppingCart,
	IconShoppingCartDiscount,
	IconShoppingCartOff,
	IconShoppingCartPlus,
	IconShoppingCartX,
	IconShovel,
	IconShredder,
	IconSignal2g,
	IconSignal3g,
	IconSignal4g,
	IconSignal4gPlus,
	IconSignal5g,
	IconSignature,
	IconSignatureOff,
	IconSignLeft,
	IconSignLeftFilled,
	IconSignRight,
	IconSignRightFilled,
	IconSitemap,
	IconSitemapOff,
	IconSkateboard,
	IconSkateboardOff,
	IconSkull,
	IconSlash,
	IconSlashes,
	IconSleigh,
	IconSlice,
	IconSlideshow,
	IconSmartHome,
	IconSmartHomeOff,
	IconSmoking,
	IconSmokingNo,
	IconSnowflake,
	IconSnowflakeOff,
	IconSnowman,
	IconSoccerField,
	IconSocial,
	IconSocialOff,
	IconSock,
	IconSofa,
	IconSofaOff,
	IconSort09,
	IconSort90,
	IconSortAscending,
	IconSortAscending2,
	IconSortAscendingLetters,
	IconSortAscendingNumbers,
	IconSortAZ,
	IconSortDescending,
	IconSortDescending2,
	IconSortDescendingLetters,
	IconSortDescendingNumbers,
	IconSortZA,
	IconSos,
	IconSoup,
	IconSoupOff,
	IconSourceCode,
	IconSpace,
	IconSpaceOff,
	IconSpacingHorizontal,
	IconSpacingVertical,
	IconSpade,
	IconSpadeFilled,
	IconSparkles,
	IconSpeakerphone,
	IconSpeedboat,
	IconSpider,
	IconSpiral,
	IconSpiralOff,
	IconSportBillard,
	IconSpray,
	IconSpy,
	IconSpyOff,
	IconSquare,
	IconSquare0Filled,
	IconSquare1Filled,
	IconSquare3Filled,
	IconSquare4Filled,
	IconSquare5Filled,
	IconSquare6Filled,
	IconSquare7Filled,
	IconSquare8Filled,
	IconSquare9Filled,
	IconSquareArrowDown,
	IconSquareArrowLeft,
	IconSquareArrowRight,
	IconSquareArrowUp,
	IconSquareAsterisk,
	IconSquareCheck,
	IconSquareCheckFilled,
	IconSquareChevronDown,
	IconSquareChevronLeft,
	IconSquareChevronRight,
	IconSquareChevronsDown,
	IconSquareChevronsLeft,
	IconSquareChevronsRight,
	IconSquareChevronsUp,
	IconSquareChevronUp,
	IconSquareDot,
	IconSquareF0,
	IconSquareF0Filled,
	IconSquareF1,
	IconSquareF1Filled,
	IconSquareF2,
	IconSquareF2Filled,
	IconSquareF3,
	IconSquareF3Filled,
	IconSquareF4,
	IconSquareF4Filled,
	IconSquareF5,
	IconSquareF5Filled,
	IconSquareF6,
	IconSquareF6Filled,
	IconSquareF7,
	IconSquareF7Filled,
	IconSquareF8,
	IconSquareF8Filled,
	IconSquareF9,
	IconSquareF9Filled,
	IconSquareForbid,
	IconSquareForbid2,
	IconSquareHalf,
	IconSquareKey,
	IconSquareLetterA,
	IconSquareLetterB,
	IconSquareLetterC,
	IconSquareLetterD,
	IconSquareLetterE,
	IconSquareLetterF,
	IconSquareLetterG,
	IconSquareLetterH,
	IconSquareLetterI,
	IconSquareLetterJ,
	IconSquareLetterK,
	IconSquareLetterL,
	IconSquareLetterM,
	IconSquareLetterN,
	IconSquareLetterO,
	IconSquareLetterP,
	IconSquareLetterQ,
	IconSquareLetterR,
	IconSquareLetterS,
	IconSquareLetterT,
	IconSquareLetterU,
	IconSquareLetterV,
	IconSquareLetterW,
	IconSquareLetterX,
	IconSquareLetterY,
	IconSquareLetterZ,
	IconSquareMinus,
	IconSquareNumber0,
	IconSquareNumber1,
	IconSquareNumber2,
	IconSquareNumber3,
	IconSquareNumber4,
	IconSquareNumber5,
	IconSquareNumber6,
	IconSquareNumber7,
	IconSquareNumber8,
	IconSquareNumber9,
	IconSquareOff,
	IconSquarePlus,
	IconSquareRoot,
	IconSquareRoot2,
	IconSquareRotated,
	IconSquareRotatedFilled,
	IconSquareRotatedForbid,
	IconSquareRotatedForbid2,
	IconSquareRotatedOff,
	IconSquareRounded,
	IconSquareRoundedArrowDown,
	IconSquareRoundedArrowDownFilled,
	IconSquareRoundedArrowLeft,
	IconSquareRoundedArrowLeftFilled,
	IconSquareRoundedArrowRight,
	IconSquareRoundedArrowRightFilled,
	IconSquareRoundedArrowUp,
	IconSquareRoundedArrowUpFilled,
	IconSquareRoundedCheck,
	IconSquareRoundedCheckFilled,
	IconSquareRoundedChevronDown,
	IconSquareRoundedChevronDownFilled,
	IconSquareRoundedChevronLeft,
	IconSquareRoundedChevronLeftFilled,
	IconSquareRoundedChevronRight,
	IconSquareRoundedChevronRightFilled,
	IconSquareRoundedChevronsDown,
	IconSquareRoundedChevronsDownFilled,
	IconSquareRoundedChevronsLeft,
	IconSquareRoundedChevronsLeftFilled,
	IconSquareRoundedChevronsRight,
	IconSquareRoundedChevronsRightFilled,
	IconSquareRoundedChevronsUp,
	IconSquareRoundedChevronsUpFilled,
	IconSquareRoundedChevronUp,
	IconSquareRoundedChevronUpFilled,
	IconSquareRoundedFilled,
	IconSquareRoundedLetterA,
	IconSquareRoundedLetterB,
	IconSquareRoundedLetterC,
	IconSquareRoundedLetterD,
	IconSquareRoundedLetterE,
	IconSquareRoundedLetterF,
	IconSquareRoundedLetterG,
	IconSquareRoundedLetterH,
	IconSquareRoundedLetterI,
	IconSquareRoundedLetterJ,
	IconSquareRoundedLetterK,
	IconSquareRoundedLetterL,
	IconSquareRoundedLetterM,
	IconSquareRoundedLetterN,
	IconSquareRoundedLetterO,
	IconSquareRoundedLetterP,
	IconSquareRoundedLetterQ,
	IconSquareRoundedLetterR,
	IconSquareRoundedLetterS,
	IconSquareRoundedLetterT,
	IconSquareRoundedLetterU,
	IconSquareRoundedLetterV,
	IconSquareRoundedLetterW,
	IconSquareRoundedLetterX,
	IconSquareRoundedLetterY,
	IconSquareRoundedLetterZ,
	IconSquareRoundedMinus,
	IconSquareRoundedNumber0,
	IconSquareRoundedNumber0Filled,
	IconSquareRoundedNumber1,
	IconSquareRoundedNumber1Filled,
	IconSquareRoundedNumber2,
	IconSquareRoundedNumber2Filled,
	IconSquareRoundedNumber3,
	IconSquareRoundedNumber3Filled,
	IconSquareRoundedNumber4,
	IconSquareRoundedNumber4Filled,
	IconSquareRoundedNumber5,
	IconSquareRoundedNumber5Filled,
	IconSquareRoundedNumber6,
	IconSquareRoundedNumber6Filled,
	IconSquareRoundedNumber7,
	IconSquareRoundedNumber7Filled,
	IconSquareRoundedNumber8,
	IconSquareRoundedNumber8Filled,
	IconSquareRoundedNumber9,
	IconSquareRoundedNumber9Filled,
	IconSquareRoundedPlus,
	IconSquareRoundedPlusFilled,
	IconSquareRoundedX,
	IconSquareRoundedXFilled,
	IconSquaresDiagonal,
	IconSquaresFilled,
	IconSquareToggle,
	IconSquareToggleHorizontal,
	IconSquareX,
	IconStack,
	IconStack2,
	IconStack3,
	IconStackPop,
	IconStackPush,
	IconStairs,
	IconStairsDown,
	IconStairsUp,
	IconStar,
	IconStarFilled,
	IconStarHalf,
	IconStarHalfFilled,
	IconStarOff,
	IconStars,
	IconStarsFilled,
	IconStarsOff,
	IconStatusChange,
	IconSteam,
	IconSteeringWheel,
	IconSteeringWheelOff,
	IconStepInto,
	IconStepOut,
	IconStereoGlasses,
	IconStethoscope,
	IconStethoscopeOff,
	IconSticker,
	IconStorm,
	IconStormOff,
	IconStretching,
	IconStrikethrough,
	IconSTurnDown,
	IconSTurnLeft,
	IconSTurnRight,
	IconSTurnUp,
	IconSubmarine,
	IconSubscript,
	IconSubtask,
	IconSum,
	IconSumOff,
	IconSun,
	IconSunFilled,
	IconSunglasses,
	IconSunHigh,
	IconSunLow,
	IconSunMoon,
	IconSunOff,
	IconSunrise,
	IconSunset,
	IconSunset2,
	IconSunWind,
	IconSuperscript,
	IconSvg,
	IconSwimming,
	IconSwipe,
	IconSwitch,
	IconSwitch2,
	IconSwitch3,
	IconSwitchHorizontal,
	IconSwitchVertical,
	IconSword,
	IconSwordOff,
	IconSwords,
	IconTable,
	IconTableAlias,
	IconTableExport,
	IconTableFilled,
	IconTableImport,
	IconTableOff,
	IconTableOptions,
	IconTableShortcut,
	IconTag,
	IconTagOff,
	IconTags,
	IconTagsOff,
	IconTallymark1,
	IconTallymark2,
	IconTallymark3,
	IconTallymark4,
	IconTallymarks,
	IconTank,
	IconTarget,
	IconTargetArrow,
	IconTargetOff,
	IconTeapot,
	IconTelescope,
	IconTelescopeOff,
	IconTemperature,
	IconTemperatureCelsius,
	IconTemperatureFahrenheit,
	IconTemperatureMinus,
	IconTemperatureOff,
	IconTemperaturePlus,
	IconTemplate,
	IconTemplateOff,
	IconTent,
	IconTentOff,
	IconTerminal,
	IconTerminal2,
	IconTestPipe,
	IconTestPipe2,
	IconTestPipeOff,
	IconTex,
	IconTextCaption,
	IconTextColor,
	IconTextDecrease,
	IconTextDirectionLtr,
	IconTextDirectionRtl,
	IconTextIncrease,
	IconTextOrientation,
	IconTextPlus,
	IconTextRecognition,
	IconTextResize,
	IconTextSize,
	IconTextSpellcheck,
	IconTexture,
	IconTextWrap,
	IconTextWrapDisabled,
	IconTheater,
	IconThermometer,
	IconThumbDown,
	IconThumbDownFilled,
	IconThumbDownOff,
	IconThumbUp,
	IconThumbUpFilled,
	IconThumbUpOff,
	IconTicket,
	IconTicketOff,
	IconTicTac,
	IconTie,
	IconTilde,
	IconTiltShift,
	IconTiltShiftOff,
	IconTimeline,
	IconTimelineEvent,
	IconTimelineEventExclamation,
	IconTimelineEventMinus,
	IconTimelineEventPlus,
	IconTimelineEventText,
	IconTimelineEventX,
	IconTir,
	IconToggleLeft,
	IconToggleRight,
	IconToiletPaper,
	IconToiletPaperOff,
	IconTool,
	IconTools,
	IconToolsKitchen,
	IconToolsKitchen2,
	IconToolsKitchen2Off,
	IconToolsKitchenOff,
	IconToolsOff,
	IconTooltip,
	IconTopologyBus,
	IconTopologyComplex,
	IconTopologyFull,
	IconTopologyFullHierarchy,
	IconTopologyRing,
	IconTopologyRing2,
	IconTopologyRing3,
	IconTopologyStar,
	IconTopologyStar2,
	IconTopologyStar3,
	IconTopologyStarRing,
	IconTopologyStarRing2,
	IconTopologyStarRing3,
	IconTorii,
	IconTornado,
	IconTournament,
	IconTower,
	IconTowerOff,
	IconTrack,
	IconTractor,
	IconTrademark,
	IconTrafficCone,
	IconTrafficConeOff,
	IconTrafficLights,
	IconTrafficLightsOff,
	IconTrain,
	IconTransferIn,
	IconTransferOut,
	IconTransform,
	IconTransformFilled,
	IconTransitionBottom,
	IconTransitionLeft,
	IconTransitionRight,
	IconTransitionTop,
	IconTrash,
	IconTrashFilled,
	IconTrashOff,
	IconTrashX,
	IconTrashXFilled,
	IconTree,
	IconTrees,
	IconTrekking,
	IconTrendingDown,
	IconTrendingDown2,
	IconTrendingDown3,
	IconTrendingUp,
	IconTrendingUp2,
	IconTrendingUp3,
	IconTriangle,
	IconTriangleFilled,
	IconTriangleInverted,
	IconTriangleInvertedFilled,
	IconTriangleOff,
	IconTriangles,
	IconTriangleSquareCircle,
	IconTrident,
	IconTrolley,
	IconTrophy,
	IconTrophyFilled,
	IconTrophyOff,
	IconTrowel,
	IconTruck,
	IconTruckDelivery,
	IconTruckLoading,
	IconTruckOff,
	IconTruckReturn,
	IconTxt,
	IconTypography,
	IconTypographyOff,
	IconUfo,
	IconUfoOff,
	IconUmbrella,
	IconUmbrellaFilled,
	IconUmbrellaOff,
	IconUnderline,
	IconUnlink,
	IconUpload,
	IconUrgent,
	IconUsb,
	IconUser,
	IconUserCheck,
	IconUserCircle,
	IconUserExclamation,
	IconUserMinus,
	IconUserOff,
	IconUserPlus,
	IconUsers,
	IconUserCog,
	IconUserSearch,
	IconUserX,
	IconUvIndex,
	IconUxCircle,
	IconVaccine,
	IconVaccineBottle,
	IconVaccineBottleOff,
	IconVaccineOff,
	IconVacuumCleaner,
	IconVariable,
	IconVariableMinus,
	IconVariableOff,
	IconVariablePlus,
	IconVector,
	IconVectorBezier,
	IconVectorBezier2,
	IconVectorBezierArc,
	IconVectorBezierCircle,
	IconVectorOff,
	IconVectorSpline,
	IconVectorTriangle,
	IconVectorTriangleOff,
	IconVenus,
	IconVersions,
	IconVersionsFilled,
	IconVersionsOff,
	IconVideo,
	IconVideoMinus,
	IconVideoOff,
	IconVideoPlus,
	IconView360,
	IconView360Off,
	IconViewfinder,
	IconViewfinderOff,
	IconViewportNarrow,
	IconViewportWide,
	IconVinyl,
	IconVip,
	IconVipOff,
	IconVirus,
	IconVirusOff,
	IconVirusSearch,
	IconVocabulary,
	IconVocabularyOff,
	IconVolcano,
	IconVolume,
	IconVolume2,
	IconVolume3,
	IconVolumeOff,
	IconWalk,
	IconWall,
	IconWallet,
	IconWalletOff,
	IconWallOff,
	IconWallpaper,
	IconWallpaperOff,
	IconWand,
	IconWandOff,
	IconWash,
	IconWashDry,
	IconWashDry1,
	IconWashDry2,
	IconWashDry3,
	IconWashDryA,
	IconWashDryclean,
	IconWashDrycleanOff,
	IconWashDryDip,
	IconWashDryF,
	IconWashDryHang,
	IconWashDryOff,
	IconWashDryP,
	IconWashDryShade,
	IconWashDryW,
	IconWashGentle,
	IconWashMachine,
	IconWashOff,
	IconWashPress,
	IconWashTemperature1,
	IconWashTemperature2,
	IconWashTemperature3,
	IconWashTemperature4,
	IconWashTemperature5,
	IconWashTemperature6,
	IconWashTumbleDry,
	IconWashTumbleOff,
	IconWaveSawTool,
	IconWaveSine,
	IconWaveSquare,
	IconWebhook,
	IconWebhookOff,
	IconWeight,
	IconWheelchair,
	IconWheelchairOff,
	IconWhirl,
	IconWifi,
	IconWifi0,
	IconWifi1,
	IconWifi2,
	IconWifiOff,
	IconWind,
	IconWindmill,
	IconWindmillFilled,
	IconWindmillOff,
	IconWindOff,
	IconWindow,
	IconWindowMaximize,
	IconWindowMinimize,
	IconWindowOff,
	IconWindsock,
	IconWiper,
	IconWiperWash,
	IconWoman,
	IconWood,
	IconWorld,
	IconWorldDownload,
	IconWorldLatitude,
	IconWorldLongitude,
	IconWorldOff,
	IconWorldUpload,
	IconWorldWww,
	IconWreckingBall,
	IconWriting,
	IconWritingOff,
	IconWritingSign,
	IconWritingSignOff,
	IconX,
	IconXboxA,
	IconXboxB,
	IconXboxX,
	IconXboxY,
	IconYinYang,
	IconYinYangFilled,
	IconYoga,
	IconZeppelin,
	IconZeppelinOff,
	IconZip,
	IconZodiacAquarius,
	IconZodiacAries,
	IconZodiacCancer,
	IconZodiacCapricorn,
	IconZodiacGemini,
	IconZodiacLeo,
	IconZodiacLibra,
	IconZodiacPisces,
	IconZodiacSagittarius,
	IconZodiacScorpio,
	IconZodiacTaurus,
	IconZodiacVirgo,
	IconZoomCancel,
	IconZoomCheck,
	IconZoomCheckFilled,
	IconZoomCode,
	IconZoomExclamation,
	IconZoomFilled,
	IconZoomIn,
	IconZoomInArea,
	IconZoomInAreaFilled,
	IconZoomInFilled,
	IconZoomMoney,
	IconZoomOut,
	IconZoomOutArea,
	IconZoomOutFilled,
	IconZoomPan,
	IconZoomQuestion,
	IconZoomReplace,
	IconZoomReset,
	IconZzz,
	IconZzzOff,
	IconBookmarkFilled,
} from '@tabler/icons-react';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import SegmentIcon from '@mui/icons-material/Segment';

import { IconNames } from './baseTypes';

type SVGIconBase = Pick<SvgIconProps, 'fontSize' | 'htmlColor' | 'sx' | 'onChange'>;

export interface IconProps extends SVGIconBase {
	id?: string;
	iconName: IconNames;
	fontSize?: 'small' | 'medium' | 'large' | 'inherit';
	color?: string;
	onClick?: (e: any) => any;
	className?: string;
	onMouseEnter?: (e: any) => any;
	onMouseLeave?: (e: any) => any;
}

const iconMap = {
	check: <IconCheck />,
	downloadCloud: <IconCloudDownload />,
	fallback: <IconUserPlus />,
	addUser: <IconUserPlus />,
	IconUsersGroup: <IconUsersGroup />,
	users: <IconUsers />,
	user: <IconUser />,
	checkedUser: <IconUserCheck />,
	moveArrows: <IconArrowsMove />,
	files: <IconFiles />,
	Icon123: <Icon123 />,
	Icon24Hours: <Icon24Hours />,
	Icon2fa: <Icon2fa />,
	Icon360View: <Icon360View />,
	Icon360: <Icon360 />,
	Icon3dCubeSphereOff: <Icon3dCubeSphereOff />,
	Icon3dCubeSphere: <Icon3dCubeSphere />,
	Icon3dRotate: <Icon3dRotate />,
	IconAB2: <IconAB2 />,
	IconABOff: <IconABOff />,
	IconAB: <IconAB />,
	IconAbacusOff: <IconAbacusOff />,
	IconAbacus: <IconAbacus />,
	IconAbc: <IconAbc />,
	IconAccessPointOff: <IconAccessPointOff />,
	IconAccessPoint: <IconAccessPoint />,
	IconAccessibleOffFilled: <IconAccessibleOffFilled />,
	IconAccessibleOff: <IconAccessibleOff />,
	IconAccessible: <IconAccessible />,
	IconActivityHeartbeat: <IconActivityHeartbeat />,
	IconActivity: <IconActivity />,
	IconAd2: <IconAd2 />,
	IconAdCircleOff: <IconAdCircleOff />,
	IconAdCircle: <IconAdCircle />,
	IconAdFilled: <IconAdFilled />,
	IconAdOff: <IconAdOff />,
	DiscountIcon: <DiscountOutlinedIcon />,
	SegmentIcon: <SegmentIcon />,
	IconAd: <IconAd />,
	IconAddressBookOff: <IconAddressBookOff />,
	IconAddressBook: <IconAddressBook />,
	IconAdjustmentsAlt: <IconAdjustmentsAlt />,
	IconAdjustmentsFilled: <IconAdjustmentsFilled />,
	IconAdjustmentsHorizontal: <IconAdjustmentsHorizontal />,
	IconAdjustmentsOff: <IconAdjustmentsOff />,
	IconAdjustments: <IconAdjustments />,
	IconAerialLift: <IconAerialLift />,
	IconAffiliateFilled: <IconAffiliateFilled />,
	IconAffiliate: <IconAffiliate />,
	IconAirBalloon: <IconAirBalloon />,
	IconAirConditioningDisabled: <IconAirConditioningDisabled />,
	IconAirConditioning: <IconAirConditioning />,
	IconAlarmFilled: <IconAlarmFilled />,
	IconAlarmMinusFilled: <IconAlarmMinusFilled />,
	IconAlarmMinus: <IconAlarmMinus />,
	IconAlarmOff: <IconAlarmOff />,
	IconAlarmPlusFilled: <IconAlarmPlusFilled />,
	IconAlarmPlus: <IconAlarmPlus />,
	IconAlarmSnoozeFilled: <IconAlarmSnoozeFilled />,
	IconAlarmSnooze: <IconAlarmSnooze />,
	IconAlarm: <IconAlarm />,
	IconAlbumOff: <IconAlbumOff />,
	IconAlbum: <IconAlbum />,
	IconAlertCircleFilled: <IconAlertCircleFilled />,
	IconAlertCircle: <IconAlertCircle />,
	IconAlertOctagonFilled: <IconAlertOctagonFilled />,
	IconAlertOctagon: <IconAlertOctagon />,
	IconAlertTriangleFilled: <IconAlertTriangleFilled />,
	IconAlertTriangle: <IconAlertTriangle />,
	IconAlienFilled: <IconAlienFilled />,
	IconAlien: <IconAlien />,
	IconAlignBoxBottomCenterFilled: <IconAlignBoxBottomCenterFilled />,
	IconAlignBoxBottomCenter: <IconAlignBoxBottomCenter />,
	IconAlignBoxBottomLeftFilled: <IconAlignBoxBottomLeftFilled />,
	IconAlignBoxBottomLeft: <IconAlignBoxBottomLeft />,
	IconAlignBoxBottomRightFilled: <IconAlignBoxBottomRightFilled />,
	IconAlignBoxBottomRight: <IconAlignBoxBottomRight />,
	IconAlignBoxCenterMiddle: <IconAlignBoxCenterMiddle />,
	IconAlignBoxLeftBottomFilled: <IconAlignBoxLeftBottomFilled />,
	IconAlignBoxLeftBottom: <IconAlignBoxLeftBottom />,
	IconAlignBoxLeftMiddleFilled: <IconAlignBoxLeftMiddleFilled />,
	IconAlignBoxLeftMiddle: <IconAlignBoxLeftMiddle />,
	IconAlignBoxLeftTopFilled: <IconAlignBoxLeftTopFilled />,
	IconAlignBoxLeftTop: <IconAlignBoxLeftTop />,
	IconAlignBoxRightBottomFilled: <IconAlignBoxRightBottomFilled />,
	IconAlignBoxRightBottom: <IconAlignBoxRightBottom />,
	IconAlignBoxRightMiddle: <IconAlignBoxRightMiddle />,
	IconAlignBoxRightTopFilled: <IconAlignBoxRightTopFilled />,
	IconAlignBoxRightTop: <IconAlignBoxRightTop />,
	IconAlignBoxTopCenterFilled: <IconAlignBoxTopCenterFilled />,
	IconAlignBoxTopCenter: <IconAlignBoxTopCenter />,
	IconAlignBoxTopLeftFilled: <IconAlignBoxTopLeftFilled />,
	IconAlignBoxTopLeft: <IconAlignBoxTopLeft />,
	IconAlignBoxTopRightFilled: <IconAlignBoxTopRightFilled />,
	IconAlignBoxTopRight: <IconAlignBoxTopRight />,
	IconAlignCenter: <IconAlignCenter />,
	IconAlignJustified: <IconAlignJustified />,
	IconAlignLeft: <IconAlignLeft />,
	IconAlignRight: <IconAlignRight />,
	IconAlpha: <IconAlpha />,
	IconAlphabetCyrillic: <IconAlphabetCyrillic />,
	IconAlphabetGreek: <IconAlphabetGreek />,
	IconAlphabetLatin: <IconAlphabetLatin />,
	IconAmbulance: <IconAmbulance />,
	IconAmpersand: <IconAmpersand />,
	IconAnalyzeFilled: <IconAnalyzeFilled />,
	IconAnalyzeOff: <IconAnalyzeOff />,
	IconAnalyze: <IconAnalyze />,
	IconAnchorOff: <IconAnchorOff />,
	IconAnchor: <IconAnchor />,
	IconAngle: <IconAngle />,
	IconAnkh: <IconAnkh />,
	IconAntennaBars1: <IconAntennaBars1 />,
	IconAntennaBars2: <IconAntennaBars2 />,
	IconAntennaBars3: <IconAntennaBars3 />,
	IconAntennaBars4: <IconAntennaBars4 />,
	IconAntennaBars5: <IconAntennaBars5 />,
	IconAntennaBarsOff: <IconAntennaBarsOff />,
	IconAntennaOff: <IconAntennaOff />,
	IconAntenna: <IconAntenna />,
	IconApertureOff: <IconApertureOff />,
	IconAperture: <IconAperture />,
	IconApiAppOff: <IconApiAppOff />,
	IconApiApp: <IconApiApp />,
	IconApiOff: <IconApiOff />,
	IconApi: <IconApi />,
	IconAppWindowFilled: <IconAppWindowFilled />,
	IconAppWindow: <IconAppWindow />,
	IconApple: <IconApple />,
	IconAppsFilled: <IconAppsFilled />,
	IconAppsOff: <IconAppsOff />,
	IconApps: <IconApps />,
	IconArchiveOff: <IconArchiveOff />,
	IconArchive: <IconArchive />,
	IconArmchair2Off: <IconArmchair2Off />,
	IconArmchair2: <IconArmchair2 />,
	IconArmchairOff: <IconArmchairOff />,
	IconArmchair: <IconArmchair />,
	IconArrowAutofitContentFilled: <IconArrowAutofitContentFilled />,
	IconArrowAutofitContent: <IconArrowAutofitContent />,
	IconArrowAutofitDown: <IconArrowAutofitDown />,
	IconArrowAutofitHeight: <IconArrowAutofitHeight />,
	IconArrowAutofitLeft: <IconArrowAutofitLeft />,
	IconArrowAutofitRight: <IconArrowAutofitRight />,
	IconArrowAutofitUp: <IconArrowAutofitUp />,
	IconArrowAutofitWidth: <IconArrowAutofitWidth />,
	IconArrowBackUp: <IconArrowBackUp />,
	IconArrowBack: <IconArrowBack />,
	IconArrowBadgeDown: <IconArrowBadgeDown />,
	IconArrowBadgeLeft: <IconArrowBadgeLeft />,
	IconArrowBadgeRight: <IconArrowBadgeRight />,
	IconArrowBadgeUp: <IconArrowBadgeUp />,
	IconArrowBarDown: <IconArrowBarDown />,
	IconArrowBarLeft: <IconArrowBarLeft />,
	IconArrowBarRight: <IconArrowBarRight />,
	IconArrowBarToDown: <IconArrowBarToDown />,
	IconArrowBarToLeft: <IconArrowBarToLeft />,
	IconArrowBarToRight: <IconArrowBarToRight />,
	IconArrowBarToUp: <IconArrowBarToUp />,
	IconArrowBarUp: <IconArrowBarUp />,
	IconArrowBearLeft2: <IconArrowBearLeft2 />,
	IconArrowBearLeft: <IconArrowBearLeft />,
	IconArrowBearRight2: <IconArrowBearRight2 />,
	IconArrowBearRight: <IconArrowBearRight />,
	IconArrowBigDownFilled: <IconArrowBigDownFilled />,
	IconArrowBigDownLineFilled: <IconArrowBigDownLineFilled />,
	IconArrowBigDownLine: <IconArrowBigDownLine />,
	IconArrowBigDownLinesFilled: <IconArrowBigDownLinesFilled />,
	IconArrowBigDownLines: <IconArrowBigDownLines />,
	IconArrowBigDown: <IconArrowBigDown />,
	IconArrowBigLeftFilled: <IconArrowBigLeftFilled />,
	IconArrowBigLeftLineFilled: <IconArrowBigLeftLineFilled />,
	IconArrowBigLeftLine: <IconArrowBigLeftLine />,
	IconArrowBigLeftLinesFilled: <IconArrowBigLeftLinesFilled />,
	IconArrowBigLeftLines: <IconArrowBigLeftLines />,
	IconArrowBigLeft: <IconArrowBigLeft />,
	IconArrowBigRightFilled: <IconArrowBigRightFilled />,
	IconArrowBigRightLineFilled: <IconArrowBigRightLineFilled />,
	IconArrowBigRightLine: <IconArrowBigRightLine />,
	IconArrowBigRightLinesFilled: <IconArrowBigRightLinesFilled />,
	IconArrowBigRightLines: <IconArrowBigRightLines />,
	IconArrowBigRight: <IconArrowBigRight />,
	IconArrowBigUpFilled: <IconArrowBigUpFilled />,
	IconArrowBigUpLineFilled: <IconArrowBigUpLineFilled />,
	IconArrowBigUpLine: <IconArrowBigUpLine />,
	IconArrowBigUpLinesFilled: <IconArrowBigUpLinesFilled />,
	IconArrowBigUpLines: <IconArrowBigUpLines />,
	IconArrowBigUp: <IconArrowBigUp />,
	IconArrowBounce: <IconArrowBounce />,
	IconArrowCurveLeft: <IconArrowCurveLeft />,
	IconArrowCurveRight: <IconArrowCurveRight />,
	IconArrowDownBar: <IconArrowDownBar />,
	IconArrowDownCircle: <IconArrowDownCircle />,
	IconArrowDownLeftCircle: <IconArrowDownLeftCircle />,
	IconArrowDownLeft: <IconArrowDownLeft />,
	IconArrowDownRhombus: <IconArrowDownRhombus />,
	IconArrowDownRightCircle: <IconArrowDownRightCircle />,
	IconArrowDownRight: <IconArrowDownRight />,
	IconArrowDownSquare: <IconArrowDownSquare />,
	IconArrowDownTail: <IconArrowDownTail />,
	IconArrowDown: <IconArrowDown />,
	IconArrowFork: <IconArrowFork />,
	IconArrowForwardUp: <IconArrowForwardUp />,
	IconArrowForward: <IconArrowForward />,
	IconArrowGuide: <IconArrowGuide />,
	IconArrowIteration: <IconArrowIteration />,
	IconArrowLeftBar: <IconArrowLeftBar />,
	IconArrowLeftCircle: <IconArrowLeftCircle />,
	IconArrowLeftRhombus: <IconArrowLeftRhombus />,
	IconArrowLeftRight: <IconArrowLeftRight />,
	IconArrowLeftSquare: <IconArrowLeftSquare />,
	IconArrowLeftTail: <IconArrowLeftTail />,
	IconArrowLeft: <IconArrowLeft />,
	IconArrowLoopLeft2: <IconArrowLoopLeft2 />,
	IconArrowLoopLeft: <IconArrowLoopLeft />,
	IconArrowLoopRight2: <IconArrowLoopRight2 />,
	IconArrowLoopRight: <IconArrowLoopRight />,
	IconArrowMergeBoth: <IconArrowMergeBoth />,
	IconArrowMergeLeft: <IconArrowMergeLeft />,
	IconArrowMergeRight: <IconArrowMergeRight />,
	IconArrowMerge: <IconArrowMerge />,
	IconArrowMoveDown: <IconArrowMoveDown />,
	IconArrowMoveLeft: <IconArrowMoveLeft />,
	IconArrowMoveRight: <IconArrowMoveRight />,
	IconArrowMoveUp: <IconArrowMoveUp />,
	IconArrowNarrowDown: <IconArrowNarrowDown />,
	IconArrowNarrowLeft: <IconArrowNarrowLeft />,
	IconArrowNarrowRight: <IconArrowNarrowRight />,
	IconArrowNarrowUp: <IconArrowNarrowUp />,
	IconArrowRampLeft2: <IconArrowRampLeft2 />,
	IconArrowRampLeft3: <IconArrowRampLeft3 />,
	IconArrowRampLeft: <IconArrowRampLeft />,
	IconArrowRampRight2: <IconArrowRampRight2 />,
	IconArrowRampRight3: <IconArrowRampRight3 />,
	IconArrowRampRight: <IconArrowRampRight />,
	IconArrowRightBar: <IconArrowRightBar />,
	IconArrowRightCircle: <IconArrowRightCircle />,
	IconArrowRightRhombus: <IconArrowRightRhombus />,
	IconArrowRightSquare: <IconArrowRightSquare />,
	IconArrowRightTail: <IconArrowRightTail />,
	IconArrowRight: <IconArrowRight />,
	IconArrowRotaryFirstLeft: <IconArrowRotaryFirstLeft />,
	IconArrowRotaryFirstRight: <IconArrowRotaryFirstRight />,
	IconArrowRotaryLastLeft: <IconArrowRotaryLastLeft />,
	IconArrowRotaryLastRight: <IconArrowRotaryLastRight />,
	IconArrowRotaryLeft: <IconArrowRotaryLeft />,
	IconArrowRotaryRight: <IconArrowRotaryRight />,
	IconArrowRotaryStraight: <IconArrowRotaryStraight />,
	IconArrowRoundaboutLeft: <IconArrowRoundaboutLeft />,
	IconArrowRoundaboutRight: <IconArrowRoundaboutRight />,
	IconArrowSharpTurnLeft: <IconArrowSharpTurnLeft />,
	IconArrowSharpTurnRight: <IconArrowSharpTurnRight />,
	// IconArrowTopCircle: <IconArrowTopCircle />,
	IconArrowUpBar: <IconArrowUpBar />,
	IconArrowUpCircle: <IconArrowUpCircle />,
	IconArrowUpLeftCircle: <IconArrowUpLeftCircle />,
	IconArrowUpLeft: <IconArrowUpLeft />,
	IconArrowUpRhombus: <IconArrowUpRhombus />,
	IconArrowUpRightCircle: <IconArrowUpRightCircle />,
	IconArrowUpRight: <IconArrowUpRight />,
	IconArrowUpSquare: <IconArrowUpSquare />,
	IconArrowUpTail: <IconArrowUpTail />,
	IconArrowUp: <IconArrowUp />,
	IconArrowWaveLeftDown: <IconArrowWaveLeftDown />,
	IconArrowWaveLeftUp: <IconArrowWaveLeftUp />,
	IconArrowWaveRightDown: <IconArrowWaveRightDown />,
	IconArrowWaveRightUp: <IconArrowWaveRightUp />,
	IconArrowZigZag: <IconArrowZigZag />,
	IconArrowsCross: <IconArrowsCross />,
	IconArrowsDiagonal2: <IconArrowsDiagonal2 />,
	IconArrowsDiagonalMinimize2: <IconArrowsDiagonalMinimize2 />,
	IconArrowsDiagonalMinimize: <IconArrowsDiagonalMinimize />,
	IconArrowsDiagonal: <IconArrowsDiagonal />,
	IconArrowsDiff: <IconArrowsDiff />,
	IconArrowsDoubleNeSw: <IconArrowsDoubleNeSw />,
	IconArrowsDoubleNwSe: <IconArrowsDoubleNwSe />,
	IconArrowsDoubleSeNw: <IconArrowsDoubleSeNw />,
	IconArrowsDoubleSwNe: <IconArrowsDoubleSwNe />,
	IconArrowsDownUp: <IconArrowsDownUp />,
	IconArrowsDown: <IconArrowsDown />,
	IconArrowsExchange2: <IconArrowsExchange2 />,
	IconArrowsExchange: <IconArrowsExchange />,
	IconArrowsHorizontal: <IconArrowsHorizontal />,
	IconArrowsJoin2: <IconArrowsJoin2 />,
	IconArrowsJoin: <IconArrowsJoin />,
	IconArrowsLeftDown: <IconArrowsLeftDown />,
	IconArrowsLeftRight: <IconArrowsLeftRight />,
	IconArrowsLeft: <IconArrowsLeft />,
	IconArrowsMaximize: <IconArrowsMaximize />,
	IconArrowsMinimize: <IconArrowsMinimize />,
	IconArrowsMoveHorizontal: <IconArrowsMoveHorizontal />,
	IconArrowsMoveVertical: <IconArrowsMoveVertical />,
	IconArrowsMove: <IconArrowsMove />,
	IconArrowsRandom: <IconArrowsRandom />,
	IconArrowsRightDown: <IconArrowsRightDown />,
	IconArrowsRightLeft: <IconArrowsRightLeft />,
	IconArrowsRight: <IconArrowsRight />,
	IconArrowsShuffle2: <IconArrowsShuffle2 />,
	IconArrowsShuffle: <IconArrowsShuffle />,
	IconArrowsSort: <IconArrowsSort />,
	IconArrowsSplit2: <IconArrowsSplit2 />,
	IconArrowsSplit: <IconArrowsSplit />,
	IconArrowsTransferDown: <IconArrowsTransferDown />,
	IconArrowsTransferUp: <IconArrowsTransferUp />,
	IconArrowsUpDown: <IconArrowsUpDown />,
	IconArrowsUpLeft: <IconArrowsUpLeft />,
	IconArrowsUpRight: <IconArrowsUpRight />,
	IconArrowsUp: <IconArrowsUp />,
	IconArrowsVertical: <IconArrowsVertical />,
	IconArtboardOff: <IconArtboardOff />,
	IconArtboard: <IconArtboard />,
	IconArticleOff: <IconArticleOff />,
	IconArticle: <IconArticle />,
	IconAspectRatioOff: <IconAspectRatioOff />,
	IconAspectRatio: <IconAspectRatio />,
	IconAssemblyOff: <IconAssemblyOff />,
	IconAssembly: <IconAssembly />,
	IconAsset: <IconAsset />,
	IconAsteriskSimple: <IconAsteriskSimple />,
	IconAsterisk: <IconAsterisk />,
	IconAtOff: <IconAtOff />,
	IconAt: <IconAt />,
	IconAtom2Filled: <IconAtom2Filled />,
	IconAtom2: <IconAtom2 />,
	IconAtomOff: <IconAtomOff />,
	IconAtom: <IconAtom />,
	IconAugmentedReality2: <IconAugmentedReality2 />,
	IconAugmentedRealityOff: <IconAugmentedRealityOff />,
	IconAugmentedReality: <IconAugmentedReality />,
	IconAwardFilled: <IconAwardFilled />,
	IconAwardOff: <IconAwardOff />,
	IconAward: <IconAward />,
	IconAxe: <IconAxe />,
	IconAxisX: <IconAxisX />,
	IconAxisY: <IconAxisY />,
	IconBabyBottle: <IconBabyBottle />,
	IconBabyCarriage: <IconBabyCarriage />,
	IconBackhoe: <IconBackhoe />,
	IconBackpackOff: <IconBackpackOff />,
	IconBackpack: <IconBackpack />,
	IconBackspace: <IconBackspace />,
	IconBadge3d: <IconBadge3d />,
	IconBadge4k: <IconBadge4k />,
	IconBadge8k: <IconBadge8k />,
	IconBadgeAd: <IconBadgeAd />,
	IconBadgeAr: <IconBadgeAr />,
	IconBadgeCc: <IconBadgeCc />,
	IconBadgeFilled: <IconBadgeFilled />,
	IconBadgeHd: <IconBadgeHd />,
	IconBadgeOff: <IconBadgeOff />,
	IconBadgeSd: <IconBadgeSd />,
	IconBadgeTm: <IconBadgeTm />,
	IconBadgeVo: <IconBadgeVo />,
	IconBadgeVr: <IconBadgeVr />,
	IconBadgeWc: <IconBadgeWc />,
	IconBadge: <IconBadge />,
	IconBadgesOff: <IconBadgesOff />,
	IconBadges: <IconBadges />,
	IconBaguette: <IconBaguette />,
	IconBallAmericanFootballOff: <IconBallAmericanFootballOff />,
	IconBallAmericanFootball: <IconBallAmericanFootball />,
	IconBallBaseball: <IconBallBaseball />,
	IconBallBasketball: <IconBallBasketball />,
	IconBallBowling: <IconBallBowling />,
	IconBallFootballOff: <IconBallFootballOff />,
	IconBallFootball: <IconBallFootball />,
	IconBallTennis: <IconBallTennis />,
	IconBallVolleyball: <IconBallVolleyball />,
	IconBalloonOff: <IconBalloonOff />,
	IconBalloon: <IconBalloon />,
	IconBallpenOff: <IconBallpenOff />,
	IconBallpen: <IconBallpen />,
	IconBan: <IconBan />,
	IconBandageOff: <IconBandageOff />,
	IconBandage: <IconBandage />,
	IconBarbellOff: <IconBarbellOff />,
	IconBarbell: <IconBarbell />,
	IconBarcodeOff: <IconBarcodeOff />,
	IconBarcode: <IconBarcode />,
	IconBarrelOff: <IconBarrelOff />,
	IconBarrel: <IconBarrel />,
	IconBarrierBlockOff: <IconBarrierBlockOff />,
	IconBarrierBlock: <IconBarrierBlock />,
	IconBaseline: <IconBaseline />,
	IconBasketOff: <IconBasketOff />,
	IconBasket: <IconBasket />,
	IconBat: <IconBat />,
	IconBathFilled: <IconBathFilled />,
	IconBathOff: <IconBathOff />,
	IconBath: <IconBath />,
	IconBattery1Filled: <IconBattery1Filled />,
	IconBattery1: <IconBattery1 />,
	IconBattery2Filled: <IconBattery2Filled />,
	IconBattery2: <IconBattery2 />,
	IconBattery3Filled: <IconBattery3Filled />,
	IconBattery3: <IconBattery3 />,
	IconBattery4Filled: <IconBattery4Filled />,
	IconBattery4: <IconBattery4 />,
	IconBatteryAutomotive: <IconBatteryAutomotive />,
	IconBatteryCharging2: <IconBatteryCharging2 />,
	IconBatteryCharging: <IconBatteryCharging />,
	IconBatteryEco: <IconBatteryEco />,
	IconBatteryFilled: <IconBatteryFilled />,
	IconBatteryOff: <IconBatteryOff />,
	IconBattery: <IconBattery />,
	IconBeachOff: <IconBeachOff />,
	IconBeach: <IconBeach />,
	IconBedOff: <IconBedOff />,
	IconBed: <IconBed />,
	IconBeerOff: <IconBeerOff />,
	IconBeer: <IconBeer />,
	IconBellFilled: <IconBellFilled />,
	IconBellMinusFilled: <IconBellMinusFilled />,
	IconBellMinus: <IconBellMinus />,
	IconBellOff: <IconBellOff />,
	IconBellPlusFilled: <IconBellPlusFilled />,
	IconBellPlus: <IconBellPlus />,
	IconBellRinging2Filled: <IconBellRinging2Filled />,
	IconBellRinging2: <IconBellRinging2 />,
	IconBellRingingFilled: <IconBellRingingFilled />,
	IconBellRinging: <IconBellRinging />,
	IconBellSchool: <IconBellSchool />,
	IconBellXFilled: <IconBellXFilled />,
	IconBellX: <IconBellX />,
	IconBellZFilled: <IconBellZFilled />,
	IconBellZ: <IconBellZ />,
	IconBell: <IconBell />,
	IconBeta: <IconBeta />,
	IconBible: <IconBible />,
	IconBikeOff: <IconBikeOff />,
	IconBike: <IconBike />,
	IconBinaryOff: <IconBinaryOff />,
	IconBinaryTree2: <IconBinaryTree2 />,
	IconBinaryTree: <IconBinaryTree />,
	IconBinary: <IconBinary />,
	IconBiohazardOff: <IconBiohazardOff />,
	IconBiohazard: <IconBiohazard />,
	IconBlade: <IconBlade />,
	IconBleachChlorine: <IconBleachChlorine />,
	IconBleachNoChlorine: <IconBleachNoChlorine />,
	IconBleachOff: <IconBleachOff />,
	IconBleach: <IconBleach />,
	IconBlockquote: <IconBlockquote />,
	IconBluetoothConnected: <IconBluetoothConnected />,
	IconBluetoothOff: <IconBluetoothOff />,
	IconBluetoothX: <IconBluetoothX />,
	IconBluetooth: <IconBluetooth />,
	IconBlurOff: <IconBlurOff />,
	IconBlur: <IconBlur />,
	IconBmp: <IconBmp />,
	IconBoldOff: <IconBoldOff />,
	IconBold: <IconBold />,
	IconBoltOff: <IconBoltOff />,
	IconBolt: <IconBolt />,
	IconBomb: <IconBomb />,
	IconBoneOff: <IconBoneOff />,
	IconBone: <IconBone />,
	IconBongOff: <IconBongOff />,
	IconBong: <IconBong />,
	IconBook2: <IconBook2 />,
	IconBookDownload: <IconBookDownload />,
	IconBookOff: <IconBookOff />,
	IconBookUpload: <IconBookUpload />,
	IconBook: <IconBook />,
	IconBookmarkOff: <IconBookmarkOff />,
	IconBookmark: <IconBookmark />,
	IconBookmarksOff: <IconBookmarksOff />,
	IconBookmarks: <IconBookmarks />,
	IconBooksOff: <IconBooksOff />,
	IconBooks: <IconBooks />,
	IconBorderAll: <IconBorderAll />,
	IconBorderBottom: <IconBorderBottom />,
	IconBorderCorners: <IconBorderCorners />,
	IconBorderHorizontal: <IconBorderHorizontal />,
	IconBorderInner: <IconBorderInner />,
	IconBorderLeft: <IconBorderLeft />,
	IconBorderNone: <IconBorderNone />,
	IconBorderOuter: <IconBorderOuter />,
	IconBorderRadius: <IconBorderRadius />,
	IconBorderRight: <IconBorderRight />,
	IconBorderSides: <IconBorderSides />,
	IconBorderStyle2: <IconBorderStyle2 />,
	IconBorderStyle: <IconBorderStyle />,
	IconBorderTop: <IconBorderTop />,
	IconBorderVertical: <IconBorderVertical />,
	IconBottleOff: <IconBottleOff />,
	IconBottle: <IconBottle />,
	IconBounceLeft: <IconBounceLeft />,
	IconBounceRight: <IconBounceRight />,
	IconBow: <IconBow />,
	IconBowl: <IconBowl />,
	IconBoxAlignBottomLeft: <IconBoxAlignBottomLeft />,
	IconBoxAlignBottomRight: <IconBoxAlignBottomRight />,
	IconBoxAlignBottom: <IconBoxAlignBottom />,
	IconBoxAlignLeft: <IconBoxAlignLeft />,
	IconBoxAlignRight: <IconBoxAlignRight />,
	IconBoxAlignTopLeft: <IconBoxAlignTopLeft />,
	IconBoxAlignTopRight: <IconBoxAlignTopRight />,
	IconBoxAlignTop: <IconBoxAlignTop />,
	IconBoxMargin: <IconBoxMargin />,
	IconBoxModel2Off: <IconBoxModel2Off />,
	IconBoxModel2: <IconBoxModel2 />,
	IconBoxModelOff: <IconBoxModelOff />,
	IconBoxModel: <IconBoxModel />,
	IconBoxMultiple0: <IconBoxMultiple0 />,
	IconBoxMultiple1: <IconBoxMultiple1 />,
	IconBoxMultiple2: <IconBoxMultiple2 />,
	IconBoxMultiple3: <IconBoxMultiple3 />,
	IconBoxMultiple4: <IconBoxMultiple4 />,
	IconBoxMultiple5: <IconBoxMultiple5 />,
	IconBoxMultiple6: <IconBoxMultiple6 />,
	IconBoxMultiple7: <IconBoxMultiple7 />,
	IconBoxMultiple8: <IconBoxMultiple8 />,
	IconBoxMultiple9: <IconBoxMultiple9 />,
	IconBoxMultiple: <IconBoxMultiple />,
	IconBoxOff: <IconBoxOff />,
	IconBoxPadding: <IconBoxPadding />,
	IconBoxSeam: <IconBoxSeam />,
	IconBox: <IconBox />,
	IconBracesOff: <IconBracesOff />,
	IconBraces: <IconBraces />,
	IconBracketsContainEnd: <IconBracketsContainEnd />,
	IconBracketsContainStart: <IconBracketsContainStart />,
	IconBracketsContain: <IconBracketsContain />,
	IconBracketsOff: <IconBracketsOff />,
	IconBrackets: <IconBrackets />,
	IconBraille: <IconBraille />,
	IconBrain: <IconBrain />,
	IconBrand4chan: <IconBrand4chan />,
	IconBrandAbstract: <IconBrandAbstract />,
	IconBrandAdobe: <IconBrandAdobe />,
	IconBrandAdonisJs: <IconBrandAdonisJs />,
	IconBrandAirbnb: <IconBrandAirbnb />,
	IconBrandAirtable: <IconBrandAirtable />,
	IconBrandAlgolia: <IconBrandAlgolia />,
	IconBrandAlipay: <IconBrandAlipay />,
	IconBrandAlpineJs: <IconBrandAlpineJs />,
	IconBrandAmazon: <IconBrandAmazon />,
	IconBrandAmd: <IconBrandAmd />,
	IconBrandAmigo: <IconBrandAmigo />,
	IconBrandAmongUs: <IconBrandAmongUs />,
	IconBrandAndroid: <IconBrandAndroid />,
	IconBrandAngular: <IconBrandAngular />,
	IconBrandAo3: <IconBrandAo3 />,
	IconBrandAppgallery: <IconBrandAppgallery />,
	IconBrandAppleArcade: <IconBrandAppleArcade />,
	IconBrandApplePodcast: <IconBrandApplePodcast />,
	IconBrandApple: <IconBrandApple />,
	IconBrandAppstore: <IconBrandAppstore />,
	IconBrandAsana: <IconBrandAsana />,
	IconBrandBackbone: <IconBrandBackbone />,
	IconBrandBadoo: <IconBrandBadoo />,
	IconBrandBaidu: <IconBrandBaidu />,
	IconBrandBandcamp: <IconBrandBandcamp />,
	IconBrandBandlab: <IconBrandBandlab />,
	IconBrandBeats: <IconBrandBeats />,
	IconBrandBehance: <IconBrandBehance />,
	IconBrandBilibili: <IconBrandBilibili />,
	IconBrandBinance: <IconBrandBinance />,
	IconBrandBing: <IconBrandBing />,
	IconBrandBitbucket: <IconBrandBitbucket />,
	IconBrandBlackberry: <IconBrandBlackberry />,
	IconBrandBlender: <IconBrandBlender />,
	IconBrandBlogger: <IconBrandBlogger />,
	IconBrandBooking: <IconBrandBooking />,
	IconBrandBootstrap: <IconBrandBootstrap />,
	IconBrandBulma: <IconBrandBulma />,
	IconBrandBumble: <IconBrandBumble />,
	IconBrandBunpo: <IconBrandBunpo />,
	IconBrandCSharp: <IconBrandCSharp />,
	IconBrandCake: <IconBrandCake />,
	IconBrandCampaignmonitor: <IconBrandCampaignmonitor />,
	IconBrandCarbon: <IconBrandCarbon />,
	IconBrandCashapp: <IconBrandCashapp />,
	IconBrandChrome: <IconBrandChrome />,
	IconBrandCitymapper: <IconBrandCitymapper />,
	IconBrandCodecov: <IconBrandCodecov />,
	IconBrandCodepen: <IconBrandCodepen />,
	IconBrandCodesandbox: <IconBrandCodesandbox />,
	IconBrandCohost: <IconBrandCohost />,
	IconBrandCoinbase: <IconBrandCoinbase />,
	IconBrandComedyCentral: <IconBrandComedyCentral />,
	IconBrandCoreos: <IconBrandCoreos />,
	IconBrandCouchdb: <IconBrandCouchdb />,
	IconBrandCouchsurfing: <IconBrandCouchsurfing />,
	IconBrandCpp: <IconBrandCpp />,
	IconBrandCss3: <IconBrandCss3 />,
	IconBrandCtemplar: <IconBrandCtemplar />,
	IconBrandCucumber: <IconBrandCucumber />,
	IconBrandCupra: <IconBrandCupra />,
	IconBrandCypress: <IconBrandCypress />,
	IconBrandD3: <IconBrandD3 />,
	IconBrandDaysCounter: <IconBrandDaysCounter />,
	IconBrandDcos: <IconBrandDcos />,
	IconBrandDebian: <IconBrandDebian />,
	IconBrandDeezer: <IconBrandDeezer />,
	IconBrandDeliveroo: <IconBrandDeliveroo />,
	IconBrandDeno: <IconBrandDeno />,
	IconBrandDenodo: <IconBrandDenodo />,
	IconBrandDeviantart: <IconBrandDeviantart />,
	IconBrandDingtalk: <IconBrandDingtalk />,
	IconBrandDiscord: <IconBrandDiscord />,
	IconBrandDisney: <IconBrandDisney />,
	IconBrandDisqus: <IconBrandDisqus />,
	IconBrandDjango: <IconBrandDjango />,
	IconBrandDocker: <IconBrandDocker />,
	IconBrandDoctrine: <IconBrandDoctrine />,
	IconBrandDolbyDigital: <IconBrandDolbyDigital />,
	IconBrandDouban: <IconBrandDouban />,
	IconBrandDribbble: <IconBrandDribbble />,
	IconBrandDrops: <IconBrandDrops />,
	IconBrandDrupal: <IconBrandDrupal />,
	IconBrandEdge: <IconBrandEdge />,
	IconBrandElastic: <IconBrandElastic />,
	IconBrandEmber: <IconBrandEmber />,
	IconBrandEnvato: <IconBrandEnvato />,
	IconBrandEtsy: <IconBrandEtsy />,
	IconBrandEvernote: <IconBrandEvernote />,
	IconBrandFacebook: <IconBrandFacebook />,
	IconBrandFigma: <IconBrandFigma />,
	IconBrandFinder: <IconBrandFinder />,
	IconBrandFirebase: <IconBrandFirebase />,
	IconBrandFirefox: <IconBrandFirefox />,
	IconBrandFiverr: <IconBrandFiverr />,
	IconBrandFlickr: <IconBrandFlickr />,
	IconBrandFlightradar24: <IconBrandFlightradar24 />,
	IconBrandFlipboard: <IconBrandFlipboard />,
	IconBrandFlutter: <IconBrandFlutter />,
	IconBrandFortnite: <IconBrandFortnite />,
	IconBrandFoursquare: <IconBrandFoursquare />,
	IconBrandFramerMotion: <IconBrandFramerMotion />,
	IconBrandFramer: <IconBrandFramer />,
	IconBrandFunimation: <IconBrandFunimation />,
	IconBrandGatsby: <IconBrandGatsby />,
	IconBrandGit: <IconBrandGit />,
	IconBrandGithubCopilot: <IconBrandGithubCopilot />,
	IconBrandGithub: <IconBrandGithub />,
	IconBrandGitlab: <IconBrandGitlab />,
	IconBrandGmail: <IconBrandGmail />,
	IconBrandGolang: <IconBrandGolang />,
	IconBrandGoogleAnalytics: <IconBrandGoogleAnalytics />,
	IconBrandGoogleBigQuery: <IconBrandGoogleBigQuery />,
	IconBrandGoogleDrive: <IconBrandGoogleDrive />,
	IconBrandGoogleFit: <IconBrandGoogleFit />,
	IconBrandGoogleHome: <IconBrandGoogleHome />,
	IconBrandGoogleOne: <IconBrandGoogleOne />,
	IconBrandGooglePhotos: <IconBrandGooglePhotos />,
	IconBrandGooglePlay: <IconBrandGooglePlay />,
	IconBrandGooglePodcasts: <IconBrandGooglePodcasts />,
	IconBrandGoogle: <IconBrandGoogle />,
	IconBrandGrammarly: <IconBrandGrammarly />,
	IconBrandGraphql: <IconBrandGraphql />,
	IconBrandGravatar: <IconBrandGravatar />,
	IconBrandGrindr: <IconBrandGrindr />,
	IconBrandGuardian: <IconBrandGuardian />,
	IconBrandGumroad: <IconBrandGumroad />,
	IconBrandHbo: <IconBrandHbo />,
	IconBrandHeadlessui: <IconBrandHeadlessui />,
	IconBrandHipchat: <IconBrandHipchat />,
	IconBrandHtml5: <IconBrandHtml5 />,
	IconBrandInertia: <IconBrandInertia />,
	IconBrandInstagram: <IconBrandInstagram />,
	IconBrandIntercom: <IconBrandIntercom />,
	IconBrandJavascript: <IconBrandJavascript />,
	IconBrandKickstarter: <IconBrandKickstarter />,
	IconBrandKotlin: <IconBrandKotlin />,
	IconBrandLaravel: <IconBrandLaravel />,
	IconBrandLastfm: <IconBrandLastfm />,
	IconBrandLinkedin: <IconBrandLinkedin />,
	IconBrandLinktree: <IconBrandLinktree />,
	IconBrandLinqpad: <IconBrandLinqpad />,
	IconBrandLoom: <IconBrandLoom />,
	IconBrandMailgun: <IconBrandMailgun />,
	IconBrandMantine: <IconBrandMantine />,
	IconBrandMastercard: <IconBrandMastercard />,
	IconBrandMastodon: <IconBrandMastodon />,
	IconBrandMatrix: <IconBrandMatrix />,
	IconBrandMcdonalds: <IconBrandMcdonalds />,
	IconBrandMedium: <IconBrandMedium />,
	IconBrandMercedes: <IconBrandMercedes />,
	IconBrandMessenger: <IconBrandMessenger />,
	IconBrandMeta: <IconBrandMeta />,
	IconBrandMiniprogram: <IconBrandMiniprogram />,
	IconBrandMixpanel: <IconBrandMixpanel />,
	IconBrandMonday: <IconBrandMonday />,
	IconBrandMongodb: <IconBrandMongodb />,
	IconBrandMyOppo: <IconBrandMyOppo />,
	IconBrandMysql: <IconBrandMysql />,
	IconBrandNationalGeographic: <IconBrandNationalGeographic />,
	IconBrandNem: <IconBrandNem />,
	IconBrandNetbeans: <IconBrandNetbeans />,
	IconBrandNeteaseMusic: <IconBrandNeteaseMusic />,
	IconBrandNetflix: <IconBrandNetflix />,
	IconBrandNexo: <IconBrandNexo />,
	IconBrandNextcloud: <IconBrandNextcloud />,
	IconBrandNextjs: <IconBrandNextjs />,
	IconBrandNordVpn: <IconBrandNordVpn />,
	IconBrandNotion: <IconBrandNotion />,
	IconBrandNpm: <IconBrandNpm />,
	IconBrandNuxt: <IconBrandNuxt />,
	IconBrandNytimes: <IconBrandNytimes />,
	IconBrandOffice: <IconBrandOffice />,
	IconBrandOkRu: <IconBrandOkRu />,
	IconBrandOnedrive: <IconBrandOnedrive />,
	IconBrandOnlyfans: <IconBrandOnlyfans />,
	IconBrandOpenSource: <IconBrandOpenSource />,
	IconBrandOpenai: <IconBrandOpenai />,
	IconBrandOpenvpn: <IconBrandOpenvpn />,
	IconBrandOpera: <IconBrandOpera />,
	IconBrandPagekit: <IconBrandPagekit />,
	IconBrandPatreon: <IconBrandPatreon />,
	IconBrandPaypal: <IconBrandPaypal />,
	IconBrandPaypay: <IconBrandPaypay />,
	IconBrandPeanut: <IconBrandPeanut />,
	IconBrandPepsi: <IconBrandPepsi />,
	IconBrandPhp: <IconBrandPhp />,
	IconBrandPicsart: <IconBrandPicsart />,
	IconBrandPinterest: <IconBrandPinterest />,
	IconBrandPlanetscale: <IconBrandPlanetscale />,
	IconBrandPocket: <IconBrandPocket />,
	IconBrandPolymer: <IconBrandPolymer />,
	IconBrandPowershell: <IconBrandPowershell />,
	IconBrandPrisma: <IconBrandPrisma />,
	IconBrandProducthunt: <IconBrandProducthunt />,
	IconBrandPushbullet: <IconBrandPushbullet />,
	IconBrandPushover: <IconBrandPushover />,
	IconBrandPython: <IconBrandPython />,
	IconBrandQq: <IconBrandQq />,
	IconBrandRadixUi: <IconBrandRadixUi />,
	IconBrandReactNative: <IconBrandReactNative />,
	IconBrandReact: <IconBrandReact />,
	IconBrandReason: <IconBrandReason />,
	IconBrandReddit: <IconBrandReddit />,
	IconBrandRedhat: <IconBrandRedhat />,
	IconBrandRedux: <IconBrandRedux />,
	IconBrandRevolut: <IconBrandRevolut />,
	IconBrandSafari: <IconBrandSafari />,
	IconBrandSamsungpass: <IconBrandSamsungpass />,
	IconBrandSass: <IconBrandSass />,
	IconBrandSentry: <IconBrandSentry />,
	IconBrandSharik: <IconBrandSharik />,
	IconBrandShazam: <IconBrandShazam />,
	IconBrandShopee: <IconBrandShopee />,
	IconBrandSketch: <IconBrandSketch />,
	IconBrandSkype: <IconBrandSkype />,
	IconBrandSlack: <IconBrandSlack />,
	IconBrandSnapchat: <IconBrandSnapchat />,
	IconBrandSnapseed: <IconBrandSnapseed />,
	IconBrandSnowflake: <IconBrandSnowflake />,
	IconBrandSocketIo: <IconBrandSocketIo />,
	IconBrandSolidjs: <IconBrandSolidjs />,
	IconBrandSoundcloud: <IconBrandSoundcloud />,
	IconBrandSpacehey: <IconBrandSpacehey />,
	IconBrandSpotify: <IconBrandSpotify />,
	IconBrandStackoverflow: <IconBrandStackoverflow />,
	IconBrandStackshare: <IconBrandStackshare />,
	IconBrandSteam: <IconBrandSteam />,
	IconBrandStorybook: <IconBrandStorybook />,
	IconBrandStorytel: <IconBrandStorytel />,
	IconBrandStrava: <IconBrandStrava />,
	IconBrandStripe: <IconBrandStripe />,
	IconBrandSublimeText: <IconBrandSublimeText />,
	IconBrandSugarizer: <IconBrandSugarizer />,
	IconBrandSupabase: <IconBrandSupabase />,
	IconBrandSuperhuman: <IconBrandSuperhuman />,
	IconBrandSupernova: <IconBrandSupernova />,
	IconBrandSurfshark: <IconBrandSurfshark />,
	IconBrandSvelte: <IconBrandSvelte />,
	IconBrandSymfony: <IconBrandSymfony />,
	IconBrandTabler: <IconBrandTabler />,
	IconBrandTailwind: <IconBrandTailwind />,
	IconBrandTaobao: <IconBrandTaobao />,
	IconBrandTed: <IconBrandTed />,
	IconBrandTelegram: <IconBrandTelegram />,
	IconBrandTether: <IconBrandTether />,
	IconBrandThreejs: <IconBrandThreejs />,
	IconBrandTidal: <IconBrandTidal />,
	IconBrandTiktok: <IconBrandTiktok />,
	IconBrandTinder: <IconBrandTinder />,
	IconBrandTopbuzz: <IconBrandTopbuzz />,
	IconBrandTorchain: <IconBrandTorchain />,
	IconBrandToyota: <IconBrandToyota />,
	IconBrandTrello: <IconBrandTrello />,
	IconBrandTripadvisor: <IconBrandTripadvisor />,
	IconBrandTumblr: <IconBrandTumblr />,
	IconBrandTwilio: <IconBrandTwilio />,
	IconBrandTwitch: <IconBrandTwitch />,
	IconBrandTwitter: <IconBrandTwitter />,
	IconBrandTypescript: <IconBrandTypescript />,
	IconBrandUber: <IconBrandUber />,
	IconBrandUbuntu: <IconBrandUbuntu />,
	IconBrandUnity: <IconBrandUnity />,
	IconBrandUnsplash: <IconBrandUnsplash />,
	IconBrandUpwork: <IconBrandUpwork />,
	IconBrandValorant: <IconBrandValorant />,
	IconBrandVercel: <IconBrandVercel />,
	IconBrandVimeo: <IconBrandVimeo />,
	IconBrandVinted: <IconBrandVinted />,
	IconBrandVisa: <IconBrandVisa />,
	IconBrandVisualStudio: <IconBrandVisualStudio />,
	IconBrandVite: <IconBrandVite />,
	IconBrandVivaldi: <IconBrandVivaldi />,
	IconBrandVk: <IconBrandVk />,
	IconBrandVolkswagen: <IconBrandVolkswagen />,
	IconBrandVsco: <IconBrandVsco />,
	IconBrandVscode: <IconBrandVscode />,
	IconBrandVue: <IconBrandVue />,
	IconBrandWalmart: <IconBrandWalmart />,
	IconBrandWaze: <IconBrandWaze />,
	IconBrandWebflow: <IconBrandWebflow />,
	IconBrandWechat: <IconBrandWechat />,
	IconBrandWeibo: <IconBrandWeibo />,
	IconBrandWhatsapp: <IconBrandWhatsapp />,
	IconBrandWindows: <IconBrandWindows />,
	IconBrandWindy: <IconBrandWindy />,
	IconBrandWish: <IconBrandWish />,
	IconBrandWix: <IconBrandWix />,
	IconBrandWordpress: <IconBrandWordpress />,
	IconBrandXbox: <IconBrandXbox />,
	IconBrandXing: <IconBrandXing />,
	IconBrandYahoo: <IconBrandYahoo />,
	IconBrandYatse: <IconBrandYatse />,
	IconBrandYcombinator: <IconBrandYcombinator />,
	IconBrandYoutubeKids: <IconBrandYoutubeKids />,
	IconBrandYoutube: <IconBrandYoutube />,
	IconBrandZalando: <IconBrandZalando />,
	IconBrandZapier: <IconBrandZapier />,
	IconBrandZeit: <IconBrandZeit />,
	IconBrandZhihu: <IconBrandZhihu />,
	IconBrandZoom: <IconBrandZoom />,
	IconBrandZulip: <IconBrandZulip />,
	IconBrandZwift: <IconBrandZwift />,
	IconBreadOff: <IconBreadOff />,
	IconBread: <IconBread />,
	IconBriefcaseOff: <IconBriefcaseOff />,
	IconBriefcase: <IconBriefcase />,
	IconBrightness2: <IconBrightness2 />,
	IconBrightnessDown: <IconBrightnessDown />,
	IconBrightnessHalf: <IconBrightnessHalf />,
	IconBrightnessOff: <IconBrightnessOff />,
	IconBrightnessUp: <IconBrightnessUp />,
	IconBrightness: <IconBrightness />,
	IconBroadcastOff: <IconBroadcastOff />,
	IconBroadcast: <IconBroadcast />,
	IconBrowserCheck: <IconBrowserCheck />,
	IconBrowserOff: <IconBrowserOff />,
	IconBrowserPlus: <IconBrowserPlus />,
	IconBrowserX: <IconBrowserX />,
	IconBrowser: <IconBrowser />,
	IconBrushOff: <IconBrushOff />,
	IconBrush: <IconBrush />,
	IconBucketDroplet: <IconBucketDroplet />,
	IconBucketOff: <IconBucketOff />,
	IconBucket: <IconBucket />,
	IconBugOff: <IconBugOff />,
	IconBug: <IconBug />,
	IconBuildingArch: <IconBuildingArch />,
	IconBuildingBank: <IconBuildingBank />,
	IconBuildingBridge2: <IconBuildingBridge2 />,
	IconBuildingBridge: <IconBuildingBridge />,
	IconBuildingBroadcastTower: <IconBuildingBroadcastTower />,
	IconBuildingCarousel: <IconBuildingCarousel />,
	IconBuildingCastle: <IconBuildingCastle />,
	IconBuildingChurch: <IconBuildingChurch />,
	IconBuildingCircus: <IconBuildingCircus />,
	IconBuildingCommunity: <IconBuildingCommunity />,
	IconBuildingCottage: <IconBuildingCottage />,
	IconBuildingEstate: <IconBuildingEstate />,
	IconBuildingFactory2: <IconBuildingFactory2 />,
	IconBuildingFactory: <IconBuildingFactory />,
	IconBuildingFortress: <IconBuildingFortress />,
	IconBuildingHospital: <IconBuildingHospital />,
	IconBuildingLighthouse: <IconBuildingLighthouse />,
	IconBuildingMonument: <IconBuildingMonument />,
	IconBuildingPavilion: <IconBuildingPavilion />,
	IconBuildingSkyscraper: <IconBuildingSkyscraper />,
	IconBuildingStadium: <IconBuildingStadium />,
	IconBuildingStore: <IconBuildingStore />,
	IconBuildingTunnel: <IconBuildingTunnel />,
	IconBuildingWarehouse: <IconBuildingWarehouse />,
	IconBuildingWindTurbine: <IconBuildingWindTurbine />,
	IconBuilding: <IconBuilding />,
	IconBulbFilled: <IconBulbFilled />,
	IconBulbOff: <IconBulbOff />,
	IconBulb: <IconBulb />,
	IconBulldozer: <IconBulldozer />,
	IconBusOff: <IconBusOff />,
	IconBusStop: <IconBusStop />,
	IconBus: <IconBus />,
	IconBusinessplan: <IconBusinessplan />,
	IconButterfly: <IconButterfly />,
	IconCactusOff: <IconCactusOff />,
	IconCactus: <IconCactus />,
	IconCakeOff: <IconCakeOff />,
	IconCake: <IconCake />,
	IconCalculatorOff: <IconCalculatorOff />,
	IconCalculator: <IconCalculator />,
	IconCalendarDue: <IconCalendarDue />,
	IconCalendarEvent: <IconCalendarEvent />,
	IconCalendarMinus: <IconCalendarMinus />,
	IconCalendarOff: <IconCalendarOff />,
	IconCalendarPlus: <IconCalendarPlus />,
	IconCalendarStats: <IconCalendarStats />,
	IconCalendarTime: <IconCalendarTime />,
	IconCalendar: <IconCalendar />,
	IconCameraMinus: <IconCameraMinus />,
	IconCameraOff: <IconCameraOff />,
	IconCameraPlus: <IconCameraPlus />,
	IconCameraRotate: <IconCameraRotate />,
	IconCameraSelfie: <IconCameraSelfie />,
	IconCamera: <IconCamera />,
	IconCampfire: <IconCampfire />,
	IconCandle: <IconCandle />,
	IconCandyOff: <IconCandyOff />,
	IconCandy: <IconCandy />,
	IconCane: <IconCane />,
	IconCannabis: <IconCannabis />,
	IconCaptureOff: <IconCaptureOff />,
	IconCapture: <IconCapture />,
	IconCarCrane: <IconCarCrane />,
	IconCarCrash: <IconCarCrash />,
	IconCarOff: <IconCarOff />,
	IconCarTurbine: <IconCarTurbine />,
	IconCar: <IconCar />,
	IconCaravan: <IconCaravan />,
	IconCardboardsOff: <IconCardboardsOff />,
	IconCardboards: <IconCardboards />,
	IconCards: <IconCards />,
	IconCaretDown: <IconCaretDown />,
	IconCaretLeft: <IconCaretLeft />,
	IconCaretRight: <IconCaretRight />,
	IconCaretUp: <IconCaretUp />,
	IconCarouselHorizontal: <IconCarouselHorizontal />,
	IconCarouselVertical: <IconCarouselVertical />,
	IconCarrotOff: <IconCarrotOff />,
	IconCarrot: <IconCarrot />,
	IconCashBanknoteOff: <IconCashBanknoteOff />,
	IconCashBanknote: <IconCashBanknote />,
	IconCashOff: <IconCashOff />,
	IconCash: <IconCash />,
	IconCastOff: <IconCastOff />,
	IconCast: <IconCast />,
	IconCat: <IconCat />,
	IconCategory2: <IconCategory2 />,
	IconCategory: <IconCategory />,
	IconCeOff: <IconCeOff />,
	IconCe: <IconCe />,
	IconCellSignal1: <IconCellSignal1 />,
	IconCellSignal2: <IconCellSignal2 />,
	IconCellSignal3: <IconCellSignal3 />,
	IconCellSignal4: <IconCellSignal4 />,
	IconCellSignal5: <IconCellSignal5 />,
	IconCellSignalOff: <IconCellSignalOff />,
	IconCell: <IconCell />,
	IconCertificate2Off: <IconCertificate2Off />,
	IconCertificate2: <IconCertificate2 />,
	IconCertificateOff: <IconCertificateOff />,
	IconCertificate: <IconCertificate />,
	IconChairDirector: <IconChairDirector />,
	IconChalkboardOff: <IconChalkboardOff />,
	IconChalkboard: <IconChalkboard />,
	IconChargingPile: <IconChargingPile />,
	IconChartArcs3: <IconChartArcs3 />,
	IconChartArcs: <IconChartArcs />,
	IconChartAreaFilled: <IconChartAreaFilled />,
	IconChartAreaLineFilled: <IconChartAreaLineFilled />,
	IconChartAreaLine: <IconChartAreaLine />,
	IconChartArea: <IconChartArea />,
	IconChartArrowsVertical: <IconChartArrowsVertical />,
	IconChartArrows: <IconChartArrows />,
	IconChartBarOff: <IconChartBarOff />,
	IconChartBar: <IconChartBar />,
	IconChartBubbleFilled: <IconChartBubbleFilled />,
	IconChartBubble: <IconChartBubble />,
	IconChartCandleFilled: <IconChartCandleFilled />,
	IconChartCandle: <IconChartCandle />,
	IconChartCircles: <IconChartCircles />,
	IconChartDonut2: <IconChartDonut2 />,
	IconChartDonut3: <IconChartDonut3 />,
	IconChartDonut4: <IconChartDonut4 />,
	IconChartDonutFilled: <IconChartDonutFilled />,
	IconChartDonut: <IconChartDonut />,
	IconChartDots2: <IconChartDots2 />,
	IconChartDots3: <IconChartDots3 />,
	IconChartDots: <IconChartDots />,
	IconChartGridDots: <IconChartGridDots />,
	IconChartHistogram: <IconChartHistogram />,
	IconChartInfographic: <IconChartInfographic />,
	IconChartLine: <IconChartLine />,
	IconChartPie2: <IconChartPie2 />,
	IconChartPie3: <IconChartPie3 />,
	IconChartPie4: <IconChartPie4 />,
	IconChartPieFilled: <IconChartPieFilled />,
	IconChartPieOff: <IconChartPieOff />,
	IconChartPie: <IconChartPie />,
	IconChartPpf: <IconChartPpf />,
	IconChartRadar: <IconChartRadar />,
	IconChartSankey: <IconChartSankey />,
	IconChartTreemap: <IconChartTreemap />,
	IconCheck: <IconCheck />,
	IconCheckbox: <IconCheckbox />,
	IconChecklist: <IconChecklist />,
	IconChecks: <IconChecks />,
	IconCheckupList: <IconCheckupList />,
	IconCheese: <IconCheese />,
	IconChefHatOff: <IconChefHatOff />,
	IconChefHat: <IconChefHat />,
	IconCherryFilled: <IconCherryFilled />,
	IconCherry: <IconCherry />,
	IconChessBishopFilled: <IconChessBishopFilled />,
	IconChessBishop: <IconChessBishop />,
	IconChessFilled: <IconChessFilled />,
	IconChessKingFilled: <IconChessKingFilled />,
	IconChessKing: <IconChessKing />,
	IconChessKnightFilled: <IconChessKnightFilled />,
	IconChessKnight: <IconChessKnight />,
	IconChessQueenFilled: <IconChessQueenFilled />,
	IconChessQueen: <IconChessQueen />,
	IconChessRookFilled: <IconChessRookFilled />,
	IconChessRook: <IconChessRook />,
	IconChess: <IconChess />,
	IconChevronDownLeft: <IconChevronDownLeft />,
	IconChevronDownRight: <IconChevronDownRight />,
	IconChevronDown: <IconChevronDown />,
	IconChevronLeft: <IconChevronLeft />,
	IconChevronRight: <IconChevronRight />,
	IconChevronUpLeft: <IconChevronUpLeft />,
	IconChevronUpRight: <IconChevronUpRight />,
	IconChevronUp: <IconChevronUp />,
	IconChevronsDownLeft: <IconChevronsDownLeft />,
	IconChevronsDownRight: <IconChevronsDownRight />,
	IconChevronsDown: <IconChevronsDown />,
	IconChevronsLeft: <IconChevronsLeft />,
	IconChevronsRight: <IconChevronsRight />,
	IconChevronsUpLeft: <IconChevronsUpLeft />,
	IconChevronsUpRight: <IconChevronsUpRight />,
	IconChevronsUp: <IconChevronsUp />,
	IconChisel: <IconChisel />,
	IconChristmasTreeOff: <IconChristmasTreeOff />,
	IconChristmasTree: <IconChristmasTree />,
	IconCircle0Filled: <IconCircle0Filled />,
	IconCircle1Filled: <IconCircle1Filled />,
	IconCircle2Filled: <IconCircle2Filled />,
	IconCircle3Filled: <IconCircle3Filled />,
	IconCircle4Filled: <IconCircle4Filled />,
	IconCircle5Filled: <IconCircle5Filled />,
	IconCircle6Filled: <IconCircle6Filled />,
	IconCircle7Filled: <IconCircle7Filled />,
	IconCircle8Filled: <IconCircle8Filled />,
	IconCircle9Filled: <IconCircle9Filled />,
	IconCircleArrowDownFilled: <IconCircleArrowDownFilled />,
	IconCircleArrowDownLeftFilled: <IconCircleArrowDownLeftFilled />,
	IconCircleArrowDownLeft: <IconCircleArrowDownLeft />,
	IconCircleArrowDownRightFilled: <IconCircleArrowDownRightFilled />,
	IconCircleArrowDownRight: <IconCircleArrowDownRight />,
	IconCircleArrowDown: <IconCircleArrowDown />,
	IconCircleArrowLeftFilled: <IconCircleArrowLeftFilled />,
	IconCircleArrowLeft: <IconCircleArrowLeft />,
	IconCircleArrowRightFilled: <IconCircleArrowRightFilled />,
	IconCircleArrowRight: <IconCircleArrowRight />,
	IconCircleArrowUpFilled: <IconCircleArrowUpFilled />,
	IconCircleArrowUpLeftFilled: <IconCircleArrowUpLeftFilled />,
	IconCircleArrowUpLeft: <IconCircleArrowUpLeft />,
	IconCircleArrowUpRightFilled: <IconCircleArrowUpRightFilled />,
	IconCircleArrowUpRight: <IconCircleArrowUpRight />,
	IconCircleArrowUp: <IconCircleArrowUp />,
	IconCircleCaretDown: <IconCircleCaretDown />,
	IconCircleCaretLeft: <IconCircleCaretLeft />,
	IconCircleCaretRight: <IconCircleCaretRight />,
	IconCircleCaretUp: <IconCircleCaretUp />,
	IconCircleCheckFilled: <IconCircleCheckFilled />,
	IconCircleCheck: <IconCircleCheck />,
	IconCircleChevronDown: <IconCircleChevronDown />,
	IconCircleChevronLeft: <IconCircleChevronLeft />,
	IconCircleChevronRight: <IconCircleChevronRight />,
	IconCircleChevronUp: <IconCircleChevronUp />,
	IconCircleChevronsDown: <IconCircleChevronsDown />,
	IconCircleChevronsLeft: <IconCircleChevronsLeft />,
	IconCircleChevronsRight: <IconCircleChevronsRight />,
	IconCircleChevronsUp: <IconCircleChevronsUp />,
	IconCircleDashed: <IconCircleDashed />,
	IconCircleDotFilled: <IconCircleDotFilled />,
	IconCircleDot: <IconCircleDot />,
	IconCircleDotted: <IconCircleDotted />,
	IconCircleFilled: <IconCircleFilled />,
	IconCircleHalf2: <IconCircleHalf2 />,
	IconCircleHalfVertical: <IconCircleHalfVertical />,
	IconCircleHalf: <IconCircleHalf />,
	IconCircleKeyFilled: <IconCircleKeyFilled />,
	IconCircleKey: <IconCircleKey />,
	IconCircleLetterA: <IconCircleLetterA />,
	IconCircleLetterB: <IconCircleLetterB />,
	IconCircleLetterC: <IconCircleLetterC />,
	IconCircleLetterD: <IconCircleLetterD />,
	IconCircleLetterE: <IconCircleLetterE />,
	IconCircleLetterF: <IconCircleLetterF />,
	IconCircleLetterG: <IconCircleLetterG />,
	IconCircleLetterH: <IconCircleLetterH />,
	IconCircleLetterI: <IconCircleLetterI />,
	IconCircleLetterJ: <IconCircleLetterJ />,
	IconCircleLetterK: <IconCircleLetterK />,
	IconCircleLetterL: <IconCircleLetterL />,
	IconCircleLetterM: <IconCircleLetterM />,
	IconCircleLetterN: <IconCircleLetterN />,
	IconCircleLetterO: <IconCircleLetterO />,
	IconCircleLetterP: <IconCircleLetterP />,
	IconCircleLetterQ: <IconCircleLetterQ />,
	IconCircleLetterR: <IconCircleLetterR />,
	IconCircleLetterS: <IconCircleLetterS />,
	IconCircleLetterT: <IconCircleLetterT />,
	IconCircleLetterU: <IconCircleLetterU />,
	IconCircleLetterV: <IconCircleLetterV />,
	IconCircleLetterW: <IconCircleLetterW />,
	IconCircleLetterX: <IconCircleLetterX />,
	IconCircleLetterY: <IconCircleLetterY />,
	IconCircleLetterZ: <IconCircleLetterZ />,
	IconCircleMinus: <AiOutlineMinusCircle />, // <IconCircleMinus />,
	IconCircleNumber0: <IconCircleNumber0 />,
	IconCircleNumber1: <IconCircleNumber1 />,
	IconCircleNumber2: <IconCircleNumber2 />,
	IconCircleNumber3: <IconCircleNumber3 />,
	IconCircleNumber4: <IconCircleNumber4 />,
	IconCircleNumber5: <IconCircleNumber5 />,
	IconCircleNumber6: <IconCircleNumber6 />,
	IconCircleNumber7: <IconCircleNumber7 />,
	IconCircleNumber8: <IconCircleNumber8 />,
	IconCircleNumber9: <IconCircleNumber9 />,
	IconCircleOff: <IconCircleOff />,
	IconCirclePlus: <AiOutlinePlusCircle />, //<IconCirclePlus />,
	IconCircleRectangleOff: <IconCircleRectangleOff />,
	IconCircleRectangle: <IconCircleRectangle />,
	IconCircleSquare: <IconCircleSquare />,
	IconCircleTriangle: <IconCircleTriangle />,
	IconCircleXFilled: <IconCircleXFilled />,
	IconCircleX: <IconCircleX />,
	IconCircle: <IconCircle />,
	IconCirclesFilled: <IconCirclesFilled />,
	IconCirclesRelation: <IconCirclesRelation />,
	IconCircles: <IconCircles />,
	IconCircuitAmmeter: <IconCircuitAmmeter />,
	IconCircuitBattery: <IconCircuitBattery />,
	IconCircuitBulb: <IconCircuitBulb />,
	IconCircuitCapacitorPolarized: <IconCircuitCapacitorPolarized />,
	IconCircuitCapacitor: <IconCircuitCapacitor />,
	IconCircuitCellPlus: <IconCircuitCellPlus />,
	IconCircuitCell: <IconCircuitCell />,
	IconCircuitChangeover: <IconCircuitChangeover />,
	IconCircuitDiodeZener: <IconCircuitDiodeZener />,
	IconCircuitDiode: <IconCircuitDiode />,
	IconCircuitGroundDigital: <IconCircuitGroundDigital />,
	IconCircuitGround: <IconCircuitGround />,
	IconCircuitInductor: <IconCircuitInductor />,
	IconCircuitMotor: <IconCircuitMotor />,
	IconCircuitPushbutton: <IconCircuitPushbutton />,
	IconCircuitResistor: <IconCircuitResistor />,
	IconCircuitSwitchClosed: <IconCircuitSwitchClosed />,
	IconCircuitSwitchOpen: <IconCircuitSwitchOpen />,
	IconCircuitVoltmeter: <IconCircuitVoltmeter />,
	IconClearAll: <IconClearAll />,
	IconClearFormatting: <IconClearFormatting />,
	IconClick: <IconClick />,
	IconClipboardCheck: <IconClipboardCheck />,
	IconClipboardCopy: <IconClipboardCopy />,
	IconClipboardData: <IconClipboardData />,
	IconClipboardHeart: <IconClipboardHeart />,
	IconClipboardList: <IconClipboardList />,
	IconClipboardOff: <IconClipboardOff />,
	IconClipboardPlus: <IconClipboardPlus />,
	IconClipboardText: <IconClipboardText />,
	IconClipboardTypography: <IconClipboardTypography />,
	IconClipboardX: <IconClipboardX />,
	IconClipboard: <IconClipboard />,
	IconClock2: <IconClock2 />,
	IconClockCancel: <IconClockCancel />,
	IconClockEdit: <IconClockEdit />,
	IconClockFilled: <IconClockFilled />,
	IconClockHour1: <IconClockHour1 />,
	IconClockHour10: <IconClockHour10 />,
	IconClockHour11: <IconClockHour11 />,
	IconClockHour12: <IconClockHour12 />,
	IconClockHour2: <IconClockHour2 />,
	IconClockHour3: <IconClockHour3 />,
	IconClockHour4: <IconClockHour4 />,
	IconClockHour5: <IconClockHour5 />,
	IconClockHour6: <IconClockHour6 />,
	IconClockHour7: <IconClockHour7 />,
	IconClockHour8: <IconClockHour8 />,
	IconClockHour9: <IconClockHour9 />,
	IconClockOff: <IconClockOff />,
	IconClockPause: <IconClockPause />,
	IconClockPlay: <IconClockPlay />,
	IconClockRecord: <IconClockRecord />,
	IconClockStop: <IconClockStop />,
	IconClock: <IconClock />,
	IconClothesRackOff: <IconClothesRackOff />,
	IconClothesRack: <IconClothesRack />,
	IconCloudComputing: <IconCloudComputing />,
	IconCloudDataConnection: <IconCloudDataConnection />,
	IconCloudDownload: <IconCloudDownload />,
	IconCloudFilled: <IconCloudFilled />,
	IconCloudFog: <IconCloudFog />,
	IconCloudLockOpen: <IconCloudLockOpen />,
	IconCloudLock: <IconCloudLock />,
	IconCloudOff: <IconCloudOff />,
	IconCloudRain: <IconCloudRain />,
	IconCloudSnow: <IconCloudSnow />,
	IconCloudStorm: <IconCloudStorm />,
	IconCloudUpload: <IconCloudUpload />,
	IconCloud: <IconCloud />,
	IconClover2: <IconClover2 />,
	IconClover: <IconClover />,
	IconClubsFilled: <IconClubsFilled />,
	IconClubs: <IconClubs />,
	IconCodeAsterix: <IconCodeAsterix />,
	IconCodeCircle2: <IconCodeCircle2 />,
	IconCodeCircle: <IconCodeCircle />,
	IconCodeDots: <IconCodeDots />,
	IconCodeMinus: <IconCodeMinus />,
	IconCodeOff: <IconCodeOff />,
	IconCodePlus: <IconCodePlus />,
	IconCode: <IconCode />,
	IconCoffeeOff: <IconCoffeeOff />,
	IconCoffee: <IconCoffee />,
	IconCoffin: <IconCoffin />,
	IconCoinBitcoin: <IconCoinBitcoin />,
	IconCoinEuro: <IconCoinEuro />,
	IconCoinMonero: <IconCoinMonero />,
	IconCoinOff: <IconCoinOff />,
	IconCoinPound: <IconCoinPound />,
	IconCoinRupee: <IconCoinRupee />,
	IconCoinYen: <IconCoinYen />,
	IconCoinYuan: <IconCoinYuan />,
	IconCoin: <IconCoin />,
	IconCoins: <IconCoins />,
	IconColorFilter: <IconColorFilter />,
	IconColorPickerOff: <IconColorPickerOff />,
	IconColorPicker: <IconColorPicker />,
	IconColorSwatchOff: <IconColorSwatchOff />,
	IconColorSwatch: <IconColorSwatch />,
	IconColumnInsertLeft: <IconColumnInsertLeft />,
	IconColumnInsertRight: <IconColumnInsertRight />,
	IconColumns1: <IconColumns1 />,
	IconColumns2: <IconColumns2 />,
	IconColumns3: <IconColumns3 />,
	IconColumnsOff: <IconColumnsOff />,
	IconColumns: <IconColumns />,
	IconComet: <IconComet />,
	IconCommandOff: <IconCommandOff />,
	IconCommand: <IconCommand />,
	IconCompassOff: <IconCompassOff />,
	IconCompass: <IconCompass />,
	IconComponentsOff: <IconComponentsOff />,
	IconComponents: <IconComponents />,
	IconCone2: <IconCone2 />,
	IconConeOff: <IconConeOff />,
	IconCone: <IconCone />,
	IconConfettiOff: <IconConfettiOff />,
	IconConfetti: <IconConfetti />,
	IconConfucius: <IconConfucius />,
	IconContainerOff: <IconContainerOff />,
	IconContainer: <IconContainer />,
	IconContrast2Off: <IconContrast2Off />,
	IconContrast2: <IconContrast2 />,
	IconContrastOff: <IconContrastOff />,
	IconContrast: <IconContrast />,
	IconCooker: <IconCooker />,
	IconCookieMan: <IconCookieMan />,
	IconCookieOff: <IconCookieOff />,
	IconCookie: <IconCookie />,
	IconCopyOff: <IconCopyOff />,
	IconCopy: <IconCopy />,
	IconCopyleftFilled: <IconCopyleftFilled />,
	IconCopyleftOff: <IconCopyleftOff />,
	IconCopyleft: <IconCopyleft />,
	IconCopyrightFilled: <IconCopyrightFilled />,
	IconCopyrightOff: <IconCopyrightOff />,
	IconCopyright: <IconCopyright />,
	IconCornerDownLeftDouble: <IconCornerDownLeftDouble />,
	IconCornerDownLeft: <IconCornerDownLeft />,
	IconCornerDownRightDouble: <IconCornerDownRightDouble />,
	IconCornerDownRight: <IconCornerDownRight />,
	IconCornerLeftDownDouble: <IconCornerLeftDownDouble />,
	IconCornerLeftDown: <IconCornerLeftDown />,
	IconCornerLeftUpDouble: <IconCornerLeftUpDouble />,
	IconCornerLeftUp: <IconCornerLeftUp />,
	IconCornerRightDownDouble: <IconCornerRightDownDouble />,
	IconCornerRightDown: <IconCornerRightDown />,
	IconCornerRightUpDouble: <IconCornerRightUpDouble />,
	IconCornerRightUp: <IconCornerRightUp />,
	IconCornerUpLeftDouble: <IconCornerUpLeftDouble />,
	IconCornerUpLeft: <IconCornerUpLeft />,
	IconCornerUpRightDouble: <IconCornerUpRightDouble />,
	IconCornerUpRight: <IconCornerUpRight />,
	IconCpu2: <IconCpu2 />,
	IconCpuOff: <IconCpuOff />,
	IconCpu: <IconCpu />,
	IconCraneOff: <IconCraneOff />,
	IconCrane: <IconCrane />,
	IconCreativeCommonsBy: <IconCreativeCommonsBy />,
	IconCreativeCommonsNc: <IconCreativeCommonsNc />,
	IconCreativeCommonsNd: <IconCreativeCommonsNd />,
	IconCreativeCommonsOff: <IconCreativeCommonsOff />,
	IconCreativeCommonsSa: <IconCreativeCommonsSa />,
	IconCreativeCommonsZero: <IconCreativeCommonsZero />,
	IconCreativeCommons: <IconCreativeCommons />,
	IconCreditCardOff: <IconCreditCardOff />,
	IconCreditCard: <IconCreditCard />,
	IconCricket: <IconCricket />,
	IconCrop: <IconCrop />,
	IconCrossFilled: <IconCrossFilled />,
	IconCrossOff: <IconCrossOff />,
	IconCross: <IconCross />,
	IconCrosshair: <IconCrosshair />,
	IconCrownOff: <IconCrownOff />,
	IconCrown: <IconCrown />,
	IconCrutchesOff: <IconCrutchesOff />,
	IconCrutches: <IconCrutches />,
	IconCrystalBall: <IconCrystalBall />,
	IconCsv: <IconCsv />,
	IconCubeSend: <IconCubeSend />,
	IconCubeUnfolded: <IconCubeUnfolded />,
	IconCupOff: <IconCupOff />,
	IconCup: <IconCup />,
	IconCurling: <IconCurling />,
	IconCurlyLoop: <IconCurlyLoop />,
	IconCurrencyAfghani: <IconCurrencyAfghani />,
	IconCurrencyBahraini: <IconCurrencyBahraini />,
	IconCurrencyBaht: <IconCurrencyBaht />,
	IconCurrencyBitcoin: <IconCurrencyBitcoin />,
	IconCurrencyCent: <IconCurrencyCent />,
	IconCurrencyDinar: <IconCurrencyDinar />,
	IconCurrencyDirham: <IconCurrencyDirham />,
	IconCurrencyDogecoin: <IconCurrencyDogecoin />,
	IconCurrencyDollarAustralian: <IconCurrencyDollarAustralian />,
	IconCurrencyDollarBrunei: <IconCurrencyDollarBrunei />,
	IconCurrencyDollarCanadian: <IconCurrencyDollarCanadian />,
	IconCurrencyDollarGuyanese: <IconCurrencyDollarGuyanese />,
	IconCurrencyDollarOff: <IconCurrencyDollarOff />,
	IconCurrencyDollarSingapore: <IconCurrencyDollarSingapore />,
	IconCurrencyDollarZimbabwean: <IconCurrencyDollarZimbabwean />,
	IconCurrencyDollar: <IconCurrencyDollar />,
	IconCurrencyDong: <IconCurrencyDong />,
	IconCurrencyDram: <IconCurrencyDram />,
	IconCurrencyEthereum: <IconCurrencyEthereum />,
	IconCurrencyEuroOff: <IconCurrencyEuroOff />,
	IconCurrencyEuro: <IconCurrencyEuro />,
	IconCurrencyForint: <IconCurrencyForint />,
	IconCurrencyFrank: <IconCurrencyFrank />,
	IconCurrencyGuarani: <IconCurrencyGuarani />,
	IconCurrencyHryvnia: <IconCurrencyHryvnia />,
	IconCurrencyKip: <IconCurrencyKip />,
	IconCurrencyKroneCzech: <IconCurrencyKroneCzech />,
	IconCurrencyKroneDanish: <IconCurrencyKroneDanish />,
	IconCurrencyKroneSwedish: <IconCurrencyKroneSwedish />,
	IconCurrencyLari: <IconCurrencyLari />,
	IconCurrencyLeu: <IconCurrencyLeu />,
	IconCurrencyLira: <IconCurrencyLira />,
	IconCurrencyLitecoin: <IconCurrencyLitecoin />,
	IconCurrencyLyd: <IconCurrencyLyd />,
	IconCurrencyManat: <IconCurrencyManat />,
	IconCurrencyMonero: <IconCurrencyMonero />,
	IconCurrencyNaira: <IconCurrencyNaira />,
	IconCurrencyNano: <IconCurrencyNano />,
	IconCurrencyOff: <IconCurrencyOff />,
	IconCurrencyPaanga: <IconCurrencyPaanga />,
	IconCurrencyPeso: <IconCurrencyPeso />,
	IconCurrencyPoundOff: <IconCurrencyPoundOff />,
	IconCurrencyPound: <IconCurrencyPound />,
	IconCurrencyQuetzal: <IconCurrencyQuetzal />,
	IconCurrencyReal: <IconCurrencyReal />,
	IconCurrencyRenminbi: <IconCurrencyRenminbi />,
	IconCurrencyRipple: <IconCurrencyRipple />,
	IconCurrencyRiyal: <IconCurrencyRiyal />,
	IconCurrencyRubel: <IconCurrencyRubel />,
	IconCurrencyRufiyaa: <IconCurrencyRufiyaa />,
	IconCurrencyRupeeNepalese: <IconCurrencyRupeeNepalese />,
	IconCurrencyRupee: <IconCurrencyRupee />,
	IconCurrencyShekel: <IconCurrencyShekel />,
	IconCurrencySolana: <IconCurrencySolana />,
	IconCurrencySom: <IconCurrencySom />,
	IconCurrencyTaka: <IconCurrencyTaka />,
	IconCurrencyTenge: <IconCurrencyTenge />,
	IconCurrencyTugrik: <IconCurrencyTugrik />,
	IconCurrencyWon: <IconCurrencyWon />,
	IconCurrencyYenOff: <IconCurrencyYenOff />,
	IconCurrencyYen: <IconCurrencyYen />,
	IconCurrencyYuan: <IconCurrencyYuan />,
	IconCurrencyZloty: <IconCurrencyZloty />,
	IconCurrency: <IconCurrency />,
	IconCurrentLocationOff: <IconCurrentLocationOff />,
	IconCurrentLocation: <IconCurrentLocation />,
	IconCursorOff: <IconCursorOff />,
	IconCursorText: <IconCursorText />,
	IconCut: <IconCut />,
	IconCylinder: <IconCylinder />,
	IconDashboardOff: <IconDashboardOff />,
	IconDashboard: <IconDashboard />,
	IconDatabaseExport: <IconDatabaseExport />,
	IconDatabaseImport: <IconDatabaseImport />,
	IconDatabaseOff: <IconDatabaseOff />,
	IconDatabase: <IconDatabase />,
	IconDeer: <IconDeer />,
	IconDelta: <IconDelta />,
	IconDentalBroken: <IconDentalBroken />,
	IconDentalOff: <IconDentalOff />,
	IconDental: <IconDental />,
	IconDetailsOff: <IconDetailsOff />,
	IconDetails: <IconDetails />,
	IconDeviceAirpodsCase: <IconDeviceAirpodsCase />,
	IconDeviceAirpods: <IconDeviceAirpods />,
	IconDeviceAnalytics: <IconDeviceAnalytics />,
	IconDeviceAudioTape: <IconDeviceAudioTape />,
	IconDeviceCameraPhone: <IconDeviceCameraPhone />,
	IconDeviceCctvOff: <IconDeviceCctvOff />,
	IconDeviceCctv: <IconDeviceCctv />,
	IconDeviceComputerCameraOff: <IconDeviceComputerCameraOff />,
	IconDeviceComputerCamera: <IconDeviceComputerCamera />,
	IconDeviceDesktopAnalytics: <IconDeviceDesktopAnalytics />,
	IconDeviceDesktopOff: <IconDeviceDesktopOff />,
	IconDeviceDesktop: <IconDeviceDesktop />,
	IconDeviceFloppy: <IconDeviceFloppy />,
	IconDeviceGamepad2: <IconDeviceGamepad2 />,
	IconDeviceGamepad: <IconDeviceGamepad />,
	IconDeviceHeartMonitor: <IconDeviceHeartMonitor />,
	IconDeviceImac: <IconDeviceImac />,
	IconDeviceIpadHorizontal: <IconDeviceIpadHorizontal />,
	IconDeviceIpad: <IconDeviceIpad />,
	IconDeviceLandlinePhone: <IconDeviceLandlinePhone />,
	IconDeviceLaptopOff: <IconDeviceLaptopOff />,
	IconDeviceLaptop: <IconDeviceLaptop />,
	IconDeviceMobileCharging: <IconDeviceMobileCharging />,
	IconDeviceMobileMessage: <IconDeviceMobileMessage />,
	IconDeviceMobileOff: <IconDeviceMobileOff />,
	IconDeviceMobileRotated: <IconDeviceMobileRotated />,
	IconDeviceMobileVibration: <IconDeviceMobileVibration />,
	IconDeviceMobile: <IconDeviceMobile />,
	IconDeviceNintendoOff: <IconDeviceNintendoOff />,
	IconDeviceNintendo: <IconDeviceNintendo />,
	IconDeviceRemote: <IconDeviceRemote />,
	IconDeviceSdCard: <IconDeviceSdCard />,
	IconDeviceSim1: <IconDeviceSim1 />,
	IconDeviceSim2: <IconDeviceSim2 />,
	IconDeviceSim3: <IconDeviceSim3 />,
	IconDeviceSim: <IconDeviceSim />,
	IconDeviceSpeakerOff: <IconDeviceSpeakerOff />,
	IconDeviceSpeaker: <IconDeviceSpeaker />,
	IconDeviceTabletOff: <IconDeviceTabletOff />,
	IconDeviceTablet: <IconDeviceTablet />,
	IconDeviceTvOff: <IconDeviceTvOff />,
	IconDeviceTvOld: <IconDeviceTvOld />,
	IconDeviceTv: <IconDeviceTv />,
	IconDeviceWatchOff: <IconDeviceWatchOff />,
	IconDeviceWatchStats2: <IconDeviceWatchStats2 />,
	IconDeviceWatchStats: <IconDeviceWatchStats />,
	IconDeviceWatch: <IconDeviceWatch />,
	IconDevices2: <IconDevices2 />,
	IconDevicesOff: <IconDevicesOff />,
	IconDevicesPcOff: <IconDevicesPcOff />,
	IconDevicesPc: <IconDevicesPc />,
	IconDevices: <IconDevices />,
	IconDialpadOff: <IconDialpadOff />,
	IconDialpad: <IconDialpad />,
	IconDiamondFilled: <IconDiamondFilled />,
	IconDiamondOff: <IconDiamondOff />,
	IconDiamond: <IconDiamond />,
	IconDiamondsFilled: <IconDiamondsFilled />,
	IconDiamonds: <IconDiamonds />,
	IconDice1Filled: <IconDice1Filled />,
	IconDice1: <IconDice1 />,
	IconDice2Filled: <IconDice2Filled />,
	IconDice2: <IconDice2 />,
	IconDice3Filled: <IconDice3Filled />,
	IconDice3: <IconDice3 />,
	IconDice4Filled: <IconDice4Filled />,
	IconDice4: <IconDice4 />,
	IconDice5Filled: <IconDice5Filled />,
	IconDice5: <IconDice5 />,
	IconDice6Filled: <IconDice6Filled />,
	IconDice6: <IconDice6 />,
	IconDiceFilled: <IconDiceFilled />,
	IconDice: <IconDice />,
	IconDimensions: <IconDimensions />,
	IconDirectionHorizontal: <IconDirectionHorizontal />,
	IconDirectionSignFilled: <IconDirectionSignFilled />,
	IconDirectionSignOff: <IconDirectionSignOff />,
	IconDirectionSign: <IconDirectionSign />,
	IconDirection: <IconDirection />,
	IconDirectionsOff: <IconDirectionsOff />,
	IconDirections: <IconDirections />,
	IconDisabled2: <IconDisabled2 />,
	IconDisabledOff: <IconDisabledOff />,
	IconDisabled: <IconDisabled />,
	IconDiscGolf: <IconDiscGolf />,
	IconDiscOff: <IconDiscOff />,
	IconDisc: <IconDisc />,
	IconDiscount2Off: <IconDiscount2Off />,
	IconDiscount2: <IconDiscount2 />,
	IconDiscountCheckFilled: <IconDiscountCheckFilled />,
	IconDiscountCheck: <IconDiscountCheck />,
	IconDiscountOff: <IconDiscountOff />,
	IconDiscount: <IconDiscount />,
	IconDivide: <IconDivide />,
	IconDna2Off: <IconDna2Off />,
	IconDna2: <IconDna2 />,
	IconDnaOff: <IconDnaOff />,
	IconDna: <IconDna />,
	IconDogBowl: <IconDogBowl />,
	IconDog: <IconDog />,
	IconDoorEnter: <IconDoorEnter />,
	IconDoorExit: <IconDoorExit />,
	IconDoorOff: <IconDoorOff />,
	IconDoor: <IconDoor />,
	IconDotsCircleHorizontal: <IconDotsCircleHorizontal />,
	IconDotsDiagonal2: <IconDotsDiagonal2 />,
	IconDotsDiagonal: <IconDotsDiagonal />,
	IconDotsVertical: <IconDotsVertical />,
	IconDots: <IconDots />,
	IconDownloadOff: <IconDownloadOff />,
	IconDownload: <IconDownload />,
	IconDragDrop2: <IconDragDrop2 />,
	IconDragDrop: <IconDragDrop />,
	IconDroneOff: <IconDroneOff />,
	IconDrone: <IconDrone />,
	IconDropCircle: <IconDropCircle />,
	IconDropletFilled: <IconDropletFilled />,
	IconDropletHalf2: <IconDropletHalf2 />,
	IconDropletHalfFilled: <IconDropletHalfFilled />,
	IconDropletHalf: <IconDropletHalf />,
	IconDropletOff: <IconDropletOff />,
	IconDroplet: <IconDroplet />,
	IconEPassport: <IconEPassport />,
	IconEarOff: <IconEarOff />,
	IconEar: <IconEar />,
	IconEaseInControlPoint: <IconEaseInControlPoint />,
	IconEaseInOutControlPoints: <IconEaseInOutControlPoints />,
	IconEaseInOut: <IconEaseInOut />,
	IconEaseIn: <IconEaseIn />,
	IconEaseOutControlPoint: <IconEaseOutControlPoint />,
	IconEaseOut: <IconEaseOut />,
	IconEditCircleOff: <IconEditCircleOff />,
	IconEditCircle: <IconEditCircle />,
	IconEditOff: <IconEditOff />,
	IconEdit: <IconEdit />,
	IconEggCracked: <IconEggCracked />,
	IconEggFilled: <IconEggFilled />,
	IconEggFried: <IconEggFried />,
	IconEggOff: <IconEggOff />,
	IconEgg: <IconEgg />,
	IconEggs: <IconEggs />,
	IconElevatorOff: <IconElevatorOff />,
	IconElevator: <IconElevator />,
	IconEmergencyBed: <IconEmergencyBed />,
	IconEmpathizeOff: <IconEmpathizeOff />,
	IconEmpathize: <IconEmpathize />,
	IconEmphasis: <IconEmphasis />,
	IconEngineOff: <IconEngineOff />,
	IconEngine: <IconEngine />,
	IconEqualDouble: <IconEqualDouble />,
	IconEqualNot: <IconEqualNot />,
	IconEqual: <IconEqual />,
	IconEraserOff: <IconEraserOff />,
	IconEraser: <IconEraser />,
	IconError404Off: <IconError404Off />,
	IconError404: <IconError404 />,
	IconExchangeOff: <IconExchangeOff />,
	IconExchange: <IconExchange />,
	IconExclamationCircle: <IconExclamationCircle />,
	IconExclamationMarkOff: <IconExclamationMarkOff />,
	IconExclamationMark: <IconExclamationMark />,
	IconExplicitOff: <IconExplicitOff />,
	IconExplicit: <IconExplicit />,
	IconExposure0: <IconExposure0 />,
	IconExposureMinus1: <IconExposureMinus1 />,
	IconExposureMinus2: <IconExposureMinus2 />,
	IconExposureOff: <IconExposureOff />,
	IconExposurePlus1: <IconExposurePlus1 />,
	IconExposurePlus2: <IconExposurePlus2 />,
	IconExposure: <IconExposure />,
	IconExternalLinkOff: <IconExternalLinkOff />,
	IconExternalLink: <IconExternalLink />,
	IconEyeCheck: <IconEyeCheck />,
	IconEyeFilled: <IconEyeFilled />,
	IconEyeOff: <IconEyeOff />,
	IconEyeTable: <IconEyeTable />,
	IconEye: <IconEye />,
	IconEyeglass2: <IconEyeglass2 />,
	IconEyeglassOff: <IconEyeglassOff />,
	IconEyeglass: <IconEyeglass />,
	IconFaceIdError: <IconFaceIdError />,
	IconFaceId: <IconFaceId />,
	IconFaceMaskOff: <IconFaceMaskOff />,
	IconFaceMask: <IconFaceMask />,
	IconFall: <IconFall />,
	IconFeatherOff: <IconFeatherOff />,
	IconFeather: <IconFeather />,
	IconFenceOff: <IconFenceOff />,
	IconFence: <IconFence />,
	IconFidgetSpinner: <IconFidgetSpinner />,
	IconFile3d: <IconFile3d />,
	IconFileAlert: <IconFileAlert />,
	IconFileAnalytics: <IconFileAnalytics />,
	IconFileArrowLeft: <IconFileArrowLeft />,
	IconFileArrowRight: <IconFileArrowRight />,
	IconFileBarcode: <IconFileBarcode />,
	IconFileBroken: <IconFileBroken />,
	IconFileCertificate: <IconFileCertificate />,
	IconFileChart: <IconFileChart />,
	IconFileCheck: <IconFileCheck />,
	IconFileCode2: <IconFileCode2 />,
	IconFileCode: <IconFileCode />,
	IconFileDatabase: <IconFileDatabase />,
	IconFileDelta: <IconFileDelta />,
	IconFileDescription: <IconFileDescription />,
	IconFileDiff: <IconFileDiff />,
	IconFileDigit: <IconFileDigit />,
	IconFileDislike: <IconFileDislike />,
	IconFileDollar: <IconFileDollar />,
	IconFileDots: <IconFileDots />,
	IconFileDownload: <IconFileDownload />,
	IconFileEuro: <IconFileEuro />,
	IconFileExport: <IconFileExport />,
	IconFileFilled: <IconFileFilled />,
	IconFileFunction: <IconFileFunction />,
	IconFileHorizontal: <IconFileHorizontal />,
	IconFileImport: <IconFileImport />,
	IconFileInfinity: <IconFileInfinity />,
	IconFileInfo: <IconFileInfo />,
	IconFileInvoice: <IconFileInvoice />,
	IconFileLambda: <IconFileLambda />,
	IconFileLike: <IconFileLike />,
	IconFileMinus: <IconFileMinus />,
	IconFileMusic: <IconFileMusic />,
	IconFileOff: <IconFileOff />,
	IconFileOrientation: <IconFileOrientation />,
	IconFilePencil: <IconFilePencil />,
	IconFilePercent: <IconFilePercent />,
	IconFilePhone: <IconFilePhone />,
	IconFilePlus: <IconFilePlus />,
	IconFilePower: <IconFilePower />,
	IconFileReport: <IconFileReport />,
	IconFileRss: <IconFileRss />,
	IconFileScissors: <IconFileScissors />,
	IconFileSearch: <IconFileSearch />,
	IconFileSettings: <IconFileSettings />,
	IconFileShredder: <IconFileShredder />,
	IconFileSignal: <IconFileSignal />,
	IconFileSpreadsheet: <IconFileSpreadsheet />,
	IconFileStack: <IconFileStack />,
	IconFileStar: <IconFileStar />,
	IconFileSymlink: <IconFileSymlink />,
	IconFileText: <IconFileText />,
	IconFileTime: <IconFileTime />,
	IconFileTypography: <IconFileTypography />,
	IconFileUnknown: <IconFileUnknown />,
	IconFileUpload: <IconFileUpload />,
	IconFileVector: <IconFileVector />,
	IconFileXFilled: <IconFileXFilled />,
	IconFileX: <IconFileX />,
	IconFileZip: <IconFileZip />,
	IconFile: <IconFile />,
	IconFilesOff: <IconFilesOff />,
	IconFiles: <IconFiles />,
	IconFilterOff: <IconFilterOff />,
	IconFilter: <IconFilter />,
	IconFilters: <IconFilters />,
	IconFingerprintOff: <IconFingerprintOff />,
	IconFingerprint: <IconFingerprint />,
	IconFireHydrantOff: <IconFireHydrantOff />,
	IconFireHydrant: <IconFireHydrant />,
	IconFiretruck: <IconFiretruck />,
	IconFirstAidKitOff: <IconFirstAidKitOff />,
	IconFirstAidKit: <IconFirstAidKit />,
	IconFishBone: <IconFishBone />,
	IconFishChristianity: <IconFishChristianity />,
	IconFishHookOff: <IconFishHookOff />,
	IconFishHook: <IconFishHook />,
	IconFishOff: <IconFishOff />,
	IconFish: <IconFish />,
	IconFlag2Filled: <IconFlag2Filled />,
	IconFlag2Off: <IconFlag2Off />,
	IconFlag2: <IconFlag2 />,
	IconFlag3Filled: <IconFlag3Filled />,
	IconFlag3: <IconFlag3 />,
	IconFlagFilled: <IconFlagFilled />,
	IconFlagOff: <IconFlagOff />,
	IconFlag: <IconFlag />,
	IconFlameOff: <IconFlameOff />,
	IconFlame: <IconFlame />,
	IconFlare: <IconFlare />,
	IconFlask2Off: <IconFlask2Off />,
	IconFlask2: <IconFlask2 />,
	IconFlaskOff: <IconFlaskOff />,
	IconFlask: <IconFlask />,
	IconFlipFlops: <IconFlipFlops />,
	IconFlipHorizontal: <IconFlipHorizontal />,
	IconFlipVertical: <IconFlipVertical />,
	IconFloatCenter: <IconFloatCenter />,
	IconFloatLeft: <IconFloatLeft />,
	IconFloatNone: <IconFloatNone />,
	IconFloatRight: <IconFloatRight />,
	IconFlowerOff: <IconFlowerOff />,
	IconFlower: <IconFlower />,
	IconFocus2: <IconFocus2 />,
	IconFocusCentered: <IconFocusCentered />,
	IconFocus: <IconFocus />,
	IconFoldDown: <IconFoldDown />,
	IconFoldUp: <IconFoldUp />,
	IconFold: <IconFold />,
	IconFolderFilled: <IconFolderFilled />,
	IconFolderMinus: <IconFolderMinus />,
	IconFolderOff: <IconFolderOff />,
	IconFolderPlus: <IconFolderPlus />,
	IconFolderX: <IconFolderX />,
	IconFolder: <IconFolder />,
	IconFoldersOff: <IconFoldersOff />,
	IconFolders: <IconFolders />,
	IconForbid2: <IconForbid2 />,
	IconForbid: <IconForbid />,
	IconForklift: <IconForklift />,
	IconForms: <IconForms />,
	IconFountainOff: <IconFountainOff />,
	IconFountain: <IconFountain />,
	IconFrameOff: <IconFrameOff />,
	IconFrame: <IconFrame />,
	IconFreeRights: <IconFreeRights />,
	IconFridgeOff: <IconFridgeOff />,
	IconFridge: <IconFridge />,
	IconFriendsOff: <IconFriendsOff />,
	IconFriends: <IconFriends />,
	IconFunctionOff: <IconFunctionOff />,
	IconFunction: <IconFunction />,
	IconGardenCartOff: <IconGardenCartOff />,
	IconGardenCart: <IconGardenCart />,
	IconGasStationOff: <IconGasStationOff />,
	IconGasStation: <IconGasStation />,
	IconGaugeOff: <IconGaugeOff />,
	IconGauge: <IconGauge />,
	IconGavel: <IconGavel />,
	IconGenderAgender: <IconGenderAgender />,
	IconGenderAndrogyne: <IconGenderAndrogyne />,
	IconGenderBigender: <IconGenderBigender />,
	IconGenderDemiboy: <IconGenderDemiboy />,
	IconGenderDemigirl: <IconGenderDemigirl />,
	IconGenderEpicene: <IconGenderEpicene />,
	IconGenderFemale: <IconGenderFemale />,
	IconGenderFemme: <IconGenderFemme />,
	IconGenderGenderfluid: <IconGenderGenderfluid />,
	IconGenderGenderless: <IconGenderGenderless />,
	IconGenderGenderqueer: <IconGenderGenderqueer />,
	IconGenderHermaphrodite: <IconGenderHermaphrodite />,
	IconGenderIntergender: <IconGenderIntergender />,
	IconGenderMale: <IconGenderMale />,
	IconGenderNeutrois: <IconGenderNeutrois />,
	IconGenderThird: <IconGenderThird />,
	IconGenderTransgender: <IconGenderTransgender />,
	IconGenderTrasvesti: <IconGenderTrasvesti />,
	IconGeometry: <IconGeometry />,
	IconGhost2Filled: <IconGhost2Filled />,
	IconGhost2: <IconGhost2 />,
	IconGhostFilled: <IconGhostFilled />,
	IconGhostOff: <IconGhostOff />,
	IconGhost: <IconGhost />,
	IconGif: <IconGif />,
	IconGiftCard: <IconGiftCard />,
	IconGiftOff: <IconGiftOff />,
	IconGift: <IconGift />,
	IconGitBranchDeleted: <IconGitBranchDeleted />,
	IconGitBranch: <IconGitBranch />,
	IconGitCherryPick: <IconGitCherryPick />,
	IconGitCommit: <IconGitCommit />,
	IconGitCompare: <IconGitCompare />,
	IconGitFork: <IconGitFork />,
	IconGitMerge: <IconGitMerge />,
	IconGitPullRequestClosed: <IconGitPullRequestClosed />,
	IconGitPullRequestDraft: <IconGitPullRequestDraft />,
	IconGitPullRequest: <IconGitPullRequest />,
	IconGizmo: <IconGizmo />,
	IconGlassFull: <IconGlassFull />,
	IconGlassOff: <IconGlassOff />,
	IconGlass: <IconGlass />,
	IconGlobeOff: <IconGlobeOff />,
	IconGlobe: <IconGlobe />,
	IconGoGame: <IconGoGame />,
	IconGolfOff: <IconGolfOff />,
	IconGolf: <IconGolf />,
	IconGps: <IconGps />,
	IconGradienter: <IconGradienter />,
	IconGrain: <IconGrain />,
	IconGraphOff: <IconGraphOff />,
	IconGraph: <IconGraph />,
	IconGrave2: <IconGrave2 />,
	IconGrave: <IconGrave />,
	IconGridDots: <IconGridDots />,
	IconGridPattern: <IconGridPattern />,
	IconGrillFork: <IconGrillFork />,
	IconGrillOff: <IconGrillOff />,
	IconGrillSpatula: <IconGrillSpatula />,
	IconGrill: <IconGrill />,
	IconGripHorizontal: <IconGripHorizontal />,
	IconGripVertical: <IconGripVertical />,
	IconGrowth: <IconGrowth />,
	IconGuitarPickFilled: <IconGuitarPickFilled />,
	IconGuitarPick: <IconGuitarPick />,
	IconH1: <IconH1 />,
	IconH2: <IconH2 />,
	IconH3: <IconH3 />,
	IconH4: <IconH4 />,
	IconH5: <IconH5 />,
	IconH6: <IconH6 />,
	IconHammerOff: <IconHammerOff />,
	IconHammer: <IconHammer />,
	IconHandClick: <IconHandClick />,
	IconHandFingerOff: <IconHandFingerOff />,
	IconHandFinger: <IconHandFinger />,
	IconHandGrab: <IconHandGrab />,
	IconHandLittleFinger: <IconHandLittleFinger />,
	IconHandMiddleFinger: <IconHandMiddleFinger />,
	IconHandMove: <IconHandMove />,
	IconHandOff: <IconHandOff />,
	IconHandRingFinger: <IconHandRingFinger />,
	IconHandRock: <IconHandRock />,
	IconHandSanitizer: <IconHandSanitizer />,
	IconHandStop: <IconHandStop />,
	IconHandThreeFingers: <IconHandThreeFingers />,
	IconHandTwoFingers: <IconHandTwoFingers />,
	IconHanger2: <IconHanger2 />,
	IconHangerOff: <IconHangerOff />,
	IconHanger: <IconHanger />,
	IconHash: <IconHash />,
	IconHaze: <IconHaze />,
	IconHeadingOff: <IconHeadingOff />,
	IconHeading: <IconHeading />,
	IconHeadphonesOff: <IconHeadphonesOff />,
	IconHeadphones: <IconHeadphones />,
	IconHeadsetOff: <IconHeadsetOff />,
	IconHeadset: <IconHeadset />,
	IconHealthRecognition: <IconHealthRecognition />,
	IconHeartBroken: <IconHeartBroken />,
	IconHeartFilled: <IconHeartFilled />,
	IconHeartHandshake: <IconHeartHandshake />,
	IconHeartMinus: <IconHeartMinus />,
	IconHeartOff: <IconHeartOff />,
	IconHeartPlus: <IconHeartPlus />,
	IconHeartRateMonitor: <IconHeartRateMonitor />,
	IconHeart: <IconHeart />,
	IconHeartbeat: <IconHeartbeat />,
	IconHeartsOff: <IconHeartsOff />,
	IconHearts: <IconHearts />,
	IconHelicopterLanding: <IconHelicopterLanding />,
	IconHelicopter: <IconHelicopter />,
	IconHelmetOff: <IconHelmetOff />,
	IconHelmet: <IconHelmet />,
	IconHelpHexagon: <IconHelpHexagon />,
	IconHelpOctagon: <IconHelpOctagon />,
	IconHelpOff: <IconHelpOff />,
	IconHelp: <IconHelp />,
	IconHexagon0Filled: <IconHexagon0Filled />,
	IconHexagon1Filled: <IconHexagon1Filled />,
	IconHexagon2Filled: <IconHexagon2Filled />,
	IconHexagon3Filled: <IconHexagon3Filled />,
	IconHexagon3d: <IconHexagon3d />,
	IconHexagon4Filled: <IconHexagon4Filled />,
	IconHexagon5Filled: <IconHexagon5Filled />,
	IconHexagon6Filled: <IconHexagon6Filled />,
	IconHexagon7Filled: <IconHexagon7Filled />,
	IconHexagon8Filled: <IconHexagon8Filled />,
	IconHexagon9Filled: <IconHexagon9Filled />,
	IconHexagonFilled: <IconHexagonFilled />,
	IconHexagonLetterA: <IconHexagonLetterA />,
	IconHexagonLetterB: <IconHexagonLetterB />,
	IconHexagonLetterC: <IconHexagonLetterC />,
	IconHexagonLetterD: <IconHexagonLetterD />,
	IconHexagonLetterE: <IconHexagonLetterE />,
	IconHexagonLetterF: <IconHexagonLetterF />,
	IconHexagonLetterG: <IconHexagonLetterG />,
	IconHexagonLetterH: <IconHexagonLetterH />,
	IconHexagonLetterI: <IconHexagonLetterI />,
	IconHexagonLetterJ: <IconHexagonLetterJ />,
	IconHexagonLetterK: <IconHexagonLetterK />,
	IconHexagonLetterL: <IconHexagonLetterL />,
	IconHexagonLetterM: <IconHexagonLetterM />,
	IconHexagonLetterN: <IconHexagonLetterN />,
	IconHexagonLetterO: <IconHexagonLetterO />,
	IconHexagonLetterP: <IconHexagonLetterP />,
	IconHexagonLetterQ: <IconHexagonLetterQ />,
	IconHexagonLetterR: <IconHexagonLetterR />,
	IconHexagonLetterS: <IconHexagonLetterS />,
	IconHexagonLetterT: <IconHexagonLetterT />,
	IconHexagonLetterU: <IconHexagonLetterU />,
	IconHexagonLetterV: <IconHexagonLetterV />,
	IconHexagonLetterW: <IconHexagonLetterW />,
	IconHexagonLetterX: <IconHexagonLetterX />,
	IconHexagonLetterY: <IconHexagonLetterY />,
	IconHexagonLetterZ: <IconHexagonLetterZ />,
	IconHexagonNumber0: <IconHexagonNumber0 />,
	IconHexagonNumber1: <IconHexagonNumber1 />,
	IconHexagonNumber2: <IconHexagonNumber2 />,
	IconHexagonNumber3: <IconHexagonNumber3 />,
	IconHexagonNumber4: <IconHexagonNumber4 />,
	IconHexagonNumber5: <IconHexagonNumber5 />,
	IconHexagonNumber6: <IconHexagonNumber6 />,
	IconHexagonNumber7: <IconHexagonNumber7 />,
	IconHexagonNumber8: <IconHexagonNumber8 />,
	IconHexagonNumber9: <IconHexagonNumber9 />,
	IconHexagonOff: <IconHexagonOff />,
	IconHexagon: <IconHexagon />,
	IconHexagonsOff: <IconHexagonsOff />,
	IconHexagons: <IconHexagons />,
	IconHierarchy2: <IconHierarchy2 />,
	IconHierarchy3: <IconHierarchy3 />,
	IconHierarchyOff: <IconHierarchyOff />,
	IconHierarchy: <IconHierarchy />,
	IconHighlightOff: <IconHighlightOff />,
	IconHighlight: <IconHighlight />,
	IconHistoryOff: <IconHistoryOff />,
	IconHistoryToggle: <IconHistoryToggle />,
	IconHistory: <IconHistory />,
	IconHome2: <IconHome2 />,
	IconHomeBolt: <IconHomeBolt />,
	IconHomeCancel: <IconHomeCancel />,
	IconHomeCheck: <IconHomeCheck />,
	IconHomeCog: <IconHomeCog />,
	IconHomeDollar: <IconHomeDollar />,
	IconHomeDot: <IconHomeDot />,
	IconHomeDown: <IconHomeDown />,
	IconHomeEco: <IconHomeEco />,
	IconHomeEdit: <IconHomeEdit />,
	IconHomeExclamation: <IconHomeExclamation />,
	IconHomeHand: <IconHomeHand />,
	IconHomeHeart: <IconHomeHeart />,
	IconHomeInfinity: <IconHomeInfinity />,
	IconHomeLink: <IconHomeLink />,
	IconHomeMinus: <IconHomeMinus />,
	IconHomeMove: <IconHomeMove />,
	IconHomeOff: <IconHomeOff />,
	IconHomePlus: <IconHomePlus />,
	IconHomeQuestion: <IconHomeQuestion />,
	IconHomeRibbon: <IconHomeRibbon />,
	IconHomeSearch: <IconHomeSearch />,
	IconHomeShare: <IconHomeShare />,
	IconHomeShield: <IconHomeShield />,
	IconHomeSignal: <IconHomeSignal />,
	IconHomeStar: <IconHomeStar />,
	IconHomeStats: <IconHomeStats />,
	IconHomeUp: <IconHomeUp />,
	IconHomeX: <IconHomeX />,
	IconHome: <IconHome />,
	IconHorseToy: <IconHorseToy />,
	IconHotelService: <IconHotelService />,
	IconHourglassEmpty: <IconHourglassEmpty />,
	IconHourglassFilled: <IconHourglassFilled />,
	IconHourglassHigh: <IconHourglassHigh />,
	IconHourglassLow: <IconHourglassLow />,
	IconHourglassOff: <IconHourglassOff />,
	IconHourglass: <IconHourglass />,
	IconIceCream2: <IconIceCream2 />,
	IconIceCreamOff: <IconIceCreamOff />,
	IconIceCream: <IconIceCream />,
	IconIceSkating: <IconIceSkating />,
	IconIconsOff: <IconIconsOff />,
	IconIcons: <IconIcons />,
	IconIdBadge2: <IconIdBadge2 />,
	IconIdBadgeOff: <IconIdBadgeOff />,
	IconIdBadge: <IconIdBadge />,
	IconIdOff: <IconIdOff />,
	IconId: <IconId />,
	IconInboxOff: <IconInboxOff />,
	IconInbox: <IconInbox />,
	IconIndentDecrease: <IconIndentDecrease />,
	IconIndentIncrease: <IconIndentIncrease />,
	IconInfinityOff: <IconInfinityOff />,
	IconInfinity: <IconInfinity />,
	IconInfoCircleFilled: <IconInfoCircleFilled />,
	IconInfoCircle: <IconInfoCircle />,
	IconInfoHexagon: <IconInfoHexagon />,
	IconInfoOctagon: <IconInfoOctagon />,
	IconInfoSquareRoundedFilled: <IconInfoSquareRoundedFilled />,
	IconInfoSquareRounded: <IconInfoSquareRounded />,
	IconInfoSquare: <IconInfoSquare />,
	IconInnerShadowBottomFilled: <IconInnerShadowBottomFilled />,
	IconInnerShadowBottomLeftFilled: <IconInnerShadowBottomLeftFilled />,
	IconInnerShadowBottomLeft: <IconInnerShadowBottomLeft />,
	IconInnerShadowBottomRightFilled: <IconInnerShadowBottomRightFilled />,
	IconInnerShadowBottomRight: <IconInnerShadowBottomRight />,
	IconInnerShadowBottom: <IconInnerShadowBottom />,
	IconInnerShadowLeftFilled: <IconInnerShadowLeftFilled />,
	IconInnerShadowLeft: <IconInnerShadowLeft />,
	IconInnerShadowRightFilled: <IconInnerShadowRightFilled />,
	IconInnerShadowRight: <IconInnerShadowRight />,
	IconInnerShadowTopFilled: <IconInnerShadowTopFilled />,
	IconInnerShadowTopLeftFilled: <IconInnerShadowTopLeftFilled />,
	IconInnerShadowTopLeft: <IconInnerShadowTopLeft />,
	IconInnerShadowTopRightFilled: <IconInnerShadowTopRightFilled />,
	IconInnerShadowTopRight: <IconInnerShadowTopRight />,
	IconInnerShadowTop: <IconInnerShadowTop />,
	IconInputSearch: <IconInputSearch />,
	IconIroning1: <IconIroning1 />,
	IconIroning2: <IconIroning2 />,
	IconIroning3: <IconIroning3 />,
	IconIroningOff: <IconIroningOff />,
	IconIroningSteamOff: <IconIroningSteamOff />,
	IconIroningSteam: <IconIroningSteam />,
	IconItalic: <IconItalic />,
	IconJacket: <IconJacket />,
	IconJetpack: <IconJetpack />,
	IconJewishStarFilled: <IconJewishStarFilled />,
	IconJewishStar: <IconJewishStar />,
	IconJpg: <IconJpg />,
	IconJumpRope: <IconJumpRope />,
	IconKarate: <IconKarate />,
	IconKayak: <IconKayak />,
	IconKering: <IconKering />,
	IconKeyOff: <IconKeyOff />,
	IconKey: <IconKey />,
	IconKeyboardHide: <IconKeyboardHide />,
	IconKeyboardOff: <IconKeyboardOff />,
	IconKeyboardShow: <IconKeyboardShow />,
	IconKeyboard: <IconKeyboard />,
	IconKeyframeAlignCenter: <IconKeyframeAlignCenter />,
	IconKeyframeAlignHorizontal: <IconKeyframeAlignHorizontal />,
	IconKeyframeAlignVertical: <IconKeyframeAlignVertical />,
	IconKeyframe: <IconKeyframe />,
	IconKeyframes: <IconKeyframes />,
	IconLadderOff: <IconLadderOff />,
	IconLadder: <IconLadder />,
	IconLambda: <IconLambda />,
	IconLamp2: <IconLamp2 />,
	IconLampOff: <IconLampOff />,
	IconLamp: <IconLamp />,
	IconLanguageHiragana: <IconLanguageHiragana />,
	IconLanguageKatakana: <IconLanguageKatakana />,
	IconLanguageOff: <IconLanguageOff />,
	IconLanguage: <IconLanguage />,
	IconLassoOff: <IconLassoOff />,
	IconLassoPolygon: <IconLassoPolygon />,
	IconLasso: <IconLasso />,
	IconLayersDifference: <IconLayersDifference />,
	IconLayersIntersect2: <IconLayersIntersect2 />,
	IconLayersIntersect: <IconLayersIntersect />,
	IconLayersLinked: <IconLayersLinked />,
	IconLayersOff: <IconLayersOff />,
	IconLayersSubtract: <IconLayersSubtract />,
	IconLayersUnion: <IconLayersUnion />,
	IconLayout2: <IconLayout2 />,
	IconLayoutAlignBottom: <IconLayoutAlignBottom />,
	IconLayoutAlignCenter: <IconLayoutAlignCenter />,
	IconLayoutAlignLeft: <IconLayoutAlignLeft />,
	IconLayoutAlignMiddle: <IconLayoutAlignMiddle />,
	IconLayoutAlignRight: <IconLayoutAlignRight />,
	IconLayoutAlignTop: <IconLayoutAlignTop />,
	IconLayoutBoardSplit: <IconLayoutBoardSplit />,
	IconLayoutBoard: <IconLayoutBoard />,
	IconLayoutBottombarCollapse: <IconLayoutBottombarCollapse />,
	IconLayoutBottombarExpand: <IconLayoutBottombarExpand />,
	IconLayoutBottombar: <IconLayoutBottombar />,
	IconLayoutCards: <IconLayoutCards />,
	IconLayoutCollage: <IconLayoutCollage />,
	IconLayoutColumns: <IconLayoutColumns />,
	IconLayoutDashboard: <IconLayoutDashboard />,
	IconLayoutDistributeHorizontal: <IconLayoutDistributeHorizontal />,
	IconLayoutDistributeVertical: <IconLayoutDistributeVertical />,
	IconLayoutGridAdd: <IconLayoutGridAdd />,
	IconLayoutGrid: <IconLayoutGrid />,
	IconLayoutKanban: <IconLayoutKanban />,
	IconLayoutList: <IconLayoutList />,
	IconLayoutNavbarCollapse: <IconLayoutNavbarCollapse />,
	IconLayoutNavbarExpand: <IconLayoutNavbarExpand />,
	IconLayoutNavbar: <IconLayoutNavbar />,
	IconLayoutOff: <IconLayoutOff />,
	IconLayoutRows: <IconLayoutRows />,
	IconLayoutSidebarLeftCollapse: <IconLayoutSidebarLeftCollapse />,
	IconLayoutSidebarLeftExpand: <IconLayoutSidebarLeftExpand />,
	IconLayoutSidebarRightCollapse: <IconLayoutSidebarRightCollapse />,
	IconLayoutSidebarRightExpand: <IconLayoutSidebarRightExpand />,
	IconLayoutSidebarRight: <IconLayoutSidebarRight />,
	IconLayoutSidebar: <IconLayoutSidebar />,
	IconLayout: <IconLayout />,
	IconLeafOff: <IconLeafOff />,
	IconLeaf: <IconLeaf />,
	IconLegoOff: <IconLegoOff />,
	IconLego: <IconLego />,
	IconLemon2: <IconLemon2 />,
	IconLemon: <IconLemon />,
	IconLetterA: <IconLetterA />,
	IconLetterB: <IconLetterB />,
	IconLetterC: <IconLetterC />,
	IconLetterCaseLower: <IconLetterCaseLower />,
	IconLetterCaseToggle: <IconLetterCaseToggle />,
	IconLetterCaseUpper: <IconLetterCaseUpper />,
	IconLetterCase: <IconLetterCase />,
	IconLetterD: <IconLetterD />,
	IconLetterE: <IconLetterE />,
	IconLetterF: <IconLetterF />,
	IconLetterG: <IconLetterG />,
	IconLetterH: <IconLetterH />,
	IconLetterI: <IconLetterI />,
	IconLetterJ: <IconLetterJ />,
	IconLetterK: <IconLetterK />,
	IconLetterL: <IconLetterL />,
	IconLetterM: <IconLetterM />,
	IconLetterN: <IconLetterN />,
	IconLetterO: <IconLetterO />,
	IconLetterP: <IconLetterP />,
	IconLetterQ: <IconLetterQ />,
	IconLetterR: <IconLetterR />,
	IconLetterS: <IconLetterS />,
	IconLetterSpacing: <IconLetterSpacing />,
	IconLetterT: <IconLetterT />,
	IconLetterU: <IconLetterU />,
	IconLetterV: <IconLetterV />,
	IconLetterW: <IconLetterW />,
	IconLetterX: <IconLetterX />,
	IconLetterY: <IconLetterY />,
	IconLetterZ: <IconLetterZ />,
	IconLicenseOff: <IconLicenseOff />,
	IconLicense: <IconLicense />,
	IconLifebuoyOff: <IconLifebuoyOff />,
	IconLifebuoy: <IconLifebuoy />,
	IconLighter: <IconLighter />,
	IconLineDashed: <IconLineDashed />,
	IconLineDotted: <IconLineDotted />,
	IconLineHeight: <IconLineHeight />,
	IconLine: <IconLine />,
	IconLinkOff: <IconLinkOff />,
	IconLink: <IconLink />,
	IconListCheck: <IconListCheck />,
	IconListDetails: <IconListDetails />,
	IconListNumbers: <IconListNumbers />,
	IconListSearch: <IconListSearch />,
	IconList: <IconList />,
	IconLivePhotoOff: <IconLivePhotoOff />,
	IconLivePhoto: <IconLivePhoto />,
	IconLiveView: <IconLiveView />,
	IconLoader2: <IconLoader2 />,
	IconLoader3: <IconLoader3 />,
	IconLoaderQuarter: <IconLoaderQuarter />,
	IconLoader: <IconLoader />,
	IconLocationBroken: <IconLocationBroken />,
	IconLocationFilled: <IconLocationFilled />,
	IconLocationOff: <IconLocationOff />,
	IconLocation: <IconLocation />,
	IconLockAccessOff: <IconLockAccessOff />,
	IconLockAccess: <IconLockAccess />,
	IconLockOff: <IconLockOff />,
	IconLockOpenOff: <IconLockOpenOff />,
	IconLockOpen: <IconLockOpen />,
	IconLockSquareRoundedFilled: <IconLockSquareRoundedFilled />,
	IconLockSquareRounded: <IconLockSquareRounded />,
	IconLockSquare: <IconLockSquare />,
	IconLock: <IconLock />,
	IconLogicAnd: <IconLogicAnd />,
	IconLogicBuffer: <IconLogicBuffer />,
	IconLogicNand: <IconLogicNand />,
	IconLogicNor: <IconLogicNor />,
	IconLogicNot: <IconLogicNot />,
	IconLogicOr: <IconLogicOr />,
	IconLogicXnor: <IconLogicXnor />,
	IconLogicXor: <IconLogicXor />,
	IconLogin: <IconLogin />,
	IconLogout: <IconLogout />,
	IconLollipopOff: <IconLollipopOff />,
	IconLollipop: <IconLollipop />,
	IconLuggageOff: <IconLuggageOff />,
	IconLuggage: <IconLuggage />,
	IconLungsOff: <IconLungsOff />,
	IconLungs: <IconLungs />,
	IconMacroOff: <IconMacroOff />,
	IconMacro: <IconMacro />,
	IconMagnetOff: <IconMagnetOff />,
	IconMagnet: <IconMagnet />,
	IconMailFast: <IconMailFast />,
	IconMailForward: <IconMailForward />,
	IconMailOff: <IconMailOff />,
	IconMailOpened: <IconMailOpened />,
	IconMail: <IconMail />,
	IconMailboxOff: <IconMailboxOff />,
	IconMailbox: <IconMailbox />,
	IconMan: <IconMan />,
	IconManualGearbox: <IconManualGearbox />,
	IconMap2: <IconMap2 />,
	IconMapOff: <IconMapOff />,
	IconMapPinFilled: <IconMapPinFilled />,
	IconMapPinOff: <IconMapPinOff />,
	IconMapPinShare: <IconMapPinShare />,
	IconMapPin: <IconMapPin />,
	IconMapPins: <IconMapPins />,
	IconMapSearch: <IconMapSearch />,
	IconMap: <IconMap />,
	IconMarkdownOff: <IconMarkdownOff />,
	IconMarkdown: <IconMarkdown />,
	IconMarquee2: <IconMarquee2 />,
	IconMarqueeOff: <IconMarqueeOff />,
	IconMarquee: <IconMarquee />,
	IconMars: <IconMars />,
	IconMaskOff: <IconMaskOff />,
	IconMask: <IconMask />,
	IconMasksTheaterOff: <IconMasksTheaterOff />,
	IconMasksTheater: <IconMasksTheater />,
	IconMassage: <IconMassage />,
	IconMatchstick: <IconMatchstick />,
	IconMath1Divide2: <IconMath1Divide2 />,
	IconMath1Divide3: <IconMath1Divide3 />,
	IconMathAvg: <IconMathAvg />,
	IconMathEqualGreater: <IconMathEqualGreater />,
	IconMathEqualLower: <IconMathEqualLower />,
	IconMathFunctionOff: <IconMathFunctionOff />,
	IconMathFunctionY: <IconMathFunctionY />,
	IconMathFunction: <IconMathFunction />,
	IconMathGreater: <IconMathGreater />,
	IconMathIntegralX: <IconMathIntegralX />,
	IconMathIntegral: <IconMathIntegral />,
	IconMathIntegrals: <IconMathIntegrals />,
	IconMathLower: <IconMathLower />,
	IconMathMax: <IconMathMax />,
	IconMathMin: <IconMathMin />,
	IconMathNot: <IconMathNot />,
	IconMathOff: <IconMathOff />,
	IconMathPiDivide2: <IconMathPiDivide2 />,
	IconMathPi: <IconMathPi />,
	IconMathSymbols: <IconMathSymbols />,
	IconMathXDivide2: <IconMathXDivide2 />,
	IconMathXDivideY2: <IconMathXDivideY2 />,
	IconMathXDivideY: <IconMathXDivideY />,
	IconMathXMinusX: <IconMathXMinusX />,
	IconMathXMinusY: <IconMathXMinusY />,
	IconMathXPlusX: <IconMathXPlusX />,
	IconMathXPlusY: <IconMathXPlusY />,
	IconMathXy: <IconMathXy />,
	IconMathYMinusY: <IconMathYMinusY />,
	IconMathYPlusY: <IconMathYPlusY />,
	IconMath: <IconMath />,
	IconMaximizeOff: <IconMaximizeOff />,
	IconMaximize: <IconMaximize />,
	IconMeatOff: <IconMeatOff />,
	IconMeat: <IconMeat />,
	IconMedal2: <IconMedal2 />,
	IconMedal: <IconMedal />,
	IconMedicalCrossFilled: <IconMedicalCrossFilled />,
	IconMedicalCrossOff: <IconMedicalCrossOff />,
	IconMedicalCross: <IconMedicalCross />,
	IconMedicineSyrup: <IconMedicineSyrup />,
	IconMeeple: <IconMeeple />,
	IconMenorah: <IconMenorah />,
	IconMenu2: <IconMenu2 />,
	IconMenuOrder: <IconMenuOrder />,
	IconMenu: <IconMenu />,
	IconMessage2Code: <IconMessage2Code />,
	IconMessage2Off: <IconMessage2Off />,
	IconMessage2Share: <IconMessage2Share />,
	IconMessage2: <IconMessage2 />,
	IconMessageChatbot: <IconMessageChatbot />,
	IconMessageCircle2Filled: <IconMessageCircle2Filled />,
	IconMessageCircle2: <IconMessageCircle2 />,
	IconMessageCircleOff: <IconMessageCircleOff />,
	IconMessageCircle: <IconMessageCircle />,
	IconMessageCode: <IconMessageCode />,
	IconMessageDots: <IconMessageDots />,
	IconMessageForward: <IconMessageForward />,
	IconMessageLanguage: <IconMessageLanguage />,
	IconMessageOff: <IconMessageOff />,
	IconMessagePlus: <IconMessagePlus />,
	IconMessageReport: <IconMessageReport />,
	IconMessageShare: <IconMessageShare />,
	IconMessage: <IconMessage />,
	IconMessagesOff: <IconMessagesOff />,
	IconMessages: <IconMessages />,
	IconMeteorOff: <IconMeteorOff />,
	IconMeteor: <IconMeteor />,
	IconMickeyFilled: <IconMickeyFilled />,
	IconMickey: <IconMickey />,
	IconMicrophone2Off: <IconMicrophone2Off />,
	IconMicrophone2: <IconMicrophone2 />,
	IconMicrophoneOff: <IconMicrophoneOff />,
	IconMicrophone: <IconMicrophone />,
	IconMicroscopeOff: <IconMicroscopeOff />,
	IconMicroscope: <IconMicroscope />,
	IconMicrowaveOff: <IconMicrowaveOff />,
	IconMicrowave: <IconMicrowave />,
	IconMilitaryAward: <IconMilitaryAward />,
	IconMilitaryRank: <IconMilitaryRank />,
	IconMilkOff: <IconMilkOff />,
	IconMilk: <IconMilk />,
	IconMilkshake: <IconMilkshake />,
	IconMinimize: <IconMinimize />,
	IconMinusVertical: <IconMinusVertical />,
	IconMinus: <IconMinus />,
	IconMistOff: <IconMistOff />,
	IconMist: <IconMist />,
	IconMoneybag: <IconMoneybag />,
	IconMoodAngry: <IconMoodAngry />,
	IconMoodAnnoyed2: <IconMoodAnnoyed2 />,
	IconMoodAnnoyed: <IconMoodAnnoyed />,
	IconMoodBoy: <IconMoodBoy />,
	IconMoodConfuzed: <IconMoodConfuzed />,
	IconMoodCrazyHappy: <IconMoodCrazyHappy />,
	IconMoodCry: <IconMoodCry />,
	IconMoodEmpty: <IconMoodEmpty />,
	IconMoodHappy: <IconMoodHappy />,
	IconMoodKid: <IconMoodKid />,
	IconMoodLookLeft: <IconMoodLookLeft />,
	IconMoodLookRight: <IconMoodLookRight />,
	IconMoodNerd: <IconMoodNerd />,
	IconMoodNervous: <IconMoodNervous />,
	IconMoodNeutral: <IconMoodNeutral />,
	IconMoodOff: <IconMoodOff />,
	IconMoodSad2: <IconMoodSad2 />,
	IconMoodSadDizzy: <IconMoodSadDizzy />,
	IconMoodSadSquint: <IconMoodSadSquint />,
	IconMoodSad: <IconMoodSad />,
	IconMoodSick: <IconMoodSick />,
	IconMoodSilence: <IconMoodSilence />,
	IconMoodSing: <IconMoodSing />,
	IconMoodSmileBeam: <IconMoodSmileBeam />,
	IconMoodSmileDizzy: <IconMoodSmileDizzy />,
	IconMoodSmile: <IconMoodSmile />,
	IconMoodSuprised: <IconMoodSuprised />,
	IconMoodTongueWink2: <IconMoodTongueWink2 />,
	IconMoodTongueWink: <IconMoodTongueWink />,
	IconMoodTongue: <IconMoodTongue />,
	IconMoodUnamused: <IconMoodUnamused />,
	IconMoodWink2: <IconMoodWink2 />,
	IconMoodWink: <IconMoodWink />,
	IconMoodWrrr: <IconMoodWrrr />,
	IconMoodXd: <IconMoodXd />,
	IconMoon2: <IconMoon2 />,
	IconMoonFilled: <IconMoonFilled />,
	IconMoonOff: <IconMoonOff />,
	IconMoonStars: <IconMoonStars />,
	IconMoon: <IconMoon />,
	IconMoped: <IconMoped />,
	IconMotorbike: <IconMotorbike />,
	IconMountainOff: <IconMountainOff />,
	IconMountain: <IconMountain />,
	IconMouse2: <IconMouse2 />,
	IconMouseOff: <IconMouseOff />,
	IconMouse: <IconMouse />,
	IconMoustache: <IconMoustache />,
	IconMovieOff: <IconMovieOff />,
	IconMovie: <IconMovie />,
	IconMugOff: <IconMugOff />,
	IconMug: <IconMug />,
	IconMultiplier05x: <IconMultiplier05x />,
	IconMultiplier15x: <IconMultiplier15x />,
	IconMultiplier1x: <IconMultiplier1x />,
	IconMultiplier2x: <IconMultiplier2x />,
	IconMushroomOff: <IconMushroomOff />,
	IconMushroom: <IconMushroom />,
	IconMusicOff: <IconMusicOff />,
	IconMusic: <IconMusic />,
	IconNavigationFilled: <IconNavigationFilled />,
	IconNavigationOff: <IconNavigationOff />,
	IconNavigation: <IconNavigation />,
	IconNeedleThread: <IconNeedleThread />,
	IconNeedle: <IconNeedle />,
	IconNetworkOff: <IconNetworkOff />,
	IconNetwork: <IconNetwork />,
	IconNewSection: <IconNewSection />,
	IconNewsOff: <IconNewsOff />,
	IconNews: <IconNews />,
	IconNfcOff: <IconNfcOff />,
	IconNfc: <IconNfc />,
	IconNoCopyright: <IconNoCopyright />,
	IconNoCreativeCommons: <IconNoCreativeCommons />,
	IconNoDerivatives: <IconNoDerivatives />,
	IconNorthStar: <IconNorthStar />,
	IconNoteOff: <IconNoteOff />,
	IconNote: <IconNote />,
	IconNotebookOff: <IconNotebookOff />,
	IconNotebook: <IconNotebook />,
	IconNotesOff: <IconNotesOff />,
	IconNotes: <IconNotes />,
	IconNotificationOff: <IconNotificationOff />,
	IconNotification: <IconNotification />,
	IconNumber0: <IconNumber0 />,
	IconNumber1: <IconNumber1 />,
	IconNumber2: <IconNumber2 />,
	IconNumber3: <IconNumber3 />,
	IconNumber4: <IconNumber4 />,
	IconNumber5: <IconNumber5 />,
	IconNumber6: <IconNumber6 />,
	IconNumber7: <IconNumber7 />,
	IconNumber8: <IconNumber8 />,
	IconNumber9: <IconNumber9 />,
	IconNumber: <IconNumber />,
	IconNumbers: <IconNumbers />,
	IconNurse: <IconNurse />,
	IconOctagonFilled: <IconOctagonFilled />,
	IconOctagonOff: <IconOctagonOff />,
	IconOctagon: <IconOctagon />,
	IconOld: <IconOld />,
	IconOlympicsOff: <IconOlympicsOff />,
	IconOlympics: <IconOlympics />,
	IconOm: <IconOm />,
	IconOmega: <IconOmega />,
	IconOutbound: <IconOutbound />,
	IconOutlet: <IconOutlet />,
	IconOvalFilled: <IconOvalFilled />,
	IconOvalVerticalFilled: <IconOvalVerticalFilled />,
	IconOvalVertical: <IconOvalVertical />,
	IconOval: <IconOval />,
	IconOverline: <IconOverline />,
	IconPackageExport: <IconPackageExport />,
	IconPackageImport: <IconPackageImport />,
	IconPackageOff: <IconPackageOff />,
	IconPackage: <IconPackage />,
	IconPackages: <IconPackages />,
	IconPacman: <IconPacman />,
	IconPageBreak: <IconPageBreak />,
	IconPaintFilled: <IconPaintFilled />,
	IconPaintOff: <IconPaintOff />,
	IconPaint: <IconPaint />,
	IconPaletteOff: <IconPaletteOff />,
	IconPalette: <IconPalette />,
	IconPanoramaHorizontalOff: <IconPanoramaHorizontalOff />,
	IconPanoramaHorizontal: <IconPanoramaHorizontal />,
	IconPanoramaVerticalOff: <IconPanoramaVerticalOff />,
	IconPanoramaVertical: <IconPanoramaVertical />,
	IconPaperBagOff: <IconPaperBagOff />,
	IconPaperBag: <IconPaperBag />,
	IconPaperclip: <IconPaperclip />,
	IconParachuteOff: <IconParachuteOff />,
	IconParachute: <IconParachute />,
	IconParenthesesOff: <IconParenthesesOff />,
	IconParentheses: <IconParentheses />,
	IconParkingOff: <IconParkingOff />,
	IconParking: <IconParking />,
	IconPassword: <IconPassword />,
	IconPawFilled: <IconPawFilled />,
	IconPawOff: <IconPawOff />,
	IconPaw: <IconPaw />,
	IconPdf: <IconPdf />,
	IconPeace: <IconPeace />,
	IconPencilMinus: <IconPencilMinus />,
	IconPencilOff: <IconPencilOff />,
	IconPencilPlus: <IconPencilPlus />,
	IconPencil: <IconPencil />,
	IconPennant2Filled: <IconPennant2Filled />,
	IconPennant2: <IconPennant2 />,
	IconPennantFilled: <IconPennantFilled />,
	IconPennantOff: <IconPennantOff />,
	IconPennant: <IconPennant />,
	IconPentagonFilled: <IconPentagonFilled />,
	IconPentagonOff: <IconPentagonOff />,
	IconPentagon: <IconPentagon />,
	IconPentagram: <IconPentagram />,
	IconPepperOff: <IconPepperOff />,
	IconPepper: <IconPepper />,
	IconPercentage: <IconPercentage />,
	IconPerfume: <IconPerfume />,
	IconPerspectiveOff: <IconPerspectiveOff />,
	IconPerspective: <IconPerspective />,
	IconPhoneCall: <IconPhoneCall />,
	IconPhoneCalling: <IconPhoneCalling />,
	IconPhoneCheck: <IconPhoneCheck />,
	IconPhoneIncoming: <IconPhoneIncoming />,
	IconPhoneOff: <IconPhoneOff />,
	IconPhoneOutgoing: <IconPhoneOutgoing />,
	IconPhonePause: <IconPhonePause />,
	IconPhonePlus: <IconPhonePlus />,
	IconPhoneX: <IconPhoneX />,
	IconPhone: <IconPhone />,
	IconPhotoCancel: <IconPhotoCancel />,
	IconPhotoCheck: <IconPhotoCheck />,
	IconPhotoDown: <IconPhotoDown />,
	IconPhotoEdit: <IconPhotoEdit />,
	IconPhotoHeart: <IconPhotoHeart />,
	IconPhotoMinus: <IconPhotoMinus />,
	IconPhotoOff: <IconPhotoOff />,
	IconPhotoPlus: <IconPhotoPlus />,
	IconPhotoSearch: <IconPhotoSearch />,
	IconPhotoSensor2: <IconPhotoSensor2 />,
	IconPhotoSensor3: <IconPhotoSensor3 />,
	IconPhotoSensor: <IconPhotoSensor />,
	IconPhotoShield: <IconPhotoShield />,
	IconPhotoStar: <IconPhotoStar />,
	IconPhotoUp: <IconPhotoUp />,
	IconPhotoX: <IconPhotoX />,
	IconPhoto: <IconPhoto />,
	IconPhysotherapist: <IconPhysotherapist />,
	IconPictureInPictureOff: <IconPictureInPictureOff />,
	IconPictureInPictureOn: <IconPictureInPictureOn />,
	IconPictureInPictureTop: <IconPictureInPictureTop />,
	IconPictureInPicture: <IconPictureInPicture />,
	IconPigMoney: <IconPigMoney />,
	IconPigOff: <IconPigOff />,
	IconPig: <IconPig />,
	IconPilcrow: <IconPilcrow />,
	IconPillOff: <IconPillOff />,
	IconPill: <IconPill />,
	IconPills: <IconPills />,
	IconPinFilled: <IconPinFilled />,
	IconPin: <IconPin />,
	IconPingPong: <IconPingPong />,
	IconPinnedFilled: <IconPinnedFilled />,
	IconPinnedOff: <IconPinnedOff />,
	IconPinned: <IconPinned />,
	IconPizzaOff: <IconPizzaOff />,
	IconPizza: <IconPizza />,
	IconPlaceholder: <IconPlaceholder />,
	IconPlaneArrival: <IconPlaneArrival />,
	IconPlaneDeparture: <IconPlaneDeparture />,
	IconPlaneInflight: <IconPlaneInflight />,
	IconPlaneOff: <IconPlaneOff />,
	IconPlaneTilt: <IconPlaneTilt />,
	IconPlane: <IconPlane />,
	IconPlanetOff: <IconPlanetOff />,
	IconPlanet: <IconPlanet />,
	IconPlant2Off: <IconPlant2Off />,
	IconPlant2: <IconPlant2 />,
	IconPlantOff: <IconPlantOff />,
	IconPlant: <IconPlant />,
	IconPlayCardOff: <IconPlayCardOff />,
	IconPlayCard: <IconPlayCard />,
	IconPlayerEjectFilled: <IconPlayerEjectFilled />,
	IconPlayerEject: <IconPlayerEject />,
	IconPlayerPauseFilled: <IconPlayerPauseFilled />,
	IconPlayerPause: <IconPlayerPause />,
	IconPlayerPlayFilled: <IconPlayerPlayFilled />,
	IconPlayerPlay: <IconPlayerPlay />,
	IconPlayerRecordFilled: <IconPlayerRecordFilled />,
	IconPlayerRecord: <IconPlayerRecord />,
	IconPlayerSkipBackFilled: <IconPlayerSkipBackFilled />,
	IconPlayerSkipBack: <IconPlayerSkipBack />,
	IconPlayerSkipForwardFilled: <IconPlayerSkipForwardFilled />,
	IconPlayerSkipForward: <IconPlayerSkipForward />,
	IconPlayerStopFilled: <IconPlayerStopFilled />,
	IconPlayerStop: <IconPlayerStop />,
	IconPlayerTrackNextFilled: <IconPlayerTrackNextFilled />,
	IconPlayerTrackNext: <IconPlayerTrackNext />,
	IconPlayerTrackPrevFilled: <IconPlayerTrackPrevFilled />,
	IconPlayerTrackPrev: <IconPlayerTrackPrev />,
	IconPlaylistAdd: <IconPlaylistAdd />,
	IconPlaylistOff: <IconPlaylistOff />,
	IconPlaylistX: <IconPlaylistX />,
	IconPlaylist: <IconPlaylist />,
	IconPlaystationCircle: <IconPlaystationCircle />,
	IconPlaystationSquare: <IconPlaystationSquare />,
	IconPlaystationTriangle: <IconPlaystationTriangle />,
	IconPlaystationX: <IconPlaystationX />,
	IconPlugConnectedX: <IconPlugConnectedX />,
	IconPlugConnected: <IconPlugConnected />,
	IconPlugOff: <IconPlugOff />,
	IconPlugX: <IconPlugX />,
	IconPlug: <IconPlug />,
	IconPlusEqual: <IconPlusEqual />,
	IconPlusMinus: <IconPlusMinus />,
	IconPlus: <IconPlus />,
	IconPng: <IconPng />,
	IconPodiumOff: <IconPodiumOff />,
	IconPodium: <IconPodium />,
	IconPointFilled: <IconPointFilled />,
	IconPointOff: <IconPointOff />,
	IconPoint: <IconPoint />,
	IconPointer: <IconPointer />,
	IconPokeballOff: <IconPokeballOff />,
	IconPokeball: <IconPokeball />,
	IconPokerChip: <IconPokerChip />,
	IconPolaroid: <IconPolaroid />,
	IconPolygonOff: <IconPolygonOff />,
	IconPolygon: <IconPolygon />,
	IconPoo: <IconPoo />,
	IconPoolOff: <IconPoolOff />,
	IconPool: <IconPool />,
	IconPower: <IconPower />,
	IconPray: <IconPray />,
	IconPremiumRights: <IconPremiumRights />,
	IconPrescription: <IconPrescription />,
	IconPresentationAnalytics: <IconPresentationAnalytics />,
	IconPresentationOff: <IconPresentationOff />,
	IconPresentation: <IconPresentation />,
	IconPrinterOff: <IconPrinterOff />,
	IconPrinter: <IconPrinter />,
	IconPrison: <IconPrison />,
	IconPrompt: <IconPrompt />,
	IconPropellerOff: <IconPropellerOff />,
	IconPropeller: <IconPropeller />,
	IconPumpkinScary: <IconPumpkinScary />,
	IconPuzzle2: <IconPuzzle2 />,
	IconPuzzleFilled: <IconPuzzleFilled />,
	IconPuzzleOff: <IconPuzzleOff />,
	IconPuzzle: <IconPuzzle />,
	IconPyramidOff: <IconPyramidOff />,
	IconPyramid: <IconPyramid />,
	IconQrcodeOff: <IconQrcodeOff />,
	IconQrcode: <IconQrcode />,
	IconQuestionMark: <IconQuestionMark />,
	IconQuoteOff: <IconQuoteOff />,
	IconQuote: <IconQuote />,
	IconRadar2: <IconRadar2 />,
	IconRadarOff: <IconRadarOff />,
	IconRadar: <IconRadar />,
	IconRadioOff: <IconRadioOff />,
	IconRadio: <IconRadio />,
	IconRadioactiveFilled: <IconRadioactiveFilled />,
	IconRadioactiveOff: <IconRadioactiveOff />,
	IconRadioactive: <IconRadioactive />,
	IconRadiusBottomLeft: <IconRadiusBottomLeft />,
	IconRadiusBottomRight: <IconRadiusBottomRight />,
	IconRadiusTopLeft: <IconRadiusTopLeft />,
	IconRadiusTopRight: <IconRadiusTopRight />,
	IconRainbowOff: <IconRainbowOff />,
	IconRainbow: <IconRainbow />,
	IconRating12Plus: <IconRating12Plus />,
	IconRating14Plus: <IconRating14Plus />,
	IconRating16Plus: <IconRating16Plus />,
	IconRating18Plus: <IconRating18Plus />,
	IconRating21Plus: <IconRating21Plus />,
	IconRazorElectric: <IconRazorElectric />,
	IconRazor: <IconRazor />,
	IconReceipt2: <IconReceipt2 />,
	IconReceiptOff: <IconReceiptOff />,
	IconReceiptRefund: <IconReceiptRefund />,
	IconReceiptTax: <IconReceiptTax />,
	IconReceipt: <IconReceipt />,
	IconRecharging: <IconRecharging />,
	IconRecordMailOff: <IconRecordMailOff />,
	IconRecordMail: <IconRecordMail />,
	IconRectangleFilled: <IconRectangleFilled />,
	IconRectangleVerticalFilled: <IconRectangleVerticalFilled />,
	IconRectangleVertical: <IconRectangleVertical />,
	IconRectangle: <IconRectangle />,
	IconRecycleOff: <IconRecycleOff />,
	IconRecycle: <IconRecycle />,
	IconRefreshAlert: <IconRefreshAlert />,
	IconRefreshDot: <IconRefreshDot />,
	IconRefreshOff: <IconRefreshOff />,
	IconRefresh: <IconRefresh />,
	IconRegexOff: <IconRegexOff />,
	IconRegex: <IconRegex />,
	IconRegistered: <IconRegistered />,
	IconRelationManyToMany: <IconRelationManyToMany />,
	IconRelationOneToMany: <IconRelationOneToMany />,
	IconRelationOneToOne: <IconRelationOneToOne />,
	IconReload: <IconReload />,
	IconRepeatOff: <IconRepeatOff />,
	IconRepeatOnce: <IconRepeatOnce />,
	IconRepeat: <IconRepeat />,
	IconReplaceFilled: <IconReplaceFilled />,
	IconReplaceOff: <IconReplaceOff />,
	IconReplace: <IconReplace />,
	IconReportAnalytics: <IconReportAnalytics />,
	IconReportMedical: <IconReportMedical />,
	IconReportMoney: <IconReportMoney />,
	IconReportOff: <IconReportOff />,
	IconReportSearch: <IconReportSearch />,
	IconReport: <IconReport />,
	IconResize: <IconResize />,
	IconRibbonHealth: <IconRibbonHealth />,
	IconRippleOff: <IconRippleOff />,
	IconRipple: <IconRipple />,
	IconRoadOff: <IconRoadOff />,
	IconRoadSign: <IconRoadSign />,
	IconRoad: <IconRoad />,
	IconRobotOff: <IconRobotOff />,
	IconRobot: <IconRobot />,
	IconRocketOff: <IconRocketOff />,
	IconRocket: <IconRocket />,
	IconRollerSkating: <IconRollerSkating />,
	IconRollercoasterOff: <IconRollercoasterOff />,
	IconRollercoaster: <IconRollercoaster />,
	IconRosetteFilled: <IconRosetteFilled />,
	IconRosetteNumber0: <IconRosetteNumber0 />,
	IconRosetteNumber1: <IconRosetteNumber1 />,
	IconRosetteNumber2: <IconRosetteNumber2 />,
	IconRosetteNumber3: <IconRosetteNumber3 />,
	IconRosetteNumber4: <IconRosetteNumber4 />,
	IconRosetteNumber5: <IconRosetteNumber5 />,
	IconRosetteNumber6: <IconRosetteNumber6 />,
	IconRosetteNumber7: <IconRosetteNumber7 />,
	IconRosetteNumber8: <IconRosetteNumber8 />,
	IconRosetteNumber9: <IconRosetteNumber9 />,
	IconRosette: <IconRosette />,
	IconRotate2: <IconRotate2 />,
	IconRotate360: <IconRotate360 />,
	IconRotateClockwise2: <IconRotateClockwise2 />,
	IconRotateClockwise: <IconRotateClockwise />,
	IconRotateDot: <IconRotateDot />,
	IconRotateRectangle: <IconRotateRectangle />,
	IconRotate: <IconRotate />,
	IconRoute2: <IconRoute2 />,
	IconRouteOff: <IconRouteOff />,
	IconRoute: <IconRoute />,
	IconRouterOff: <IconRouterOff />,
	IconRouter: <IconRouter />,
	IconRowInsertBottom: <IconRowInsertBottom />,
	IconRowInsertTop: <IconRowInsertTop />,
	IconRss: <IconRss />,
	IconRubberStampOff: <IconRubberStampOff />,
	IconRubberStamp: <IconRubberStamp />,
	IconRuler2Off: <IconRuler2Off />,
	IconRuler2: <IconRuler2 />,
	IconRuler3: <IconRuler3 />,
	IconRulerMeasure: <IconRulerMeasure />,
	IconRulerOff: <IconRulerOff />,
	IconRuler: <IconRuler />,
	IconRun: <IconRun />,
	IconSTurnDown: <IconSTurnDown />,
	IconSTurnLeft: <IconSTurnLeft />,
	IconSTurnRight: <IconSTurnRight />,
	IconSTurnUp: <IconSTurnUp />,
	IconSailboat2: <IconSailboat2 />,
	IconSailboatOff: <IconSailboatOff />,
	IconSailboat: <IconSailboat />,
	IconSalad: <IconSalad />,
	IconSalt: <IconSalt />,
	IconSatelliteOff: <IconSatelliteOff />,
	IconSatellite: <IconSatellite />,
	IconSausage: <IconSausage />,
	IconScaleOff: <IconScaleOff />,
	IconScaleOutlineOff: <IconScaleOutlineOff />,
	IconScaleOutline: <IconScaleOutline />,
	IconScale: <IconScale />,
	IconScanEye: <IconScanEye />,
	IconScan: <IconScan />,
	IconSchemaOff: <IconSchemaOff />,
	IconSchema: <IconSchema />,
	IconSchoolBell: <IconSchoolBell />,
	IconSchoolOff: <IconSchoolOff />,
	IconSchool: <IconSchool />,
	IconScissorsOff: <IconScissorsOff />,
	IconScissors: <IconScissors />,
	IconScooterElectric: <IconScooterElectric />,
	IconScooter: <IconScooter />,
	IconScreenShareOff: <IconScreenShareOff />,
	IconScreenShare: <IconScreenShare />,
	IconScreenshot: <IconScreenshot />,
	IconScribbleOff: <IconScribbleOff />,
	IconScribble: <IconScribble />,
	IconScriptMinus: <IconScriptMinus />,
	IconScriptPlus: <IconScriptPlus />,
	IconScriptX: <IconScriptX />,
	IconScript: <IconScript />,
	IconScubaMaskOff: <IconScubaMaskOff />,
	IconScubaMask: <IconScubaMask />,
	IconSdk: <IconSdk />,
	IconSearchOff: <IconSearchOff />,
	IconSearch: <IconSearch />,
	IconSectionSign: <IconSectionSign />,
	IconSection: <IconSection />,
	IconSeedingOff: <IconSeedingOff />,
	IconSeeding: <IconSeeding />,
	IconSelect: <IconSelect />,
	IconSelector: <IconSelector />,
	IconSendOff: <IconSendOff />,
	IconSend: <IconSend />,
	IconSeo: <IconSeo />,
	IconSeparatorHorizontal: <IconSeparatorHorizontal />,
	IconSeparatorVertical: <IconSeparatorVertical />,
	IconSeparator: <IconSeparator />,
	IconServer2: <IconServer2 />,
	IconServerBolt: <IconServerBolt />,
	IconServerCog: <IconServerCog />,
	IconServerOff: <IconServerOff />,
	IconServer: <IconServer />,
	IconServicemark: <IconServicemark />,
	IconSettings2: <IconSettings2 />,
	IconSettingsAutomation: <IconSettingsAutomation />,
	IconSettingsFilled: <IconSettingsFilled />,
	IconSettingsOff: <IconSettingsOff />,
	IconSettings: <IconSettings />,
	IconShadowOff: <IconShadowOff />,
	IconShadow: <IconShadow />,
	IconShape2: <IconShape2 />,
	IconShape3: <IconShape3 />,
	IconShapeOff: <IconShapeOff />,
	IconShape: <IconShape />,
	IconShare2: <IconShare2 />,
	IconShareOff: <IconShareOff />,
	IconShare: <IconShare />,
	IconShieldCheckFilled: <IconShieldCheckFilled />,
	IconShieldCheck: <IconShieldCheck />,
	IconShieldCheckeredFilled: <IconShieldCheckeredFilled />,
	IconShieldCheckered: <IconShieldCheckered />,
	IconShieldChevron: <IconShieldChevron />,
	IconShieldFilled: <IconShieldFilled />,
	IconShieldHalfFilled: <IconShieldHalfFilled />,
	IconShieldHalf: <IconShieldHalf />,
	IconShieldLockFilled: <IconShieldLockFilled />,
	IconShieldLock: <IconShieldLock />,
	IconShieldOff: <IconShieldOff />,
	IconShieldX: <IconShieldX />,
	IconShield: <IconShield />,
	IconShipOff: <IconShipOff />,
	IconShip: <IconShip />,
	IconShirtFilled: <IconShirtFilled />,
	IconShirtOff: <IconShirtOff />,
	IconShirtSport: <IconShirtSport />,
	IconShirt: <IconShirt />,
	IconShoeOff: <IconShoeOff />,
	IconShoe: <IconShoe />,
	IconShoppingBag: <IconShoppingBag />,
	IconShoppingCartDiscount: <IconShoppingCartDiscount />,
	IconShoppingCartOff: <IconShoppingCartOff />,
	IconShoppingCartPlus: <IconShoppingCartPlus />,
	IconShoppingCartX: <IconShoppingCartX />,
	IconShoppingCart: <IconShoppingCart />,
	IconShovel: <IconShovel />,
	IconShredder: <IconShredder />,
	IconSignLeftFilled: <IconSignLeftFilled />,
	IconSignLeft: <IconSignLeft />,
	IconSignRightFilled: <IconSignRightFilled />,
	IconSignRight: <IconSignRight />,
	IconSignal2g: <IconSignal2g />,
	IconSignal3g: <IconSignal3g />,
	IconSignal4gPlus: <IconSignal4gPlus />,
	IconSignal4g: <IconSignal4g />,
	IconSignal5g: <IconSignal5g />,
	IconSignatureOff: <IconSignatureOff />,
	IconSignature: <IconSignature />,
	IconSitemapOff: <IconSitemapOff />,
	IconSitemap: <IconSitemap />,
	IconSkateboardOff: <IconSkateboardOff />,
	IconSkateboard: <IconSkateboard />,
	IconSkull: <IconSkull />,
	IconSlash: <IconSlash />,
	IconSlashes: <IconSlashes />,
	IconSleigh: <IconSleigh />,
	IconSlice: <IconSlice />,
	IconSlideshow: <IconSlideshow />,
	IconSmartHomeOff: <IconSmartHomeOff />,
	IconSmartHome: <IconSmartHome />,
	IconSmokingNo: <IconSmokingNo />,
	IconSmoking: <IconSmoking />,
	IconSnowflakeOff: <IconSnowflakeOff />,
	IconSnowflake: <IconSnowflake />,
	IconSnowman: <IconSnowman />,
	IconSoccerField: <IconSoccerField />,
	IconSocialOff: <IconSocialOff />,
	IconSocial: <IconSocial />,
	IconSock: <IconSock />,
	IconSofaOff: <IconSofaOff />,
	IconSofa: <IconSofa />,
	IconSort09: <IconSort09 />,
	IconSort90: <IconSort90 />,
	IconSortAZ: <IconSortAZ />,
	IconSortAscending2: <IconSortAscending2 />,
	IconSortAscendingLetters: <IconSortAscendingLetters />,
	IconSortAscendingNumbers: <IconSortAscendingNumbers />,
	IconSortAscending: <IconSortAscending />,
	IconSortDescending2: <IconSortDescending2 />,
	IconSortDescendingLetters: <IconSortDescendingLetters />,
	IconSortDescendingNumbers: <IconSortDescendingNumbers />,
	IconSortDescending: <IconSortDescending />,
	IconSortZA: <IconSortZA />,
	IconSos: <IconSos />,
	IconSoupOff: <IconSoupOff />,
	IconSoup: <IconSoup />,
	IconSourceCode: <IconSourceCode />,
	IconSpaceOff: <IconSpaceOff />,
	IconSpace: <IconSpace />,
	IconSpacingHorizontal: <IconSpacingHorizontal />,
	IconSpacingVertical: <IconSpacingVertical />,
	IconSpadeFilled: <IconSpadeFilled />,
	IconSpade: <IconSpade />,
	IconSparkles: <IconSparkles />,
	IconSpeakerphone: <IconSpeakerphone />,
	IconSpeedboat: <IconSpeedboat />,
	IconSpider: <IconSpider />,
	IconSpiralOff: <IconSpiralOff />,
	IconSpiral: <IconSpiral />,
	IconSportBillard: <IconSportBillard />,
	IconSpray: <IconSpray />,
	IconSpyOff: <IconSpyOff />,
	IconSpy: <IconSpy />,
	IconSquare0Filled: <IconSquare0Filled />,
	IconSquare1Filled: <IconSquare1Filled />,
	IconSquare3Filled: <IconSquare3Filled />,
	IconSquare4Filled: <IconSquare4Filled />,
	IconSquare5Filled: <IconSquare5Filled />,
	IconSquare6Filled: <IconSquare6Filled />,
	IconSquare7Filled: <IconSquare7Filled />,
	IconSquare8Filled: <IconSquare8Filled />,
	IconSquare9Filled: <IconSquare9Filled />,
	IconSquareArrowDown: <IconSquareArrowDown />,
	IconSquareArrowLeft: <IconSquareArrowLeft />,
	IconSquareArrowRight: <IconSquareArrowRight />,
	IconSquareArrowUp: <IconSquareArrowUp />,
	IconSquareAsterisk: <IconSquareAsterisk />,
	IconSquareCheckFilled: <IconSquareCheckFilled />,
	IconSquareCheck: <IconSquareCheck />,
	IconSquareChevronDown: <IconSquareChevronDown />,
	IconSquareChevronLeft: <IconSquareChevronLeft />,
	IconSquareChevronRight: <IconSquareChevronRight />,
	IconSquareChevronUp: <IconSquareChevronUp />,
	IconSquareChevronsDown: <IconSquareChevronsDown />,
	IconSquareChevronsLeft: <IconSquareChevronsLeft />,
	IconSquareChevronsRight: <IconSquareChevronsRight />,
	IconSquareChevronsUp: <IconSquareChevronsUp />,
	IconSquareDot: <IconSquareDot />,
	IconSquareF0Filled: <IconSquareF0Filled />,
	IconSquareF0: <IconSquareF0 />,
	IconSquareF1Filled: <IconSquareF1Filled />,
	IconSquareF1: <IconSquareF1 />,
	IconSquareF2Filled: <IconSquareF2Filled />,
	IconSquareF2: <IconSquareF2 />,
	IconSquareF3Filled: <IconSquareF3Filled />,
	IconSquareF3: <IconSquareF3 />,
	IconSquareF4Filled: <IconSquareF4Filled />,
	IconSquareF4: <IconSquareF4 />,
	IconSquareF5Filled: <IconSquareF5Filled />,
	IconSquareF5: <IconSquareF5 />,
	IconSquareF6Filled: <IconSquareF6Filled />,
	IconSquareF6: <IconSquareF6 />,
	IconSquareF7Filled: <IconSquareF7Filled />,
	IconSquareF7: <IconSquareF7 />,
	IconSquareF8Filled: <IconSquareF8Filled />,
	IconSquareF8: <IconSquareF8 />,
	IconSquareF9Filled: <IconSquareF9Filled />,
	IconSquareF9: <IconSquareF9 />,
	IconSquareForbid2: <IconSquareForbid2 />,
	IconSquareForbid: <IconSquareForbid />,
	IconSquareHalf: <IconSquareHalf />,
	IconSquareKey: <IconSquareKey />,
	IconSquareLetterA: <IconSquareLetterA />,
	IconSquareLetterB: <IconSquareLetterB />,
	IconSquareLetterC: <IconSquareLetterC />,
	IconSquareLetterD: <IconSquareLetterD />,
	IconSquareLetterE: <IconSquareLetterE />,
	IconSquareLetterF: <IconSquareLetterF />,
	IconSquareLetterG: <IconSquareLetterG />,
	IconSquareLetterH: <IconSquareLetterH />,
	IconSquareLetterI: <IconSquareLetterI />,
	IconSquareLetterJ: <IconSquareLetterJ />,
	IconSquareLetterK: <IconSquareLetterK />,
	IconSquareLetterL: <IconSquareLetterL />,
	IconSquareLetterM: <IconSquareLetterM />,
	IconSquareLetterN: <IconSquareLetterN />,
	IconSquareLetterO: <IconSquareLetterO />,
	IconSquareLetterP: <IconSquareLetterP />,
	IconSquareLetterQ: <IconSquareLetterQ />,
	IconSquareLetterR: <IconSquareLetterR />,
	IconSquareLetterS: <IconSquareLetterS />,
	IconSquareLetterT: <IconSquareLetterT />,
	IconSquareLetterU: <IconSquareLetterU />,
	IconSquareLetterV: <IconSquareLetterV />,
	IconSquareLetterW: <IconSquareLetterW />,
	IconSquareLetterX: <IconSquareLetterX />,
	IconSquareLetterY: <IconSquareLetterY />,
	IconSquareLetterZ: <IconSquareLetterZ />,
	IconSquareMinus: <IconSquareMinus />,
	IconSquareNumber0: <IconSquareNumber0 />,
	IconSquareNumber1: <IconSquareNumber1 />,
	IconSquareNumber2: <IconSquareNumber2 />,
	IconSquareNumber3: <IconSquareNumber3 />,
	IconSquareNumber4: <IconSquareNumber4 />,
	IconSquareNumber5: <IconSquareNumber5 />,
	IconSquareNumber6: <IconSquareNumber6 />,
	IconSquareNumber7: <IconSquareNumber7 />,
	IconSquareNumber8: <IconSquareNumber8 />,
	IconSquareNumber9: <IconSquareNumber9 />,
	IconSquareOff: <IconSquareOff />,
	IconSquarePlus: <IconSquarePlus />,
	IconSquareRoot2: <IconSquareRoot2 />,
	IconSquareRoot: <IconSquareRoot />,
	IconSquareRotatedFilled: <IconSquareRotatedFilled />,
	IconSquareRotatedForbid2: <IconSquareRotatedForbid2 />,
	IconSquareRotatedForbid: <IconSquareRotatedForbid />,
	IconSquareRotatedOff: <IconSquareRotatedOff />,
	IconSquareRotated: <IconSquareRotated />,
	IconSquareRoundedArrowDownFilled: <IconSquareRoundedArrowDownFilled />,
	IconSquareRoundedArrowDown: <IconSquareRoundedArrowDown />,
	IconSquareRoundedArrowLeftFilled: <IconSquareRoundedArrowLeftFilled />,
	IconSquareRoundedArrowLeft: <IconSquareRoundedArrowLeft />,
	IconSquareRoundedArrowRightFilled: <IconSquareRoundedArrowRightFilled />,
	IconSquareRoundedArrowRight: <IconSquareRoundedArrowRight />,
	IconSquareRoundedArrowUpFilled: <IconSquareRoundedArrowUpFilled />,
	IconSquareRoundedArrowUp: <IconSquareRoundedArrowUp />,
	IconSquareRoundedCheckFilled: <IconSquareRoundedCheckFilled />,
	IconSquareRoundedCheck: <IconSquareRoundedCheck />,
	IconSquareRoundedChevronDownFilled: <IconSquareRoundedChevronDownFilled />,
	IconSquareRoundedChevronDown: <IconSquareRoundedChevronDown />,
	IconSquareRoundedChevronLeftFilled: <IconSquareRoundedChevronLeftFilled />,
	IconSquareRoundedChevronLeft: <IconSquareRoundedChevronLeft />,
	IconSquareRoundedChevronRightFilled: <IconSquareRoundedChevronRightFilled />,
	IconSquareRoundedChevronRight: <IconSquareRoundedChevronRight />,
	IconSquareRoundedChevronUpFilled: <IconSquareRoundedChevronUpFilled />,
	IconSquareRoundedChevronUp: <IconSquareRoundedChevronUp />,
	IconSquareRoundedChevronsDownFilled: <IconSquareRoundedChevronsDownFilled />,
	IconSquareRoundedChevronsDown: <IconSquareRoundedChevronsDown />,
	IconSquareRoundedChevronsLeftFilled: <IconSquareRoundedChevronsLeftFilled />,
	IconSquareRoundedChevronsLeft: <IconSquareRoundedChevronsLeft />,
	IconSquareRoundedChevronsRightFilled: <IconSquareRoundedChevronsRightFilled />,
	IconSquareRoundedChevronsRight: <IconSquareRoundedChevronsRight />,
	IconSquareRoundedChevronsUpFilled: <IconSquareRoundedChevronsUpFilled />,
	IconSquareRoundedChevronsUp: <IconSquareRoundedChevronsUp />,
	IconSquareRoundedFilled: <IconSquareRoundedFilled />,
	IconSquareRoundedLetterA: <IconSquareRoundedLetterA />,
	IconSquareRoundedLetterB: <IconSquareRoundedLetterB />,
	IconSquareRoundedLetterC: <IconSquareRoundedLetterC />,
	IconSquareRoundedLetterD: <IconSquareRoundedLetterD />,
	IconSquareRoundedLetterE: <IconSquareRoundedLetterE />,
	IconSquareRoundedLetterF: <IconSquareRoundedLetterF />,
	IconSquareRoundedLetterG: <IconSquareRoundedLetterG />,
	IconSquareRoundedLetterH: <IconSquareRoundedLetterH />,
	IconSquareRoundedLetterI: <IconSquareRoundedLetterI />,
	IconSquareRoundedLetterJ: <IconSquareRoundedLetterJ />,
	IconSquareRoundedLetterK: <IconSquareRoundedLetterK />,
	IconSquareRoundedLetterL: <IconSquareRoundedLetterL />,
	IconSquareRoundedLetterM: <IconSquareRoundedLetterM />,
	IconSquareRoundedLetterN: <IconSquareRoundedLetterN />,
	IconSquareRoundedLetterO: <IconSquareRoundedLetterO />,
	IconSquareRoundedLetterP: <IconSquareRoundedLetterP />,
	IconSquareRoundedLetterQ: <IconSquareRoundedLetterQ />,
	IconSquareRoundedLetterR: <IconSquareRoundedLetterR />,
	IconSquareRoundedLetterS: <IconSquareRoundedLetterS />,
	IconSquareRoundedLetterT: <IconSquareRoundedLetterT />,
	IconSquareRoundedLetterU: <IconSquareRoundedLetterU />,
	IconSquareRoundedLetterV: <IconSquareRoundedLetterV />,
	IconSquareRoundedLetterW: <IconSquareRoundedLetterW />,
	IconSquareRoundedLetterX: <IconSquareRoundedLetterX />,
	IconSquareRoundedLetterY: <IconSquareRoundedLetterY />,
	IconSquareRoundedLetterZ: <IconSquareRoundedLetterZ />,
	IconSquareRoundedMinus: <IconSquareRoundedMinus />,
	IconSquareRoundedNumber0Filled: <IconSquareRoundedNumber0Filled />,
	IconSquareRoundedNumber0: <IconSquareRoundedNumber0 />,
	IconSquareRoundedNumber1Filled: <IconSquareRoundedNumber1Filled />,
	IconSquareRoundedNumber1: <IconSquareRoundedNumber1 />,
	IconSquareRoundedNumber2Filled: <IconSquareRoundedNumber2Filled />,
	IconSquareRoundedNumber2: <IconSquareRoundedNumber2 />,
	IconSquareRoundedNumber3Filled: <IconSquareRoundedNumber3Filled />,
	IconSquareRoundedNumber3: <IconSquareRoundedNumber3 />,
	IconSquareRoundedNumber4Filled: <IconSquareRoundedNumber4Filled />,
	IconSquareRoundedNumber4: <IconSquareRoundedNumber4 />,
	IconSquareRoundedNumber5Filled: <IconSquareRoundedNumber5Filled />,
	IconSquareRoundedNumber5: <IconSquareRoundedNumber5 />,
	IconSquareRoundedNumber6Filled: <IconSquareRoundedNumber6Filled />,
	IconSquareRoundedNumber6: <IconSquareRoundedNumber6 />,
	IconSquareRoundedNumber7Filled: <IconSquareRoundedNumber7Filled />,
	IconSquareRoundedNumber7: <IconSquareRoundedNumber7 />,
	IconSquareRoundedNumber8Filled: <IconSquareRoundedNumber8Filled />,
	IconSquareRoundedNumber8: <IconSquareRoundedNumber8 />,
	IconSquareRoundedNumber9Filled: <IconSquareRoundedNumber9Filled />,
	IconSquareRoundedNumber9: <IconSquareRoundedNumber9 />,
	IconSquareRoundedPlusFilled: <IconSquareRoundedPlusFilled />,
	IconSquareRoundedPlus: <IconSquareRoundedPlus />,
	IconSquareRoundedXFilled: <IconSquareRoundedXFilled />,
	IconSquareRoundedX: <IconSquareRoundedX />,
	IconSquareRounded: <IconSquareRounded />,
	IconSquareToggleHorizontal: <IconSquareToggleHorizontal />,
	IconSquareToggle: <IconSquareToggle />,
	IconSquareX: <IconSquareX />,
	IconSquare: <IconSquare />,
	IconSquaresDiagonal: <IconSquaresDiagonal />,
	IconSquaresFilled: <IconSquaresFilled />,
	IconStack2: <IconStack2 />,
	IconStack3: <IconStack3 />,
	IconStackPop: <IconStackPop />,
	IconStackPush: <IconStackPush />,
	IconStack: <IconStack />,
	IconStairsDown: <IconStairsDown />,
	IconStairsUp: <IconStairsUp />,
	IconStairs: <IconStairs />,
	IconStarFilled: <IconStarFilled />,
	IconStarHalfFilled: <IconStarHalfFilled />,
	IconStarHalf: <IconStarHalf />,
	IconStarOff: <IconStarOff />,
	IconStar: <IconStar />,
	IconStarsFilled: <IconStarsFilled />,
	IconStarsOff: <IconStarsOff />,
	IconStars: <IconStars />,
	IconStatusChange: <IconStatusChange />,
	IconSteam: <IconSteam />,
	IconSteeringWheelOff: <IconSteeringWheelOff />,
	IconSteeringWheel: <IconSteeringWheel />,
	IconStepInto: <IconStepInto />,
	IconStepOut: <IconStepOut />,
	IconStereoGlasses: <IconStereoGlasses />,
	IconStethoscopeOff: <IconStethoscopeOff />,
	IconStethoscope: <IconStethoscope />,
	IconSticker: <IconSticker />,
	IconStormOff: <IconStormOff />,
	IconStorm: <IconStorm />,
	IconStretching: <IconStretching />,
	IconStrikethrough: <IconStrikethrough />,
	IconSubmarine: <IconSubmarine />,
	IconSubscript: <IconSubscript />,
	IconSubtask: <IconSubtask />,
	IconSumOff: <IconSumOff />,
	IconSum: <IconSum />,
	IconSunFilled: <IconSunFilled />,
	IconSunHigh: <IconSunHigh />,
	IconSunLow: <IconSunLow />,
	IconSunMoon: <IconSunMoon />,
	IconSunOff: <IconSunOff />,
	IconSunWind: <IconSunWind />,
	IconSun: <IconSun />,
	IconSunglasses: <IconSunglasses />,
	IconSunrise: <IconSunrise />,
	IconSunset2: <IconSunset2 />,
	IconSunset: <IconSunset />,
	IconSuperscript: <IconSuperscript />,
	IconSvg: <IconSvg />,
	IconSwimming: <IconSwimming />,
	IconSwipe: <IconSwipe />,
	IconSwitch2: <IconSwitch2 />,
	IconSwitch3: <IconSwitch3 />,
	IconSwitchHorizontal: <IconSwitchHorizontal />,
	IconSwitchVertical: <IconSwitchVertical />,
	IconSwitch: <IconSwitch />,
	IconSwordOff: <IconSwordOff />,
	IconSword: <IconSword />,
	IconSwords: <IconSwords />,
	IconTableAlias: <IconTableAlias />,
	IconTableExport: <IconTableExport />,
	IconTableFilled: <IconTableFilled />,
	IconTableImport: <IconTableImport />,
	IconTableOff: <IconTableOff />,
	IconTableOptions: <IconTableOptions />,
	IconTableShortcut: <IconTableShortcut />,
	IconTable: <IconTable />,
	IconTagOff: <IconTagOff />,
	IconTag: <IconTag />,
	IconTagsOff: <IconTagsOff />,
	IconTags: <IconTags />,
	IconTallymark1: <IconTallymark1 />,
	IconTallymark2: <IconTallymark2 />,
	IconTallymark3: <IconTallymark3 />,
	IconTallymark4: <IconTallymark4 />,
	IconTallymarks: <IconTallymarks />,
	IconTank: <IconTank />,
	IconTargetArrow: <IconTargetArrow />,
	IconTargetOff: <IconTargetOff />,
	IconTarget: <IconTarget />,
	IconTeapot: <IconTeapot />,
	IconTelescopeOff: <IconTelescopeOff />,
	IconTelescope: <IconTelescope />,
	IconTemperatureCelsius: <IconTemperatureCelsius />,
	IconTemperatureFahrenheit: <IconTemperatureFahrenheit />,
	IconTemperatureMinus: <IconTemperatureMinus />,
	IconTemperatureOff: <IconTemperatureOff />,
	IconTemperaturePlus: <IconTemperaturePlus />,
	IconTemperature: <IconTemperature />,
	IconTemplateOff: <IconTemplateOff />,
	IconTemplate: <IconTemplate />,
	IconTentOff: <IconTentOff />,
	IconTent: <IconTent />,
	IconTerminal2: <IconTerminal2 />,
	IconTerminal: <IconTerminal />,
	IconTestPipe2: <IconTestPipe2 />,
	IconTestPipeOff: <IconTestPipeOff />,
	IconTestPipe: <IconTestPipe />,
	IconTex: <IconTex />,
	IconTextCaption: <IconTextCaption />,
	IconTextColor: <IconTextColor />,
	IconTextDecrease: <IconTextDecrease />,
	IconTextDirectionLtr: <IconTextDirectionLtr />,
	IconTextDirectionRtl: <IconTextDirectionRtl />,
	IconTextIncrease: <IconTextIncrease />,
	IconTextOrientation: <IconTextOrientation />,
	IconTextPlus: <IconTextPlus />,
	IconTextRecognition: <IconTextRecognition />,
	IconTextResize: <IconTextResize />,
	IconTextSize: <IconTextSize />,
	IconTextSpellcheck: <IconTextSpellcheck />,
	IconTextWrapDisabled: <IconTextWrapDisabled />,
	IconTextWrap: <IconTextWrap />,
	IconTexture: <IconTexture />,
	IconTheater: <IconTheater />,
	IconThermometer: <IconThermometer />,
	IconThumbDownFilled: <IconThumbDownFilled />,
	IconThumbDownOff: <IconThumbDownOff />,
	IconThumbDown: <IconThumbDown />,
	IconThumbUpFilled: <IconThumbUpFilled />,
	IconThumbUpOff: <IconThumbUpOff />,
	IconThumbUp: <IconThumbUp />,
	IconTicTac: <IconTicTac />,
	IconTicketOff: <IconTicketOff />,
	IconTicket: <IconTicket />,
	IconTie: <IconTie />,
	IconTilde: <IconTilde />,
	IconTiltShiftOff: <IconTiltShiftOff />,
	IconTiltShift: <IconTiltShift />,
	IconTimelineEventExclamation: <IconTimelineEventExclamation />,
	IconTimelineEventMinus: <IconTimelineEventMinus />,
	IconTimelineEventPlus: <IconTimelineEventPlus />,
	IconTimelineEventText: <IconTimelineEventText />,
	IconTimelineEventX: <IconTimelineEventX />,
	IconTimelineEvent: <IconTimelineEvent />,
	IconTimeline: <IconTimeline />,
	IconTir: <IconTir />,
	IconToggleLeft: <IconToggleLeft />,
	IconToggleRight: <IconToggleRight />,
	IconToiletPaperOff: <IconToiletPaperOff />,
	IconToiletPaper: <IconToiletPaper />,
	IconTool: <IconTool />,
	IconToolsKitchen2Off: <IconToolsKitchen2Off />,
	IconToolsKitchen2: <IconToolsKitchen2 />,
	IconToolsKitchenOff: <IconToolsKitchenOff />,
	IconToolsKitchen: <IconToolsKitchen />,
	IconToolsOff: <IconToolsOff />,
	IconTools: <IconTools />,
	IconTooltip: <IconTooltip />,
	IconTopologyBus: <IconTopologyBus />,
	IconTopologyComplex: <IconTopologyComplex />,
	IconTopologyFullHierarchy: <IconTopologyFullHierarchy />,
	IconTopologyFull: <IconTopologyFull />,
	IconTopologyRing2: <IconTopologyRing2 />,
	IconTopologyRing3: <IconTopologyRing3 />,
	IconTopologyRing: <IconTopologyRing />,
	IconTopologyStar2: <IconTopologyStar2 />,
	IconTopologyStar3: <IconTopologyStar3 />,
	IconTopologyStarRing2: <IconTopologyStarRing2 />,
	IconTopologyStarRing3: <IconTopologyStarRing3 />,
	IconTopologyStarRing: <IconTopologyStarRing />,
	IconTopologyStar: <IconTopologyStar />,
	IconTorii: <IconTorii />,
	IconTornado: <IconTornado />,
	IconTournament: <IconTournament />,
	IconTowerOff: <IconTowerOff />,
	IconTower: <IconTower />,
	IconTrack: <IconTrack />,
	IconTractor: <IconTractor />,
	IconTrademark: <IconTrademark />,
	IconTrafficConeOff: <IconTrafficConeOff />,
	IconTrafficCone: <IconTrafficCone />,
	IconTrafficLightsOff: <IconTrafficLightsOff />,
	IconTrafficLights: <IconTrafficLights />,
	IconTrain: <IconTrain />,
	IconTransferIn: <IconTransferIn />,
	IconTransferOut: <IconTransferOut />,
	IconTransformFilled: <IconTransformFilled />,
	IconTransform: <IconTransform />,
	IconTransitionBottom: <IconTransitionBottom />,
	IconTransitionLeft: <IconTransitionLeft />,
	IconTransitionRight: <IconTransitionRight />,
	IconTransitionTop: <IconTransitionTop />,
	IconTrashFilled: <IconTrashFilled />,
	IconTrashOff: <IconTrashOff />,
	IconTrashXFilled: <IconTrashXFilled />,
	IconTrashX: <IconTrashX />,
	IconTrash: <IconTrash />,
	IconTree: <IconTree />,
	IconTrees: <IconTrees />,
	IconTrekking: <IconTrekking />,
	IconTrendingDown2: <IconTrendingDown2 />,
	IconTrendingDown3: <IconTrendingDown3 />,
	IconTrendingDown: <IconTrendingDown />,
	IconTrendingUp2: <IconTrendingUp2 />,
	IconTrendingUp3: <IconTrendingUp3 />,
	IconTrendingUp: <IconTrendingUp />,
	IconTriangleFilled: <IconTriangleFilled />,
	IconTriangleInvertedFilled: <IconTriangleInvertedFilled />,
	IconTriangleInverted: <IconTriangleInverted />,
	IconTriangleOff: <IconTriangleOff />,
	IconTriangleSquareCircle: <IconTriangleSquareCircle />,
	IconTriangle: <IconTriangle />,
	IconTriangles: <IconTriangles />,
	IconTrident: <IconTrident />,
	IconTrolley: <IconTrolley />,
	IconTrophyFilled: <IconTrophyFilled />,
	IconTrophyOff: <IconTrophyOff />,
	IconTrophy: <IconTrophy />,
	IconTrowel: <IconTrowel />,
	IconTruckDelivery: <IconTruckDelivery />,
	IconTruckLoading: <IconTruckLoading />,
	IconTruckOff: <IconTruckOff />,
	IconTruckReturn: <IconTruckReturn />,
	IconTruck: <IconTruck />,
	IconTxt: <IconTxt />,
	IconTypographyOff: <IconTypographyOff />,
	IconTypography: <IconTypography />,
	IconUfoOff: <IconUfoOff />,
	IconUfo: <IconUfo />,
	IconUmbrellaFilled: <IconUmbrellaFilled />,
	IconUmbrellaOff: <IconUmbrellaOff />,
	IconUmbrella: <IconUmbrella />,
	IconUnderline: <IconUnderline />,
	IconUnlink: <IconUnlink />,
	IconUpload: <IconUpload />,
	IconUrgent: <IconUrgent />,
	IconUsb: <IconUsb />,
	IconUserCheck: <IconUserCheck />,
	IconUserCircle: <IconUserCircle />,
	IconUserExclamation: <IconUserExclamation />,
	IconUserMinus: <IconUserMinus />,
	IconUserOff: <IconUserOff />,
	IconUserPlus: <IconUserPlus />,
	IconUserSearch: <IconUserSearch />,
	IconUserX: <IconUserX />,
	IconUser: <IconUser />,
	IconUsers: <IconUsers />,
	IconUserCog: <IconUserCog />,
	IconUvIndex: <IconUvIndex />,
	IconUxCircle: <IconUxCircle />,
	IconVaccineBottleOff: <IconVaccineBottleOff />,
	IconVaccineBottle: <IconVaccineBottle />,
	IconVaccineOff: <IconVaccineOff />,
	IconVaccine: <IconVaccine />,
	IconVacuumCleaner: <IconVacuumCleaner />,
	IconVariableMinus: <IconVariableMinus />,
	IconVariableOff: <IconVariableOff />,
	IconVariablePlus: <IconVariablePlus />,
	IconVariable: <IconVariable />,
	IconVectorBezier2: <IconVectorBezier2 />,
	IconVectorBezierArc: <IconVectorBezierArc />,
	IconVectorBezierCircle: <IconVectorBezierCircle />,
	IconVectorBezier: <IconVectorBezier />,
	IconVectorOff: <IconVectorOff />,
	IconVectorSpline: <IconVectorSpline />,
	IconVectorTriangleOff: <IconVectorTriangleOff />,
	IconVectorTriangle: <IconVectorTriangle />,
	IconVector: <IconVector />,
	IconVenus: <IconVenus />,
	IconVersionsFilled: <IconVersionsFilled />,
	IconVersionsOff: <IconVersionsOff />,
	IconVersions: <IconVersions />,
	IconVideoMinus: <IconVideoMinus />,
	IconVideoOff: <IconVideoOff />,
	IconVideoPlus: <IconVideoPlus />,
	IconVideo: <IconVideo />,
	IconView360Off: <IconView360Off />,
	IconView360: <IconView360 />,
	IconViewfinderOff: <IconViewfinderOff />,
	IconViewfinder: <IconViewfinder />,
	IconViewportNarrow: <IconViewportNarrow />,
	IconViewportWide: <IconViewportWide />,
	IconVinyl: <IconVinyl />,
	IconVipOff: <IconVipOff />,
	IconVip: <IconVip />,
	IconVirusOff: <IconVirusOff />,
	IconVirusSearch: <IconVirusSearch />,
	IconVirus: <IconVirus />,
	IconVocabularyOff: <IconVocabularyOff />,
	IconVocabulary: <IconVocabulary />,
	IconVolcano: <IconVolcano />,
	IconVolume2: <IconVolume2 />,
	IconVolume3: <IconVolume3 />,
	IconVolumeOff: <IconVolumeOff />,
	IconVolume: <IconVolume />,
	IconWalk: <IconWalk />,
	IconWallOff: <IconWallOff />,
	IconWall: <IconWall />,
	IconWalletOff: <IconWalletOff />,
	IconWallet: <IconWallet />,
	IconWallpaperOff: <IconWallpaperOff />,
	IconWallpaper: <IconWallpaper />,
	IconWandOff: <IconWandOff />,
	IconWand: <IconWand />,
	IconWashDry1: <IconWashDry1 />,
	IconWashDry2: <IconWashDry2 />,
	IconWashDry3: <IconWashDry3 />,
	IconWashDryA: <IconWashDryA />,
	IconWashDryDip: <IconWashDryDip />,
	IconWashDryF: <IconWashDryF />,
	IconWashDryHang: <IconWashDryHang />,
	IconWashDryOff: <IconWashDryOff />,
	IconWashDryP: <IconWashDryP />,
	IconWashDryShade: <IconWashDryShade />,
	IconWashDryW: <IconWashDryW />,
	IconWashDry: <IconWashDry />,
	IconWashDrycleanOff: <IconWashDrycleanOff />,
	IconWashDryclean: <IconWashDryclean />,
	IconWashGentle: <IconWashGentle />,
	IconWashMachine: <IconWashMachine />,
	IconWashOff: <IconWashOff />,
	IconWashPress: <IconWashPress />,
	IconWashTemperature1: <IconWashTemperature1 />,
	IconWashTemperature2: <IconWashTemperature2 />,
	IconWashTemperature3: <IconWashTemperature3 />,
	IconWashTemperature4: <IconWashTemperature4 />,
	IconWashTemperature5: <IconWashTemperature5 />,
	IconWashTemperature6: <IconWashTemperature6 />,
	IconWashTumbleDry: <IconWashTumbleDry />,
	IconWashTumbleOff: <IconWashTumbleOff />,
	IconWash: <IconWash />,
	IconWaveSawTool: <IconWaveSawTool />,
	IconWaveSine: <IconWaveSine />,
	IconWaveSquare: <IconWaveSquare />,
	IconWebhookOff: <IconWebhookOff />,
	IconWebhook: <IconWebhook />,
	IconWeight: <IconWeight />,
	IconWheelchairOff: <IconWheelchairOff />,
	IconWheelchair: <IconWheelchair />,
	IconWhirl: <IconWhirl />,
	IconWifi0: <IconWifi0 />,
	IconWifi1: <IconWifi1 />,
	IconWifi2: <IconWifi2 />,
	IconWifiOff: <IconWifiOff />,
	IconWifi: <IconWifi />,
	IconWindOff: <IconWindOff />,
	IconWind: <IconWind />,
	IconWindmillFilled: <IconWindmillFilled />,
	IconWindmillOff: <IconWindmillOff />,
	IconWindmill: <IconWindmill />,
	IconWindowMaximize: <IconWindowMaximize />,
	IconWindowMinimize: <IconWindowMinimize />,
	IconWindowOff: <IconWindowOff />,
	IconWindow: <IconWindow />,
	IconWindsock: <IconWindsock />,
	IconWiperWash: <IconWiperWash />,
	IconWiper: <IconWiper />,
	IconWoman: <IconWoman />,
	IconWood: <IconWood />,
	IconWorldDownload: <IconWorldDownload />,
	IconWorldLatitude: <IconWorldLatitude />,
	IconWorldLongitude: <IconWorldLongitude />,
	IconWorldOff: <IconWorldOff />,
	IconWorldUpload: <IconWorldUpload />,
	IconWorldWww: <IconWorldWww />,
	IconWorld: <IconWorld />,
	IconWreckingBall: <IconWreckingBall />,
	IconWritingOff: <IconWritingOff />,
	IconWritingSignOff: <IconWritingSignOff />,
	IconWritingSign: <IconWritingSign />,
	IconWriting: <IconWriting />,
	IconX: <IconX />,
	IconXboxA: <IconXboxA />,
	IconXboxB: <IconXboxB />,
	IconXboxX: <IconXboxX />,
	IconXboxY: <IconXboxY />,
	IconYinYangFilled: <IconYinYangFilled />,
	IconYinYang: <IconYinYang />,
	IconYoga: <IconYoga />,
	IconZeppelinOff: <IconZeppelinOff />,
	IconZeppelin: <IconZeppelin />,
	IconZip: <IconZip />,
	IconZodiacAquarius: <IconZodiacAquarius />,
	IconZodiacAries: <IconZodiacAries />,
	IconZodiacCancer: <IconZodiacCancer />,
	IconZodiacCapricorn: <IconZodiacCapricorn />,
	IconZodiacGemini: <IconZodiacGemini />,
	IconZodiacLeo: <IconZodiacLeo />,
	IconZodiacLibra: <IconZodiacLibra />,
	IconZodiacPisces: <IconZodiacPisces />,
	IconZodiacSagittarius: <IconZodiacSagittarius />,
	IconZodiacScorpio: <IconZodiacScorpio />,
	IconZodiacTaurus: <IconZodiacTaurus />,
	IconZodiacVirgo: <IconZodiacVirgo />,
	IconZoomCancel: <IconZoomCancel />,
	IconZoomCheckFilled: <IconZoomCheckFilled />,
	IconZoomCheck: <IconZoomCheck />,
	IconZoomCode: <IconZoomCode />,
	IconZoomExclamation: <IconZoomExclamation />,
	IconZoomFilled: <IconZoomFilled />,
	IconZoomInAreaFilled: <IconZoomInAreaFilled />,
	IconZoomInArea: <IconZoomInArea />,
	IconZoomInFilled: <IconZoomInFilled />,
	IconZoomIn: <IconZoomIn />,
	IconZoomMoney: <IconZoomMoney />,
	IconZoomOutArea: <IconZoomOutArea />,
	IconZoomOutFilled: <IconZoomOutFilled />,
	IconZoomOut: <IconZoomOut />,
	IconZoomPan: <IconZoomPan />,
	IconZoomQuestion: <IconZoomQuestion />,
	IconZoomReplace: <IconZoomReplace />,
	IconZoomReset: <IconZoomReset />,
	IconZzzOff: <IconZzzOff />,
	IconZzz: <IconZzz />,
	IconBookmarkFilled: <IconBookmarkFilled />,
};

const Icon = ({ iconName, fontSize, color, className, onMouseEnter, onMouseLeave, ...rest }: IconProps) => {
	const children = iconMap[iconName] ? iconMap[iconName] : iconMap?.fallback;
	return (
		<SvgIcon
			htmlColor={color}
			className={className}
			fontSize={fontSize}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onMouseDown={(event) => event.stopPropagation()}
			{...rest}>
			{children}
		</SvgIcon>
	);
};

Icon.defaultProps = {
	onClick: () => {},
	size: 'small',
	type: 'fallback',
	fontSize: 'small',
	color: '#000',
};
export default Icon;
