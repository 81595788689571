import React from 'react';
import { Grid, Icon } from 'src/common/@the-source/atoms';
import styles from '../Cell.module.css';
import CustomText from 'src/common/@the-source/CustomText';
import { colors } from 'src/utils/theme';
import { get } from 'lodash';
import utils from 'src/utils/utils';

interface Props {
	value?: any;
	valueFormatted?: any;
	displayType?: string;
	field?: string;
	node?: any;
}

const container_style = {
	padding: '0.2rem 1rem',
	borderRadius: '40px',
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	border: `1px solid ${colors.dark_midnight_blue}`,
	background: 'white',
	lineHeight: 'normal',
};
const icon_style = {
	width: '1.4rem',
	height: '1.4rem',
	marginRight: '0.3rem',
};
const text_style = {
	textTransform: 'capitalize',
	fontSize: '1.4rem',
};

const CustomTagCellRenderer: React.FC<Props> = ({ value, ...rest }: any) => {
	const { valueFormatted, field, node } = rest;

	const bg_color_field = `${field}-color`;
	const is_order_tag = rest?.headerName === 'Order Tag';
	const bg_color = get(node, ['data', bg_color_field], colors.white);
	const { icon, bgColor = bg_color } = is_order_tag ? { icon: 'IconBookmark' } : utils.get_icon_by_value(value);

	return (
		<div className={styles.agGridCustomCell} style={{ marginTop: '0px' }}>
			{valueFormatted ? (
				<Grid style={{ ...container_style, backgroundColor: bgColor }}>
					<Icon iconName={icon} sx={icon_style} />
					<CustomText color='black' type='Body' style={text_style}>
						{valueFormatted}
					</CustomText>
				</Grid>
			) : (
				<CustomText>--</CustomText>
			)}
		</div>
	);
};

export default CustomTagCellRenderer;
