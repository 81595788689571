import cart_management from 'src/utils/api_requests/cartManagement';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { set_cart } from 'src/actions/buyer';

const useMultiCarts = () => {
	const buyer = useSelector((state: any) => state?.buyer);
	const dispatch = useDispatch();

	const get_multi_carts_data = async (params?: { buyer_id?: string; is_guest_buyer?: boolean }) => {
		try {
			const buyer_id = params?.buyer_id || buyer?.buyer_id;
			const is_guest_buyer = params?.is_guest_buyer ?? buyer?.is_guest_buyer;
			const response: any = await cart_management.get_cart({ buyer_id, is_guest_buyer });
			if (response?.status === 200) {
				return response?.data;
			}
		} catch (error) {
			console.error(error);
		}
	};

	const update_multi_carts_data = async () => {
		dispatch<any>(set_cart({ buyer_id: buyer?.buyer_id, is_guest_buyer: buyer?.is_guest_buyer }));
	};

	const activate_cart = async (cart_data: any, callback?: any) => {
		try {
			if (!cart_data?.id) return;
			const response: any = await cart_management.activate_cart(cart_data?.id);
			if (response?.status === 200) {
				dispatch<any>(
					set_cart({
						buyer_id: cart_data?.buyer_id ?? buyer?.buyer_id,
						is_guest_buyer: cart_data?.is_guest_cart ?? buyer?.is_guest_buyer,
						callback,
					}),
				);
				return true;
			}
		} catch (error) {
			console.error(error);
			callback && callback();
			return false;
		}
	};

	return {
		activate_cart,
		get_multi_carts_data,
		update_multi_carts_data,
	};
};

export default useMultiCarts;
